import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';

var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.deviceLocation)?_c(VCard,{style:(_vm.cssVars),attrs:{"flat":""}},[_c(VCardTitle,[_vm._v("Latest Location")]),_c(VCardText,[_c('GmapMap',{ref:"map",staticClass:"device-map",attrs:{"center":_vm.center,"zoom":15,"options":{
      zoomControl: true,
      mapTypeControl: false,
      scaleControl: false,
      streetViewControl: false,
      rotateControl: true,
      fullscreenControl: true,
    }}},[_c('GmapMarker',{attrs:{"position":_vm.center,"clickable":false,"draggable":false,"title":_vm.deviceLocation.latitude + ', ' + _vm.deviceLocation.longitude}})],1),_c('p',{staticClass:"address-display"},[_vm._v(_vm._s(_vm.address))]),_c('div',{ref:"result",staticClass:"d-none"}),_c('p',{staticClass:"last-updated-display",attrs:{"hidden":!(_vm.deviceLocation.lastUpdated)}},[_vm._v(" Last Updated: "+_vm._s(_vm.deviceLocation.lastUpdated)+" ")])],1)],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }