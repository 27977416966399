const state = {}

const getters = {
  getterGetReminderDate: (state) => (routerId) => {
    return state[routerId] || null; // -1 || string || null
  }
}

const mutations = {
  mutationSetReminderDate: (state, payload) => {
    state[payload.router_id] = payload.reminderDate; // -1 || string (which parses to Date)
  },
  mutationClearReminderDate: (state, payload) => {
    state[payload.router_id] = null;
  },
  mutationClearAllReminderDate: (state) => {
    Object.keys(state).forEach((key) => {
      state[key] = null;
    });
  }
}

export default {
  state,
  getters,
  mutations
}