<template>
  <v-tabs v-model="navTabs" dense right dark>
    <v-tabs-slider class="button"></v-tabs-slider>

    <!-- Manage Menu -->
    <v-menu open-on-hover bottom offset-y nudge-top="2">
      <template v-slot:activator="{ on, attrs }">
        <v-tab v-bind="attrs" v-on="on" class="text-capitalize" @click="pushRoute({ name: 'CustomerDeviceList' })">Manage</v-tab>
      </template>
      <v-list style="background-color: #d9d9d9" dense>
        <v-list-item v-for="menu in manageMenu" :key="menu.title" @click="pushRoute(menu.name)">
          <v-list-item-title>{{ menu.title }}</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>

    <!-- Device Menu -->
    <v-menu open-on-hover bottom offset-y nudge-top="2">
      <template v-slot:activator="{ on, attrs }">
        <v-tab v-bind="attrs" v-on="on" class="text-capitalize" @click="pushRoute({ name: 'ProductModel' })">Device</v-tab>
      </template>
      <v-list style="background-color: #d9d9d9" dense>
        <v-list-item v-for="menu in deviceMenu" :key="menu.title" @click="pushRoute(menu.name)">
          <v-list-item-title>{{ menu.title }}</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>

    <!-- Accounts Menu -->
    <v-menu open-on-hover bottom offset-y nudge-top="2">
      <template v-slot:activator="{ on, attrs }">
        <v-tab id="account" v-bind="attrs" v-on="on" class="text-capitalize" ref="tab1" @click="pushRoute({ name: 'BusinessUsers' })">Accounts</v-tab>
      </template>

      <v-list style="background-color: #d9d9d9" dense>
        <v-list-item v-for="menu in accountsMenu" :key="menu.title" @click="pushRoute(menu.name)">
          <v-list-item-title>{{ menu.title }}</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>

  </v-tabs>
</template>
<script>
import { mixin_routing_utils } from "@/mixins/routingUtils.js";
export default {
  mixins: [ mixin_routing_utils ],
  data() {
    return {
      navTabs: 0,
      manageMenu: [
        { title: "Devices", name: { name: "CustomerDeviceList" }},
        { title: "Organizations", name: { name: "Organization" } },
        { title: "Groups", name: { name: "DeviceGroupsList" } },
      ],
      deviceMenu: [
        { title: "Licenses", name: { name: "Licenses" } },
      ],
      accountsMenu: [
        { title: "Business Users", name: { name: "BusinessUsers" } },
        { title: "Individual Users", name: { name: "IndividualUsers" } },
      ]
    };
  },
  // The <v-tabs-slider> will move according to the "navTabs" value index
  // Watch for changes in routing. If the route changes, return the matching "navTabs" index value
  watch: {
    '$route'(to, from) {
      this.navTabs = this.positionMenuSlider(to.name);
    }
  },
  // Make sure that the slider is on the correct position in case the user refreshes the page or enters by link directly
  created () {
    this.navTabs = this.positionMenuSlider(this.$route.name);
  },
  methods: {
    pushRoute(menuRoute) {
      this.mixinChangeRoute(menuRoute);
    },
    positionMenuSlider(name){
      if(this.manageMenu.some(item => name === item.name.name)) return 0;
      if(this.deviceMenu.some(item => name === item.name.name)) return 1;
      if(this.accountsMenu.some(item => name === item.name.name)) return 2;
    }
  },
};
</script>
