<template>
  <v-tooltip bottom>
    <template v-slot:activator="{ on, attrs }">
      <div v-bind="attrs" v-on="on" class="d-flex align-center">
        <div class="custom-switch-button" :disabled="disabled" :class="{ 'selection-active': connectionAllowed }" @click.stop="$emit('click')"></div>
        <div class="text-caption ml-2" :style="{ color: !connectionAllowed ? '#CD2A32' : '#0195D2' }">{{ !connectionAllowed ? "Suspended" : "Allowed" }}</div>
      </div>
    </template>
    <span v-if="disabled">This action is disabled while the table is loading</span>
    <span v-else>You can {{ connectionAllowed ? "suspend" : "resume" }} internet access by clicking the toggle button</span>
  </v-tooltip>
</template>

<script>

export default {
  props: {
    scheduleList: Array,
    disabled: Boolean,
  },
  computed: {
    connectionAllowed() {
      let isConnectionOn = true;
      if (this.scheduleList.length === 1) isConnectionOn = !(this.scheduleList[0].Enable && this.scheduleList[0].StartTime === 2 && this.scheduleList[0].EndTime === 3);
      return isConnectionOn;
    },
  },
};
</script>

<style scoped>
.custom-switch-button {
  position: relative;
  width: 36px;
  height: 20px;
  border-radius: 16px;
  background-color: var(--v-primary-red-base);
  transition: 100ms ease-in;
  cursor: pointer;
}

.custom-switch-button:hover {
  opacity: 0.7;
}

.selection-active.custom-switch-button {
  background-color: var(--v-primary-blue-base);
}

.custom-switch-button[disabled] {
  pointer-events: none;
  background-color: gray;
  opacity: 0.5;
}

.custom-switch-button::after {
  position: absolute;
  content: "";
  height: 50%;
  aspect-ratio: 1;
  border-radius: 50%;
  background-color: white;
  top: 50%;
  left: 10%;
  transform: translateY(-50%);
  transition: 100ms ease-in;
  z-index: 1;
}

.selection-active.custom-switch-button::after {
  left: 58%;
  transition: 100ms ease-in;
}
</style>
