<template>
  <v-app>
    <v-main class="align-center">
      <v-card class="sign-up-card" width="780px" height="440px">
        <v-card-title class="justify-center">Individual Sign Up</v-card-title>
        <v-window class="sign-up-window" v-model="windowPage">
          <v-window-item :value="0">
            <SelectModel />
          </v-window-item>
          <v-window-item :value="1">
            <DeviceInfo />
          </v-window-item>
          <v-window-item :value="2">
            <NewUserInfo />
          </v-window-item>
          <v-window-item :value="3">
            <VerificationCode />
          </v-window-item>
          <v-window-item :value="4">
            <LicenseInfo />
          </v-window-item>
        </v-window>
      </v-card>
    </v-main>
  </v-app>
</template>

<script>
import SelectModel from "@/components/SignUp/SelectModel.vue";
import DeviceInfo from "@/components/SignUp/DeviceInfo.vue";
import NewUserInfo from "@/components/SignUp/NewUserInfo.vue";
import VerificationCode from "@/components/SignUp/VerificationCode.vue";
import LicenseInfo from "@/components/SignUp/LicenseInfo.vue";

import { mapGetters } from "vuex";

export default {
  components: {
    SelectModel,
    DeviceInfo,
    NewUserInfo,
    VerificationCode,
    LicenseInfo
  },
  computed: {
    ...mapGetters(["getterGetSignUpWindowPage"]),
    windowPage: {
      get() {
        return this.getterGetSignUpWindowPage;
      },
    },
  },
};
</script>

<style scoped>
  .sign-up-card {
    margin: auto;
    display: grid;
    grid-template-rows: auto 1fr;
  }
  /* Stretch child components */
  :deep.sign-up-window > .v-window__container,
  :deep.v-window-item {
    height: 100%;
  }
  .sign-up-title-text {
    font-size: 1rem;
    font-weight: 700;
    text-align: center;
  }
</style>
