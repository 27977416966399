export const get_current_user_details = /* GraphQL */ `
  query Get_current_user_deatils($input: GetCurrentUserDeatilsInput) {
    get_current_user_deatils(input: $input)
  }
`;

export const list_Users = /* GraphQL */ `
  query List_Users($input: ListUsersInput) {
    list_Users(input: $input)
  }
`;