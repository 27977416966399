<template>
  <v-card flat class="subscription-parent-card">
    <v-carousel height="fit-content" hide-delimiter-background hide-delimiters @change="onChange"
      class="subscription-carousel">
      <template v-slot:prev="{ on, attrs }">
        <v-btn icon v-bind="attrs" v-on="on" color="primary"><v-icon>mdi-arrow-left-drop-circle</v-icon></v-btn>
      </template>
      <template v-slot:next="{ on, attrs }">
        <v-btn icon v-bind="attrs" v-on="on" color="primary"><v-icon>mdi-arrow-right-drop-circle</v-icon></v-btn>
      </template>
      <v-carousel-item v-for="subscription in subscriptions" :key="subscription.imei">
        <v-card flat class="subscription-card-content">
          <v-card-title>Subscription Info</v-card-title>
          <v-card-text>
            <div class="subscription-info-line">
              <p class="subscription-left-title">Device</p>
              <p class="subscription-right-value">{{ subscription.device_name }}</p>
            </div>
            <div class="subscription-info-line">
              <p class="subscription-left-title">IMEI Number</p>
              <p class="subscription-right-value">{{ subscription.imei }}</p>
            </div>
            <div class="subscription-info-line">
              <p class="subscription-left-title">Subscription Type</p>
              <p class="subscription-right-value">{{ subscription.subscription_type }}</p>
            </div>
            <div class="subscription-info-line">
              <p class="subscription-left-title">Status</p>
              <p class="subscription-right-value">{{ subscription.subscription_status }}</p>
            </div>
            <p class="subscription-expiry-date">Ends on <span>{{ subscription.expire_date }}</span></p>
          </v-card-text>
        </v-card>
      </v-carousel-item>
    </v-carousel>
    <div class="subscription-counter">
      <p> {{ cardIndex + 1 }} / {{ subscriptions.length }} </p>
    </div>
    <v-btn class="subscription-settings-btn" icon @click="manageSubscriptionEvent()">
      <v-icon>mdi-cog</v-icon>
    </v-btn>
  </v-card>
</template>

<script>

export default {
  name: "SubscriptionInfoCard",
  props: {
    subscriptions: {
      type: Array,
      required: true
    },
  },
  data() {
    return {
      cardIndex: 0,
    };
  },
  methods: {
    onChange(index) {
      this.cardIndex = index;
    },
    manageSubscriptionEvent() {
      this.$parent.$parent.$parent.$emit('openManageSubscription');
    },
  },
};

</script>

<style>
.subscription-settings-btn {
  position: absolute;
  top: 0;
  right: 0;
  margin-top: 0.5rem;
  margin-right: 0.5rem;
}

.subscription-expiry-date {
  text-align: right;
  width: 100%;
}

.subscription-info-line {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.subscription-info-line p {
  margin-top: 1rem;
  margin-bottom: 0;
}

.subscription-left-title {
  text-align: left;
  font-weight: bold;
  width: fit-content;
}

.subscription-right-value {
  text-align: right;
  width: fit-content;
}

.subscription-counter {
  position: absolute;
  width: 100%;
  bottom: 1.5rem;
}

.subscription-counter p {
  margin-bottom: 0;
  font-size: 0.75rem;
  text-align: center;
}

.subscription-carousel .v-window__next {
  bottom: 1rem;
  display: flex;
  flex-direction: column-reverse;
  background-color: transparent;
}

.subscription-carousel .v-window__prev {
  bottom: 1rem;
  display: flex;
  flex-direction: column-reverse;
  background-color: transparent;
}

.subscription-carousel {
  position: unset;
}

.subscription-carousel .v-window__container {
  position: unset;
}

.subscription-carousel .v-window__item {
  position: unset;
}

.subscription-parent-card {
  width: 22%;
  min-width: fit-content;
  position: relative;
  overflow: hidden;
  margin-left: 0.25rem;
  margin-right: 0.25rem;
}

.subscription-card-content {
  width: 22rem;
  padding-bottom: 1rem;
  margin-left: auto;
  margin-right: auto;

}
</style>

<style scoped>
.subscription-parent-card {
  border: solid 1px #E5E5EA;
}
</style>
