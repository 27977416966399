import { API, graphqlOperation } from "aws-amplify";
import { get_router_for_specific_router_group, get_grouped_and_non_grouped_router, router_group_list, sub_router_group_list, get_organization_router_group, add_multiple_router_to_router_group, unmap_router_list_from_router_group, add_router_group } from "@/graphql";

// Queries
export const getGroupList = async (queryObj) => {
  try {
    const result = await API.graphql(
      graphqlOperation(router_group_list, {
        input: queryObj
      })
    );
    const response = JSON.parse(result.data.router_group_list).response;
    return response;
  } catch (error) {
    console.error("Error fetching list of groups", error);
    throw error;
  }
}

export const getUpdatedGroupList = async (queryObj) => {
  try {
    const result = await API.graphql(
      graphqlOperation(sub_router_group_list, {
        input: queryObj
      })
    );
    const response = JSON.parse(result.data.sub_router_group_list);
    return response;
  } catch (error) {
    console.error("Error fetching list of groups", error);
    throw error;
  }
}

export const getGroupedDevices = async (queryObj) => {
  try {
    const result = await API.graphql(
      graphqlOperation(get_router_for_specific_router_group, {
        input: queryObj
      })
    );
    const response = JSON.parse(result.data.get_router_for_specific_router_group).items;
    return response;
  } catch (error) {
    console.error("Error fetching grouped devices", error);
    throw error;
  }
};

export const getUngroupedDevices = async (queryObj) => {
  try {
    const result = await API.graphql(
      graphqlOperation(get_grouped_and_non_grouped_router, {
        input: queryObj
      })
    );
    const response = JSON.parse(result.data.get_grouped_and_non_grouped_router).ungroupedRouters;
    return response;
  } catch (error) {
    console.error("Error fetching ungrouped devices", error);
    throw error;
  }
};

// This does not return a normal empty response but throws errors for not finding anything
export const getOrganizationGroup = async (customerId) => {
  try {
      const result = await API.graphql(
          graphqlOperation(get_organization_router_group, {
              input: {
                  customer_id: customerId,
              },
          })
      );
      const response = JSON.parse(result.data.get_organization_router_group).data;
      return response;
  } catch (error) {
    console.error("Error fetching groups", error);
  }
}

// Mutations
export const addDevicesToAGroup = async (mutationObj) => {
  try{
    const result = await API.graphql(
      graphqlOperation(add_multiple_router_to_router_group, {
        input: mutationObj
      })
    )
    const response = result.data;
    return response;
  } catch (error) {
    console.error("Error grouping devices", error);
    throw error;
  }
}

export const removeDevicesFromAGroup = async (mutationObj) => {
  try{
    const result = await API.graphql(
      graphqlOperation(unmap_router_list_from_router_group, {
        input: mutationObj
      })
    )
    const response = JSON.parse(result.data.unmap_router_list_from_router_group);
    return response;
  } catch (error) {
    console.error("Error ungrouping devices", error);
    throw error;
  }
}

export const createDeviceGroup = async (mutationObj) => {
  try{
    const result = await API.graphql(
      graphqlOperation(add_router_group, {
        input: mutationObj
      })
    );
    return result;
  } catch (error) {
    console.error("Error ungrouping devices", error);
    throw error;
  }
}