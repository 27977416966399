<template>
  <div>
    <v-dialog v-model="routersDialog" persistent overflow-hidden max-width="400px">
      <v-card class="dialogCard cardBg overflow-hidden">
        <v-toolbar class="dialogToolbar cardBg" dense>
          <v-toolbar-title>Delete Router</v-toolbar-title>
          <v-spacer></v-spacer>

          <v-icon @click="close_dialog()" class="ml-2" color="iconCross">mdi-close</v-icon>
        </v-toolbar>

        <v-card-text class="cardBg pt-6 justify-center">
          Are you sure you want to delete all routers assigned to the user?
          <br />
          <div v-for="router in routerData" :key="router.id">- {{ router }}</div>
        </v-card-text>

        <v-card-actions class="button-corner">
          <v-spacer></v-spacer>
          <v-btn class="blue--text" outlined color="white" @click="close_dialog()">Cancel</v-btn>
          <v-btn class="button tabledata--text mr-2" :loading="loadingDelete" @click="delete_router()">Delete</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { delete_user_permanently, delete_router_details } from "@/graphql/mutations.js";
import { API, graphqlOperation } from "aws-amplify";
import { mapActions, mapGetters, mapMutations } from "vuex";

export default {
  props: {
    routerData: Array,
    userID: String,
    routersDialog: Boolean,
    userEmail: String,
  },
  data() {
    return {
      loadingDelete: false,
    };
  },
  computed: {
    ...mapGetters(["getterGetUserInfo"])
  },
  methods: {
    ...mapActions(["actionDeleteCustomer"]),
    ...mapMutations(["mutationSetSnackBarItem"]),
    triggerSnackBar(type, text) {
      this.mutationSetSnackBarItem({ type, text });
    },
    async delete_router() {
      this.loadingDelete = true;
      try {
        let result = await API.graphql(
          graphqlOperation(delete_router_details, {
            input: {
              user_id: this.getterGetUserInfo.user.user_id,
              router_id: this.routerData,
            },
          })
        );
        await this.delete_permanently();
      } catch (err) {
        this.triggerSnackBar("error", err.errors ? err.errors[0].message : err);
      } finally {
        this.loadingDelete = false;
      }
    },
    async delete_permanently() {
      try {
        let result = await API.graphql(
          graphqlOperation(delete_user_permanently, {
            input: {
              user_id: this.userID,
            },
          })
        );
        //Delete customer from stripe
        const email = this.userEmail;
        await this.actionDeleteCustomer(email);
        this.$emit("clicked", 7);
      } catch (error) {
        this.triggerSnackBar("error", err.errors ? err.errors[0].message : err);
      } finally {
        this.loadingDelete = false;
      }
    },
    close_dialog() {
      this.$emit("clicked", 0);
    },
  },
};
</script>
