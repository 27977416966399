<template>
  <div>
    <v-main>
      <v-toolbar class="elevation-0 tablePadding" dense flat>
        <v-toolbar-title class="black--text mt-1">{{ getterGetCurrentUserRole == "SU_ADMIN" ? "Business Users" : "Users" }}</v-toolbar-title>

        <v-select class="ml-6 mt-6" color="black" style="font-size: 12px; max-width: 150px" v-model="statusModel" label="Filter By" :items="filterItems" @input="get_status_value(statusModel)">
          <template #item="{ item }">
            <span class="filter-font"> {{ item == "ACTIVE" ? "Active" : item == "INACTIVE" ? "Inactive" : "All" }} </span>
          </template>
          <template #selection="{ item }">
            <span class="filter-font"> {{ item == "ACTIVE" ? "Active" : item == "INACTIVE" ? "Inactive" : "All" }} </span>
          </template>
        </v-select>

        <v-btn class="button ml-6" v-if="getterGetCurrentUserRole !== 'SP_ADMIN'" @click="add_user()" dark>Add User</v-btn>
        <VueJsonToCsv
          :labels="{
            user_full_name: { title: 'Name' },
            user_status: { title: 'Status' },
            user_role: { title: 'Role' },
            user_email_id: { title: 'Email ID' },
            affiliation: { title: 'Organization' },
            user_created_on: { title: 'Created On' },
          }"
          csv-title="Users"
          :json-data="tableData"
        >
          <v-btn class="button ml-2" dark>Export</v-btn>
        </VueJsonToCsv>
        <v-spacer></v-spacer>
        <div class="container-wrapper">
          <v-text-field clearable append-icon="mdi-magnify blue--text" v-model="search" class="search-bar mr-1" label="Search" single-line style="max-width: 250px"></v-text-field>
        </div>
      </v-toolbar>

      <v-card-text>
        <v-data-table
          dense
          :search="search"
          :headers="getterGetCurrentUserRole == 'SP_ADMIN' ? suppHeaders : headers"
          :items="tableData"
          class="elevation-1"
          :footer-props="{
            itemsPerPageOptions: [10, 50, 100, 500, -1],
          }"
          :loading="areUsersLoaded"
          :items-per-page="10"
          :no-results-text="`No matching results for '${search}'`"
        >
          <template v-slot:[`item.user_full_name`]="{ item }">
            <span class="caption">{{ item.first_name }} {{ item.user_full_name }}</span>
          </template>

          <template v-slot:[`header.user_status`]="{ header }">
            <span class="ml-2">
              {{ header.text }}
            </span>
          </template>
          <template v-slot:[`item.user_role`]="{ item }">
            <span class="caption">{{ display_roles(item.user_role) }}</span>
          </template>
          <template v-slot:[`item.user_status`]="{ item }">
            <span class="caption ml-2">
              <span v-if="item.user_status == 'INACTIVE'" class="caption grey--text text--darken-1">Inactive</span>
              <span v-else class="caption blue--text text--darken-2">Active</span>
            </span>
          </template>
          <template v-slot:[`item.user_email_id`]="{ item }">
            <span class="caption">{{ item.user_email_id }}</span>
          </template>
          <template v-slot:[`item.affiliation`]="{ item }">
            <span class="caption">{{ item.affiliation }}</span>
          </template>
          <template v-slot:[`item.user_created_on`]="{ item }">
            <span class="caption">{{ mixinConvertUTCToDate(item.user_created_on) }}</span>
          </template>
          <template v-slot:[`item.organization_name`]="{ item }">
            <span class="caption">
              {{ item.organization_name }}
            </span>
          </template>
          <template v-slot:[`item.Actions`]="{ item }">
            <v-menu v-if="item.user_email_id != getterGetCurrentUserEmail" bottom left>
              <template v-slot:activator="{ on, attrs }">
                <v-btn icon v-bind="attrs" v-on="on">
                  <v-icon>mdi-dots-vertical</v-icon>
                </v-btn>
              </template>
              <v-list dense>
                <v-list-item v-if="item.user_status == 'ACTIVE'" @click="edit_details(item)">
                  <v-list-item-title>Edit</v-list-item-title>
                </v-list-item>

                <v-list-item v-if="item.user_status == 'ACTIVE'" @click="toggle_org(item)">
                  <v-list-item-title>Suspend</v-list-item-title>
                </v-list-item>
                <v-list-item v-if="item.user_status == 'ACTIVE'" @click="delete_permanently(item)">
                  <v-list-item-title>Delete</v-list-item-title>
                </v-list-item>
                <v-list-item v-if="item.user_status == 'ACTIVE'" @click="login_account(item)">
                  <v-list-item-title>Login</v-list-item-title>
                </v-list-item>

                <v-list-item v-if="item.user_status == 'INACTIVE'" @click="activate_user_item(item)">
                  <v-list-item-title>Activate</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
            <em v-else class="grey--text"> Signed in </em>
          </template>
          <template v-slot:footer>
            <div class="footer-height">
              <LoadingIcon v-if="areUsersLoaded" style="position: absolute" class="ml-2 mt-2"></LoadingIcon>

              <span v-if="areUsersLoaded" style="position: absolute" class="ml-10 mt-5">
                {{ getterGetCurrentUserRole == "SU_ADMIN" ? "Loading business users..." : "Loading users..." }}
              </span>

              <span v-else>
                <v-icon @click="refresh_list()" class="ml-1 mt-3 btn-fix" dark outline="none" color="cyan" style="position: absolute; font-size: 34px" id="refresh"> mdi-refresh </v-icon>
                <span style="position: absolute" class="ml-11 pl-0 mt-5"> Last updated: {{ mixinGetLastUpdatedTime }} </span>
              </span>
            </div>
          </template>
        </v-data-table>
      </v-card-text>

      <v-dialog v-model="activateUserDialog" persistent overflow-hidden max-width="400px">
        <v-card class="dialogCard cardBg overflow-hidden">
          <v-toolbar class="dialogToolbar cardBg" dense>
            <v-toolbar-title>Activate User</v-toolbar-title>
            <v-spacer></v-spacer>

            <v-icon @click="activateUserDialog = false" class="ml-2" color="iconCross">mdi-close</v-icon>
          </v-toolbar>

          <v-card-text class="cardBg pt-6 justify-center">
            Are you sure you want to activate <b> {{ fullName }}</b> ?
          </v-card-text>

          <v-card-actions class="button-corner">
            <v-spacer></v-spacer>
            <v-btn class="button tabledata--text" @click="activateUserDialog = false">Cancel</v-btn>
            <v-btn class="button tabledata--text mr-2" :loading="loadingactivate" @click="activate_user()">Activate</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <!-- ---------------------------Delete user dialog--------------------------- 진짜 ㅅㅂ 멍청한 ㅅㄲ들-->
      <v-dialog v-model="deleteUserDialog" persistent overflow-hidden max-width="400px">
        <v-card class="dialogCard cardBg overflow-hidden">
          <v-toolbar class="dialogToolbar cardBg" dense>
            <v-toolbar-title>Deactivate User</v-toolbar-title>
            <v-spacer></v-spacer>

            <v-icon @click="deleteUserDialog = false" class="ml-2" color="iconCross">mdi-close</v-icon>
          </v-toolbar>

          <v-card-text class="cardBg pt-6 justify-center">
            Are you sure you want to deactivate <b> {{ delUserName }}</b> ?
          </v-card-text>

          <v-card-actions class="button-corner">
            <v-spacer></v-spacer>
            <v-btn class="blue--text" outlined @click="deleteUserDialog = false">Cancel</v-btn>
            <v-btn class="button tabledata--text mr-2" :loading="loadingDelete" @click="delete_user()">Deactivate</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog v-model="userLogin" persistent overflow-hidden max-width="400px">
        <v-card class="cardBg">
          <v-card-title class="medium cardTitle">
            <span>Access Account</span>
            <v-spacer></v-spacer>
            <v-btn icon @click="userLogin = false">
              <v-icon color="iconCross">mdi-close</v-icon>
            </v-btn>
          </v-card-title>

          <v-card-text class="cardBg pt-6 justify-center">
            Are you sure you want to login to
            <b> {{ delUserName }}'s </b> account.
          </v-card-text>

          <v-card-actions class="button-corner">
            <v-spacer></v-spacer>
            <v-btn class="blue--text" outlined @click="userLogin = false">Cancel</v-btn>
            <v-btn class="button tabledata--text" @click="login_confirm()">Login</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <!-- componets invokes on add and edit action -->
      <div v-if="addCheck == true">
        <AddBusinessUsers :addUserDialog="addUserDialog" :timeZoneArray="timeZoneArray" :countryCodes="countryCodes" @clicked="close_dialog" :orgItems="orgItems" />
      </div>
      <div v-if="editCheck == true">
        <EditBusinessUsers :editDialog="editDialog" :groupItems="groupItems" :timeZoneArray="timeZoneArray" :countryCodes="countryCodes" :editDetails="editDetails" @clicked="close_dialog" />
      </div>
      <div v-if="componentCheck == 1">
        <DeleteUserPermenent :delUserName="delUserName" :userID="userID" :delPermanently="delPermanently" @clicked="close_dialog" />
      </div>
    </v-main>
  </div>
</template>
<script>
import VueJsonToCsv from "vue-json-to-csv";
import { getUserList } from "@/services";
import { delete_user_and_reactivate_user, delete_user_permanently, sendImpersonationEmail } from "@/graphql/mutations.js";
import EditBusinessUsers from "@/components/SuperAdminAcces/Accounts/EditBusinessUsers.vue";
import AddBusinessUsers from "@/components/SuperAdminAcces/Accounts/AddBusinessUsers.vue";
import DeleteUserPermenent from "@/components/SuperAdminAcces/Accounts/DeleteUserPermenent.vue";

import { list_Users, router_group_list } from "@/graphql/queries.js";
import countryList from "@/mixins/CountryList.json";
import timeZones from "@/mixins/TimeZones.json";
import { API, graphqlOperation } from "aws-amplify";
import LoadingIcon from "@/components/LoadingIcon.vue";
import { mapGetters, mapMutations, mapActions } from "vuex";
import { mixin_time_utils } from "@/mixins/timeUtils";

export default {
  mixins: [mixin_time_utils],
  components: {
    VueJsonToCsv,
    AddBusinessUsers,
    DeleteUserPermenent,
    EditBusinessUsers,
    LoadingIcon,
  },
  data: () => ({
    orgItems: [],
    groupItems: [],
    userLogin: false,
    search: "",
    addUserForm: "",
    addUserDialog: false,
    deleteUserDialog: false,
    delPermanently: false,
    componentCheck: 0,
    delUserName: "",
    loadingactivate: false,
    areUsersLoaded: false,
    loadingDelete: false,
    userId: "",
    filterItems: ["ALL", "ACTIVE", "INACTIVE"],
    statusModel: "ALL",
    countryCodes: [],
    delCustomerId: "",
    status: null,
    tableData: [],
    timeZones: [],
    timeZoneArray: [],
    addCheck: false,
    suppHeaders: [
      { text: "Status", value: "user_status", class: "headerColor white--text", sortable: true },
      { text: " Name", value: "user_full_name", class: "headerColor white--text", sortable: true },
      { text: "Email", sortable: true, value: "user_email_id", class: "headerColor white--text" },
      { text: "Role", sortable: true, value: "user_role", class: "headerColor white--text" },
      { text: "Organization", sortable: true, value: "organization_name", class: "headerColor white--text" },
      { text: "Created On", sortable: true, value: "user_created_on", class: "headerColor white--text" },
    ],
    headers: [
      { text: "Status", value: "user_status", class: "headerColor white--text", sortable: true },
      { text: " Name", value: "user_full_name", class: "headerColor white--text", sortable: true },
      { text: "Email", sortable: true, value: "user_email_id", class: "headerColor white--text" },
      { text: "Role", sortable: true, value: "user_role", class: "headerColor white--text" },
      { text: "Organization", sortable: true, value: "organization_name", class: "headerColor white--text" },
      { text: "Created On", sortable: true, value: "user_created_on", class: "headerColor white--text" },
      { text: "Actions", value: "Actions", class: "headerColor white--text" },
    ],
    orgName: "",
    editDialog: false,
    editDetails: {},
    editCheck: false,
    loginDetails: {},
    fullName: "",
    delUserID: "",
    activateUserDialog: false,
    finalData: [],
    last_evaluated_key: null,

    isMounted: false,
  }),
  // This refreshes the page if the user exited impersonation mode from BusinessUsers page and navigate back to the same page.
  watch: {
    "$store.state.user.impersonationArray": {
      handler() {
        this.refresh_list();
      },
      deep: true,
    },
  },
  // Method gets invoked as soon as page lods
  created() {
    this.countries();
    this.country_timezones();
  },
  //
  mounted() {
    this.isMounted = true;
    this.get_user_list();
  },
  beforeDestroy() {
    this.isMounted = false;
  },
  computed: {
    ...mapGetters(["getterGetUserInfo", "getterGetCurrentUserRole", "getterGetCurrentUserEmail"]),
  },
  methods: {
    ...mapMutations(["mutationSetSnackBarItem"]),
    triggerSnackBar(type, text) {
      this.mutationSetSnackBarItem({ type, text });
    },
    ...mapActions(["actionStartImpersonation"]),
    activate_user_item(item) {
      this.fullName = item.full_name;
      this.delUserID = item.user_id;
      this.activateUserDialog = true;
    },

    async activate_user() {
      this.loadingactivate = true;
      try {
        let result = await API.graphql(
          graphqlOperation(delete_user_and_reactivate_user, {
            input: {
              user_id: this.delUserID,
            },
          })
        );
        this.triggerSnackBar("success", "User Activated Successfully");
        this.get_user_list();
      } catch (err) {
        this.triggerSnackBar("error", err.errors ? err.errors[0].message : err);
      } finally {
        this.statusModel = "";
        this.activateUserDialog = false;
        this.loadingactivate = false;
      }
    },
    get_status_value(data) {
      this.selectedValue = data;
      if (this.selectedValue == "ACTIVE") {
        this.tableData = this.finalData.filter((Obj) => Obj.user_status == "ACTIVE");
      } else if (this.selectedValue == "INACTIVE") {
        this.tableData = this.finalData.filter((Obj) => Obj.user_status == "INACTIVE");
      } else if (this.selectedValue == "ALL") {
        this.tableData = this.finalData;
      }
    },
    //method invokes to fetch the user list
    async get_user_list() {
      if (!this.isMounted) return;
      this.areUsersLoaded = true;
      try {
        // let result = await API.graphql(
        //   graphqlOperation(list_Users, {
        //     input: {
        //       user_role: this.getterGetCurrentUserRole,
        //       customer_id: this.getterGetUserInfo.customer.customer_id,
        //       limit: 100,
        //       next_token: null,
        //     },
        //   })
        // );
        const fetchedUsers = await getUserList(this.getterGetCurrentUserRole, this.getterGetUserInfo.customer.customer_id, 100, null);
        this.last_evaluated_key = fetchedUsers.next_token;
        this.tableData = fetchedUsers.business;
        this.finalData = fetchedUsers.business;
        while (this.last_evaluated_key && this.isMounted) {
          await this.load_more_user_list();
        }
        this.mixinResetStartTime();
      } catch (error) {
        this.triggerSnackBar("error", err.errors ? err.errors[0].message : err);
      } finally {
        this.areUsersLoaded = false;
      }
    },
    async load_more_user_list() {
      try {
        // let result = await API.graphql(
        //   graphqlOperation(list_Users, {
        //     input: {
        //       user_role: this.getterGetCurrentUserRole,
        //       customer_id: this.getterGetUserInfo.customer.customer_id,
        //       limit: 500,
        //       next_token: this.last_evaluated_key,
        //     },
        //   })
        // );
        const fetchedUsers = await getUserList(this.getterGetCurrentUserRole, this.getterGetUserInfo.customer.customer_id, 500, this.last_evaluated_key);
        this.last_evaluated_key = fetchedUsers.next_token;
        this.tableData = this.tableData.concat(fetchedUsers.business);
        this.finalData = this.finalData.concat(fetchedUsers.business);
      } catch (error) {
        this.triggerSnackBar("error", err.errors ? err.errors[0].message : err);
      }
    },

    //method to display the roles on tale
    display_roles(role) {
      const userRole = {
        SU_ADMIN: "Super Admin",
        C_ADMIN: "Admin",
        SP_ADMIN: "Support Staff",
        P_ADMIN: "Partner",
        G_M_ADMIN: "Group Manager",
        INDIVIDUAL: "User",
        C_ADMIN_1: "Org Admin",
      };
      return userRole[role];
    },
    //Function gets invoked on click of add
    async add_user() {
      // this.get_org_details();
      this.addUserDialog = true;
      this.addCheck = true;
    },
    // Function of displaying the country dropdown
    countries() {
      countryList.forEach((element) => {
        this.countryCodes.push({
          label: element.name + "(" + element.dial_code + ")",
          value: element.dial_code,
        });
      });
    },

    // Function for displaying the timezones
    country_timezones() {
      var timeZoneObj = timeZones;
      this.timeZoneArray = timeZoneObj.timezones;
    },

    //method invokes on close action of add popup
    close_dialog(val) {
      if (val == 0) {
        this.addUserDialog = false;
        this.editDialog = false;
        this.delPermanently = false;
      } else if (val == 1) {
        this.triggerSnackBar("error", "Please Provide Mandatory Fields");
      } else if (val == 2) {
        this.triggerSnackBar("success", "Created Successfully");
        this.addUserDialog = false;
        this.get_user_list();
      } else if (val == 3) {
        this.triggerSnackBar("error", "Group is Mandatory");
        this.get_user_list();
      } else if (val == 4) {
        this.triggerSnackBar("success", "Updated successfully");
        this.editDialog = false;
        this.get_user_list();
      } else if (val == 5) {
        this.triggerSnackBar("success", "Deleted Permanently");
        this.delPermanently = false;
        this.get_user_list();
      }
    },

    //method invokes on edit action
    async edit_details(item) {
      await this.get_particular_org_details(item);
      this.editDetails = item;
      this.editDialog = true;
      this.editCheck = true;
    },
    async get_particular_org_details(item) {
      try {
        let result = await API.graphql(
          graphqlOperation(router_group_list, {
            input: {
              customer_id: item.customer_id,
              user_role: "C_ADMIN",
            },
          })
        );
        var response = JSON.parse(result.data.router_group_list).response;
        var data = response.items;
        this.groupItems = [];
        data.forEach((element) => {
          if (element.group_status == "ACTIVE") {
            this.groupItems.push({
              label: element.group_name,
              value: element.router_group_id,
            });
          }
        });
      } catch (err) {
        this.triggerSnackBar("error", err.errors ? err.errors[0].message : err);
      }
    },

    login_account(item) {
      this.delUserName = item.user_full_name;
      this.userLogin = true;
      this.loginDetails = item;
    },

    async login_confirm() {
      this.userLogin = false;
      await this.actionStartImpersonation({ userEmail: this.loginDetails.user_email_id, backPath: "BusinessUsers" });
    },

    //method invokes on login action
    async send_impersonate_email() {
      try {
        let result = await API.graphql(
          graphqlOperation(sendImpersonationEmail, {
            input: {
              role: this.loginDetails.role,
              customer_id: this.loginDetails.customer_id,
              email: this.loginDetails.email,
              user_id: this.loginDetails.user_id,
            },
          })
        );
      } catch (error) {}
    },

    //method invokes on delete action
    toggle_org(item) {
      this.delUserName = item.user_full_name;
      this.delCustomerId = item.customer_id;
      this.userID = item.user_id;
      this.deleteUserDialog = true;
    },

    delete_permanently(item) {
      this.componentCheck = 1;
      this.delUserName = item.user_full_name;
      this.delCustomerId = item.customer_id;
      this.userID = item.user_id;
      this.delPermanently = true;
    },

    //method invokes on delete action
    async delete_user() {
      this.loadingDelete = true;
      try {
        let result = await API.graphql(
          graphqlOperation(delete_user_and_reactivate_user, {
            input: {
              user_id: this.userID,
            },
          })
        );
        this.triggerSnackBar("success", "Deleted Successfully");
        this.get_user_list();
      } catch (err) {
        this.triggerSnackBar("error", err.errors ? err.errors[0].message : err);
      } finally {
        this.deleteUserDialog = false;
        this.loadingDelete = false;
      }
    },
    async refresh_list() {
      this.tableData = [];
      this.finalData = [];
      await this.get_user_list();
    },
  },
};
</script>

<style>
.button.v-btn {
  text-transform: none;
}
.v-input.search-bar {
  width: 400px;
  margin-top: 25px;
}
.v-icon.magnify-btn {
  color: #0195d2;
}
</style>

<style scoped>
.search-bar >>> .v-input__slot::before,
.container-wrapper .search-bar >>> .v-input__slot {
  border-color: #0195d2 !important;
  color: #0195d2 !important;
}

.v-application .search-bar >>> .mdi-close {
  color: grey !important;
}

.filter-font {
  font-size: 14px;
  text-transform: none;
}
</style>
