import { VApp } from 'vuetify/lib/components/VApp';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VImg } from 'vuetify/lib/components/VImg';
import { VMain } from 'vuetify/lib/components/VMain';
import { VWindow } from 'vuetify/lib/components/VWindow';
import { VWindowItem } from 'vuetify/lib/components/VWindow';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VApp,[_c(VMain,[_c('div',{staticClass:"login-page-layout"},[_c('div',{staticClass:"login-left-layout"},[_c(VImg,{staticClass:"my-6",attrs:{"max-width":"240px","src":require("@/assets/jextream-log-mdm.png")}}),_c(VCard,{attrs:{"width":"90%","max-width":"550px"}},[_c(VCardText,[_c('p',{staticClass:"login-title-text grey--text text--darken-2"},[_vm._v("Login")]),_c(VWindow,{model:{value:(_vm.windowPage),callback:function ($$v) {_vm.windowPage=$$v},expression:"windowPage"}},[_c(VWindowItem,{attrs:{"value":'Login'}},[_c('LoginWindow')],1),_c(VWindowItem,{attrs:{"value":'Forgot-Password'}},[_c('ForgotPasswordWindow')],1),_c(VWindowItem,{attrs:{"value":'Forgot-Password-Submit'}},[_c('ForgotPasswordSubmitWindow')],1),_c(VWindowItem,{attrs:{"value":'New-Password'}},[_c('ResetPasswordWindow')],1),_c(VWindowItem,{attrs:{"value":'MFA'}},[_c('MFAWindow')],1)],1)],1)],1),_c('BottomImage')],1),_c('RightImage')],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }