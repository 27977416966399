import { API, graphqlOperation } from "aws-amplify";
import { color_code_and_carrier_details, list_Users, get_all_partners } from "@/graphql";

export const getCarrierList = async () => {
  try {
    const result = await API.graphql(
      graphqlOperation(color_code_and_carrier_details, {
        input: {
          actions: "CARRIER",
        },
      })
    );

    const response = JSON.parse(result.data.color_code_and_carrier_details);
    return response;
  } catch (err) {}
};

// passing empty object as input returns all partners
export const getPartnerList = async () => {
  try {
    const result = await API.graphql(
      graphqlOperation(get_all_partners, {
        input: {}
      })
    );
    const response = result.data.get_all_partners;
    return response;
  } catch (err) {
    console.log(err);
  }
};

export const getAccountPartnerList = async (role, id) => {
  try {
    const result = await API.graphql(
      graphqlOperation(list_Users, {
        input: {
          user_role: role,
          customer_id: id,
        },
      })
    );
    const response = JSON.parse(result.data.list_Users).business;
    return response;
  } catch (err) {
    console.log(err);
  }
};
