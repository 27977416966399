<template>
  <div style="display: grid; height: 100%;">
    <v-card-text class="pa-6">
      <v-form ref="form">
        <div style="display: grid; grid-template-columns: 1fr 1fr; column-gap: 1rem;">
          <v-text-field dense outlined label="Device Name" v-model="deviceName" :rules="deviceNameRule" maxlength="15" @keyup.enter="next()" />
          <v-text-field dense outlined label="Device Description" v-model="deviceDescription" class="ml-2" :rules="deviceDescriptionRule" maxlength="50" @keyup.enter="next()" />
          <v-select dense outlined required label="Carrier" v-model="carrierInfo" :items="carrierList" :loading="isLoading"/>
        </div>
      </v-form>
    </v-card-text>
    <v-card-actions class="pa-6">
      <v-btn @click="cancel_sign_up()" outlined class="blue--text">Cancel</v-btn>
      <v-spacer></v-spacer>
      <v-btn class="button tabledata--text mr-2" @click="back()">Back</v-btn>
      <v-btn class="button tabledata--text" :loading="isLoading" @click="next()">Next</v-btn>
    </v-card-actions>
  </div>
</template>

<script>
import axios from 'axios';
import { mixin_routing_utils } from "@/mixins/routingUtils.js";
import { mapGetters, mapMutations } from "vuex";

export default {
  components: {},
  mixins: [mixin_routing_utils],
  data() {
    return {
      carrierList: [],
      deviceNameRule: [
        value => !!value || 'Enter device name',
        value => (value && value.length >= 2) || 'Minimum length is 2 characters.',
        (val) => val.length <= 15 || 'Maxmum length is 15 characters.'
      ],
      deviceDescriptionRule: [
        value => !!value || 'Enter your device description',
        value => (value && value.length >= 2) || 'Minimum length is 2 characters.',
        (val) => val.length <= 50 || 'Maxmum length is 50 characters.'
      ],
      isLoading: false,
    };
  },
  mounted(){
    this.getCarriers();
  },
  computed: {
    ...mapGetters(["getterGetSignUpWindowPage", "getterGetDeviceName", "getterGetDeviceDescription", "getterGetCarrierInfo"]),
    windowPage: {
      get() {
        return this.getterGetSignUpWindowPage;
      },
      set(value) {
        this.mutationSetSignUpWindowPage(value);
      },
    },
    deviceName: {
      get() {
        return this.getterGetDeviceName;
      },
      set(value) {
        this.mutationSetDeviceName(value);
      },
    },
    deviceDescription: {
      get() {
        return this.getterGetDeviceDescription;
      },
      set(value) {
        this.mutationSetDeviceDescription(value);
      },
    },
    carrierInfo: {
      get() {
        return this.getterGetCarrierInfo;
      },
      set(value) {
        this.mutationSetCarrierInfo(value);
      },
    },
  },
  methods: {
    ...mapMutations(["mutationSetSnackBarItem", "mutationSetSignUpWindowPage", "mutationSetDeviceName", "mutationSetDeviceDescription", "mutationSetCarrierInfo"]),
    triggerSnackBar(type, text) {
      this.mutationSetSnackBarItem({ type, text });
    },
    back(){
      this.windowPage = 0;
    },
    next() {
      if(!this.$refs.form.validate()) return this.triggerSnackBar("error", "Fill in the mandatory fields.");
      this.windowPage = 2;
    },
    // Note that the sign up form reset action will be performed in router gaurd
    // Whenever the user returns from /SignUp page, the router guard will automatically reset the signup vuex states
    cancel_sign_up() {
      this.mixinChangeRoute("/");
    },
    //method to fetch the carrier list
    async getCarriers() {
      this.carrierList = [];
      this.isLoading = true;
      try {
        const response = await axios({ method: "POST", url: process.env.VUE_APP_MDM_ROUTER_API + "/color_code_and_carrier_details", headers: { "Content-Type": "application/json" } })
        response.data.Items.forEach((element) => {
          this.carrierList.push({ text: element.carrier_type, value: element.carrier_type });
        });
        // set default value
        this.carrierInfo = this.carrierList[0].value;
      } catch (err) {
        this.triggerSnackBar("error", err)
      }
      finally {
        this.isLoading = false;
      }
    },
  },
};
</script>

<style scoped>
</style>
