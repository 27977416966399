import { VAppBar } from 'vuetify/lib/components/VAppBar';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VAppBar,{staticStyle:{"background-color":"black"},attrs:{"flat":"","fixed":"","height":"70"}},[_c(VToolbarTitle,{staticClass:"white--text",staticStyle:{"display":"flex","align-items":"center","cursor":"pointer"},on:{"click":function($event){return _vm.close_device_details_dialog()}}},[_c(VIcon,{staticClass:"white--text",attrs:{"x-large":""}},[_vm._v("mdi-chevron-left")]),_c('div',[_c('span',{staticClass:"d-none d-sm-inline",staticStyle:{"font-weight":"bold"}},[_vm._v("Device Detail - ")]),_vm._v(_vm._s(_vm.deviceName))]),_c('em',{staticClass:"d-none d-sm-inline",staticStyle:{"margin-left":"0.5rem"}},[_vm._v("("+_vm._s(_vm.idString)+")")])],1),_c(VSpacer),_c(VBtn,{attrs:{"icon":"","large":"","color":"white"},on:{"click":_vm.refreshDeviceDetails}},[_c(VIcon,[_vm._v("mdi-refresh")])],1),(_vm.validDevice)?_c(VBtn,{staticClass:"ml-4 mr-4 white--text text-none",attrs:{"disabled":_vm.isUnlicensed,"rounded":"","color":"#0195D2"},on:{"click":function($event){return _vm.open_device_configs_dialog()}}},[_c(VIcon,{staticClass:"mr-md-1"},[_vm._v("mdi-cog")]),_c('span',{staticClass:"d-none d-md-inline"},[_vm._v("Configuration")])],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }