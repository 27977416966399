<template>
  <div>
    <v-main>
      <v-toolbar class="elevation-0 tablePadding toolbar" dense flat>
        <v-toolbar-title class="black--text mt-1">Organizations</v-toolbar-title>
        <v-select class="ml-6 filter mt-6" color="black" style="font-size: 12px; max-width: 150px" clearable v-model="partner" label="Partner" :items="partnerItems" :no-data-text="loadingText" @click:clearable="store_filter_value(partner, 'partner')" @input="store_filter_value(partner, 'partner')">
          <template #item="{ item }">
            <span class="filter-font"> {{ item }}</span>
          </template>
          <template #label v-if="loadingText == 'Loading...'">
            <i class="fas fa-spinner fa-spin ml-0 mr-1" style="color: #949494"></i>
            <span> Partner </span>
          </template>
        </v-select>

        <v-select class="ml-2 filter mt-6" color="black" style="font-size: 12px; max-width: 150px" clearable v-model="color" label="Category" :items="usedColorItems" :no-data-text="loadingText" @click:clearable="store_filter_value(color, 'color')" @input="store_filter_value(color, 'color')">
          <template #item="{ item }">
            <v-icon class="mr-1 ml-n2" :color="getHexToHex(item)">mdi-circle</v-icon>
            <span class="filter-font"> {{ getHexToText(item) }}</span>
          </template>

          <template #selection="{ item }">
            <v-icon class="mr-1 icon-font" :color="getHexToHex(item)">mdi-circle</v-icon>
            <span class="filter-font">{{ getHexToText(item) }}</span>
          </template>

          <template #label v-if="loadingText == 'Loading...'">
            <i class="fas fa-spinner fa-spin ml-0 mr-1" style="color: #949494"></i>
            <span> Category </span>
          </template>
        </v-select>

        <v-btn v-if="getterGetCurrentUserRole != 'SP_ADMIN'" class="button ml-6 mr-2" @click="add_user()" dark>Add Organzation</v-btn>
        <VueJsonToCsv
          :labels="{
            organization_created_on: { title: 'Created On' },
            partner_name: { title: 'Partner' },
            organization_name: { title: 'organization_name' },
            carrier_type: { title: 'Provider(Carrier)' },
            no_of_routers: { title: ' # of Devices' },
            organization_code: { title: 'Organization Code' },
            organization_city: { title: 'City' },
            user_email_id: { title: 'email' },
            organization_status: { title: 'Status' },
          }"
          csv-title="User List"
          :json-data="tableData"
        >
          <v-btn class="button" dark>Export</v-btn>
        </VueJsonToCsv>

        <v-spacer></v-spacer>
        <div class="container-wrapper">
          <v-text-field clearable append-icon="mdi-magnify blue--text" v-model="search" class="search-bar mr-1" label="Search" single-line style="max-width: 250px" @blur="search_deactive"></v-text-field>
        </div>
      </v-toolbar>

      <v-card-text>
        <v-data-table
          dense
          :search="search"
          :headers="getterGetCurrentUserRole == 'SP_ADMIN' ? suppHeaders : headers"
          :items="tableData"
          class="elevation-1"
          :footer-props="{
            itemsPerPageOptions: [10, 50, 100, 500, -1],
          }"
          :loading="loading1"
          :items-per-page="10"
          @click:row="handle_row_click_router"
          :no-results-text="noResultsText"
        >
          <template v-slot:[`item.organization_created_on`]="{ item }">
            <span class="caption">{{ convert_date(item.organization_created_on) }} </span>
          </template>
          <template v-slot:[`header.organization_status`]="{ header }">
            <span class="ml-2">
              {{ header.text }}
            </span>
          </template>
          <template v-slot:[`item.organization_name`]="{ item }">
            <span @click="handle_row_click_router(item)" style="cursor: pointer">
              <u>{{ item.organization_name }}</u>
            </span>
          </template>

          <template v-slot:[`item.color_code`]="{ item }">
            <v-icon :color="getColor(item.color_id)" class="ml-n1 mr-1">mdi-circle </v-icon>
            <span class="caption">{{ getColorTextUIOnly(item.color_id) }}</span>
          </template>

          <template v-slot:[`item.organization_status`]="{ item }">
            <span v-if="item.organization_status == 'INACTIVE'" class="caption grey--text text--darken-1 ml-2 bold-status">Inactive</span>
            <span v-else class="caption blue--text text--darken-2 ml-2 bold-status">Active</span>
          </template>
          <template v-slot:[`item.organization_code`]="{ item }">
            <span class="caption">{{ item.organization_code }}</span>
          </template>

          <template v-slot:[`item.user_email_id`]="{ item }">
            <span class="caption">{{ item.user_email_id }}</span>
          </template>
          <template v-slot:[`item.organization_city`]="{ item }">
            <span class="caption">{{ item.organization_city }}</span>
          </template>
          <template v-slot:[`item.partner_name`]="{ item }">
            <span class="caption">{{ item.partner_name }}</span>
          </template>
          <template v-slot:[`item.Actions`]="{ item }">
            <div ref="actions">
              <v-menu bottom left>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn icon v-bind="attrs" v-on="on" @click="menuIsActive = !menuIsActive">
                    <v-icon>mdi-dots-vertical</v-icon>
                  </v-btn>
                </template>
                <v-list dense>
                  <v-list-item v-if="item.organization_status == 'ACTIVE'" @click="toggle_org(item, 1)">
                    <v-list-item-title>Deactivate</v-list-item-title>
                  </v-list-item>
                  <v-list-item v-if="item.organization_status == 'INACTIVE'" @click="toggle_org(item, 2)">
                    <v-list-item-title>Activate </v-list-item-title>
                  </v-list-item>
                  <v-list-item v-if="item.organization_status == 'ACTIVE'" @click="login_account(item)">
                    <v-list-item-title>Login</v-list-item-title>
                  </v-list-item>
                  <v-list-item @click="edit_details(item)">
                    <v-list-item-title>Edit</v-list-item-title>
                  </v-list-item>
                  <v-list-item @click="open_delete_organization_dialog(item)">
                    <v-list-item-title>Delete</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </div>
          </template>

          <template v-slot:footer>
            <div class="footer-height">
              <LoadingIcon v-if="loadingIcon == true" style="position: absolute" class="ml-2 mt-2"></LoadingIcon>

              <span v-if="loadingIcon == true" style="position: absolute" class="ml-10 mt-5"> Loading organizations... </span>

              <span v-else>
                <v-icon @click="refresh_list()" class="ml-1 mt-3 btn-fix" dark outline="none" color="cyan" style="position: absolute; font-size: 34px" id="refresh"> mdi-refresh </v-icon>
                <span style="position: absolute" class="ml-11 pl-0 mt-5"> Last updated: {{ lastUpdated }} </span>
              </span>
            </div>
          </template>
        </v-data-table>
      </v-card-text>

      <v-dialog v-model="userLogin" persistent overflow-hidden max-width="400px">
        <v-card class="cardBg">
          <v-card-title class="medium cardTitle">
            <span>Access Account</span>
            <v-spacer></v-spacer>
            <v-btn icon @click="userLogin = false">
              <v-icon color="iconCross">mdi-close</v-icon>
            </v-btn>
          </v-card-title>

          <v-card-text class="cardBg pt-6 justify-center">
            Are you sure you want to login to organization
            <b> "{{ loginUserName }}" </b>?
          </v-card-text>

          <v-card-actions class="button-corner">
            <v-spacer></v-spacer>
            <v-btn class="blue--text" outlined @click="userLogin = false">Cancel</v-btn>
            <v-btn class="button tabledata--text" @click="confirm_login()">Login</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <!-- componets invokes on add and edit action -->
      <div v-if="componentCheck == 1">
        <AddOrganization :addUserDialog="addUserDialog" :partnerList="partnerList" :carrierItems="carrierItems" @clicked="close_dialog" @getPartners="get_partners"> </AddOrganization>
      </div>
      <div v-if="componentCheck == 2">
        <EditOrganization :editDialog="editDialog" :partnerList="partnerList" :carrierItems="carrierItems" :editDetails="editDetails" @clicked="close_dialog"> </EditOrganization>
      </div>
      <div v-if="componentCheck == 3">
        <ToggleOrg :itemCheck="itemCheck" :toggleOrgDialog="toggleOrgDialog" :toggleItem="toggleItem" @clicked="close_dialog"> </ToggleOrg>
      </div>

      <div v-if="orgCheck == true">
        <OrgRowClickDialog :groupData="groupData" :deviceData="deviceData" :modelList="modelList" :groupList="groupList" :rowDialog="rowDialog" :loadingData="loading1" :singleOrganizationInfo="singleOrganizationInfo" @closeDialog="rowDialog = false" @refreshList="refresh_org_list" />
      </div>

      <v-dialog v-model="deleteDialog" overflow-hidden persistent max-width="450px">
        <DialogCard :modalTitle="'Delete Organization'" :modalIcon="'delete'" :subTitle="deleteOrgName">
          <template #header>
            <v-btn icon x-small @click="close_delete_organization_dialog()">
              <v-icon color="white">mdi-close</v-icon>
            </v-btn>
          </template>
          <template #body>
            <div>
              Are you sure you want to delete organization:
              <b>{{ deleteOrgName }}</b
              >? <br />
              This action is permanent and cannot be undone.
            </div>
            <div class="mt-4">To confirm, type "{{ deleteOrgName }}" in the box below:</div>
            <div class="red--text mt-2" v-if="deleteOrgData.no_of_routers > 0"><b>All routers must be removed before deleting organization</b></div>
            <v-form ref="form" @submit.prevent="deleteOrg">
              <v-text-field class="mt-2" outlined dense v-model="confirmDeleteOrg" :rules="deleteOrgRules()" validate-on-blur :class="{ disabled: deleteOrgData.no_of_routers > 0 }" :disabled="deleteOrgData.no_of_routers > 0"></v-text-field>
            </v-form>
          </template>
          <template #footer>
            <v-btn small class="blue--text" outlined @click="close_delete_organization_dialog()">Cancel</v-btn>
            <v-btn :loading="isDeletingDone" small @click="deleteOrg()" class="tabledata--text mr-2" color="red" :disabled="deleteOrgData.no_of_routers > 0">Delete</v-btn>
          </template>
        </DialogCard>
      </v-dialog>

    </v-main>
  </div>
</template>
<script>
import VueJsonToCsv from "vue-json-to-csv";
import EditOrganization from "@/components/SuperAdminAcces/Organizations/EditOrganization.vue";
import AddOrganization from "@/components/SuperAdminAcces/Organizations/AddOrganization.vue";
import ToggleOrg from "@/components/SuperAdminAcces/Organizations/ToggleOrg.vue";
import DialogCard from "@/components/Slots/DialogCard.vue";

import { list_all_organization, get_organization_router_group, get_grouped_and_non_grouped_router } from "@/graphql/queries.js";
import { delete_customer_admin } from "@/graphql/mutations.js";
import OrgRowClickDialog from "@/components/SuperAdminAcces/Organizations/OrgRowClickDialog.vue";
import { API, graphqlOperation } from "aws-amplify";
import LoadingIcon from "@/components/LoadingIcon.vue";
import { mapActions, mapGetters, mapMutations } from "vuex";

import { mixin_time_utils } from "@/mixins/timeUtils";

import { getCarrierList, getPartnerList } from "@/services";

export default {
  mixins: [mixin_time_utils],
  components: {
    VueJsonToCsv,
    AddOrganization,
    OrgRowClickDialog,
    EditOrganization,
    ToggleOrg,
    LoadingIcon,
    DialogCard,
  },
  data: () => ({
    userLogin: false,
    search: "",
    addUserDialog: false,
    deleteDialog: false,
    toggleOrgDialog: false,
    loginUserName: "",
    organization: "",
    partner: "",
    loading1: false,
    itemsPerPage: 20,
    partnerList: [],
    componentCheck: 0,
    confirmDeleteOrg: "",
    isDeletingDone: false,
    suppHeaders: [
      { text: "Created On", value: "organization_created_on", class: "headerColor white--text ", sortable: true },
      { text: " Partner", value: "partner_name", class: "headerColor white--text ", sortable: true },
      { text: "Organization ", sortable: true, value: "organization_name", class: "headerColor white--text " },
      { text: "Category", sortable: true, value: "color_code", class: "headerColor white--text " },
      { text: "# of Devices ", sortable: true, value: "no_of_routers", class: "headerColor white--text " },
      { text: "Organization Code", sortable: true, value: "organization_code", class: "headerColor white--text " },
      { text: "City", sortable: true, value: "organization_city", class: "headerColor white--text " },
      { text: "Email", sortable: true, value: "user_email_id", class: "headerColor white--text " },
      { text: "Status", sortable: true, value: "organization_status", class: "headerColor white--text " },
    ],
    headers: [
      { text: "Status", sortable: true, value: "organization_status", class: "headerColor white--text " },
      { text: "Organization ", sortable: true, value: "organization_name", class: "headerColor white--text " },
      { text: "Category", sortable: true, value: "color_code", class: "headerColor white--text " },
      { text: "# of Devices ", sortable: true, value: "no_of_routers", class: "headerColor white--text " },
      { text: "Organization Code", sortable: true, value: "organization_code", class: "headerColor white--text " },
      { text: " Partner", value: "partner_name", class: "headerColor white--text ", sortable: true },
      { text: "City", sortable: true, value: "organization_city", class: "headerColor white--text " },
      { text: "Email", sortable: true, value: "user_email_id", class: "headerColor white--text " },
      { text: "Created On", value: "organization_created_on", class: "headerColor white--text ", sortable: true },
      { text: "Actions", value: "Actions", class: "headerColor white--text " },
    ],
    itemCheck: 0,
    tableData: [],
    partnerItems: [],
    organizationItems: [],
    editDialog: false,
    editDetails: {},
    singleOrganizationInfo: {},
    rowDialog: false,
    orgCheck: false,
    loginDetails: {},
    groupData: [],
    finalData: [],
    carrierItems: [],
    toggleItem: {},
    selectedOrg: "",
    selectedPartner: "",
    selectedColor: "",
    loadingIcon: true,
    color: "",
    usedColorItems: [],
    menuIsActive: false,
    groupList: [],
    deviceData: [],
    modelList: [],
    deleteOrgName: "",
    deleteOrgData: null,
    carrierList: [],
  }),
  watch: {
    rowDialog(value) {
      // When the dialog status changes, apply the class to the <html> element
      if (value) {
        document.documentElement.classList.add("hide-overlay-scroll");
      } else {
        document.documentElement.classList.remove("hide-overlay-scroll");
      }
    },
  },

  computed: {
    ...mapGetters(["getterGetUserInfo", "getterGetCurrentUserRole"]),
    loadingText() {
      if (this.loading1 == true) {
        return "Loading...";
      } else {
        return "No Data Available";
      }
    },
    noResultsText() {
      return "No matching results for " + "'" + this.search + "'";
    },
  },
  // Method gets invoked as soon as page lods
  async created() {
    this.get_organization_list();
    this.get_carrier_details();
    this.get_partners();
  },
  async mounted() {
    document.addEventListener("click", this.handleOutsideClick);
  },

  beforeUnmount() {
    clearInterval;
    document.removeEventListener("click", this.handleOutsideClick);
  },
  methods: {
    ...mapMutations(["mutationSetSnackBarItem", "mutationOpenProgressBar", "mutationCloseProgressBar"]),
    ...mapActions(["actionStartImpersonation"]),
    triggerSnackBar(type, text) {
      this.mutationSetSnackBarItem({ type, text });
    },
    //Prevents row click when action menu is open
    handleOutsideClick(event) {
      const actions = this.$refs.actions;
      if (actions != undefined && this.menuIsActive == true && !actions.contains(event.target)) {
        this.menuIsActive = false;
      }
    },

    // hexToColorName(hex) {
    //   // You can implement your logic here to convert hex to color names
    //   // For demonstration purposes, I'm using a simple mapping
    //   const colorNameMap = {
    //     "#92D050": "Light Green",
    //     "#00B050": "Green",
    //     "#2CCAFF": "Light Blue",
    //     "#0070C0": "Blue",
    //     "#28637C": "Teal",
    //     "#7030A0": "Purple",
    //     "#002060": "Dark Blue",
    //     "#FFC000": "Yellow",
    //     // Add more color mappings here
    //   };
    //   return colorNameMap[hex] || "Unknown";
    // },
    // All useless code
    getColor(item) {
      if (item == "74036d7c-bd77-48bf-a0d4-8f4d49a8267f") {
        return "#7030a0";
      } else if (item == "01282d19-90c4-47e7-95e5-40362c04c6e7") {
        return "#ED6E05";
      } else if (item == "f25a700e-3779-45aa-8004-395a775f3c76") {
        return "#AC1010";
      } else if (item == "c59d8ff2-5123-43d4-a178-7904d2bfe8b4") {
        return "#D41694";
      } else if (item == "36e80a42-57f9-46ce-95d2-5ddd0a478fd5") {
        return "#0070c0";
      } else if (item == "e165458e-3b13-4962-9303-65664b6c1f6c") {
        return "#0D3D79";
      } else if (item == "3f7cb5e2-e055-4d9e-9bf4-95fda6169b51") {
        return "#01631D";
      } else if (item == "67b5e6c6-75da-4ef7-8bb3-ed94e516dcf7") {
        return "#44A336";
      }
    },

    getColorTextUIOnly(item) {
      if (item == "74036d7c-bd77-48bf-a0d4-8f4d49a8267f") {
        return "Purple";
      } else if (item == "01282d19-90c4-47e7-95e5-40362c04c6e7") {
        return "Orange";
      } else if (item == "f25a700e-3779-45aa-8004-395a775f3c76") {
        return "Red";
      } else if (item == "c59d8ff2-5123-43d4-a178-7904d2bfe8b4") {
        return "Pink";
      } else if (item == "36e80a42-57f9-46ce-95d2-5ddd0a478fd5") {
        return "Blue";
      } else if (item == "e165458e-3b13-4962-9303-65664b6c1f6c") {
        return "Dark Blue";
      } else if (item == "3f7cb5e2-e055-4d9e-9bf4-95fda6169b51") {
        return "Green";
      } else if (item == "67b5e6c6-75da-4ef7-8bb3-ed94e516dcf7") {
        return "Light Green";
      }
    },
    getHexToHex(item) {
      if (item == "#7030A0") {
        return "#7030A0";
      } else if (item == "#FFC000") {
        return "#ED6E05";
      } else if (item == "#002060") {
        return "#AC1010";
      } else if (item == "#28637C") {
        return "#D41694";
      } else if (item == "#0070c0") {
        return "#0070C0";
      } else if (item == "#2CCAFF") {
        return "#0D3D79";
      } else if (item == "#00B050") {
        return "#01631D";
      } else if (item == "#92D050") {
        return "#44A336";
      }
    },
    getHexToText(item) {
      if (item == "#7030A0") {
        return "Purple";
      } else if (item == "#FFC000") {
        return "Orange";
      } else if (item == "#002060") {
        return "Red";
      } else if (item == "#28637C") {
        return "Pink";
      } else if (item == "#0070C0") {
        return "Blue";
      } else if (item == "#2CCAFF") {
        return "Dark Blue";
      } else if (item == "#00B050") {
        return "Green";
      } else if (item == "#92D050") {
        return "Light Green";
      }
    },
    store_filter_value(selectedValue, type) {
      if (type == "organization") {
        this.selectedOrg = selectedValue;
      } else if (type == "partner") {
        this.selectedPartner = selectedValue;
      } else if (type == "color") {
        this.selectedColor = selectedValue;
      }
      this.get_filter_result();
    },

    get_filter_result() {
      var filteredData = [];
      this.finalData.forEach((element) => {
        if (this.organization != null && this.organization != "" && this.selectedOrg != element.organization_name) {
          return;
        }
        if (this.partner != null && this.partner != "" && this.selectedPartner != element.partner_name) {
          return;
        }
        if (this.color != null && this.color != "" && this.selectedColor != element.color_code) {
          return;
        }
        filteredData.push(element);
      });
      this.tableData = filteredData;
    },

    //method invokes to fetch the user list
    async get_organization_list() {
      this.loading1 = true;
      var data = {};
      if (this.getterGetCurrentUserRole == "P_ADMIN") {
        data = {
          organization_status: "ALL",
          partner_id: this.getterGetUserInfo.user.user_id,
        };
      } else {
        data = {
          organization_status: "ALL",
        };
      }

      try {
        let result = await API.graphql(
          graphqlOperation(list_all_organization, {
            input: data,
          })
        );
        this.mixinResetStartTime();
        var response = JSON.parse(result.data.list_all_organization).organizationDetails.Items;
        this.partnerItems = [];
        this.tableData = [];
        this.organizationItems = [];
        this.usedColorItems = [];
        response.forEach((element) => {
          if (element.organization_name != "FRANKLIN") {
            this.tableData.push(element);
            this.organizationItems.push(element.organization_name);
            this.partnerItems.push(element.partner_name);
            this.usedColorItems.push(element.color_code);
          }
        });
        this.finalData = response;
      } catch (error) {
        this.triggerSnackBar("error", error.errors[0].message);
      } finally {
        this.loading1 = false;
        this.loadingIcon = false;
      }
    },

    async handle_row_click_router(item) {
      const actions = this.$refs.actions;
      if (this.menuIsActive == true && event.target.textContent != "Deactivate" && event.target.textContent != "Login" && event.target.textContent != "Edit" && event.target.textContent != "Activate" && !actions.contains(event.target)) {
        this.menuIsActive = false;
        return;
      }
      this.singleOrganizationInfo = item;
      await this.get_org_particular_list(item);
    },

    async refresh_org_list(item) {
      const actions = this.$refs.actions;
      if (this.menuIsActive == true && event.target.textContent != "Deactivate" && event.target.textContent != "Login" && event.target.textContent != "Edit" && event.target.textContent != "Activate" && !actions.contains(event.target)) {
        this.menuIsActive = false;
        return;
      }
      this.singleOrganizationInfo = item;
      await this.get_org_particular_list(item);
    },

    async get_org_particular_list(item) {
      this.groupList = ["Unassigned Devices"];
      this.deviceData = [];
      this.groupData = [];
      this.modelList = [];
      this.mutationOpenProgressBar();
      try {
        //Graphql for nongrouped routers
        let nongroupedResult = await API.graphql(
          graphqlOperation(get_grouped_and_non_grouped_router, {
            input: {
              customer_id: item.customer_id,
            },
          })
        );

        let nongroupedData = JSON.parse(nongroupedResult.data.get_grouped_and_non_grouped_router).ungroupedRouters;
        let groupedData = JSON.parse(nongroupedResult.data.get_grouped_and_non_grouped_router).groupedRouters;
        nongroupedData.forEach((router) => {
          const model = router.router_id.split("/")[0];
          const serial = router.router_id.split("/")[1];
          if (!this.modelList.includes(model)) {
            this.modelList.push(model);
          }
          var routerObject = {
            router_name: router.router_name,
            group_model: model,
            group_name: "",
            serial_no: serial,
            router_id: router.router_id,
          };

          if (!this.deviceData.some((obj) => obj.router_id == routerObject.router_id)) {
            this.deviceData.push(routerObject);
          }
        });

        //Graphql for groups in organization
        let orgResult = await API.graphql(
          graphqlOperation(get_organization_router_group, {
            input: {
              customer_id: item.customer_id,
            },
          })
        );
        const orgResponse = JSON.parse(orgResult.data.get_organization_router_group).data;
        orgResponse.forEach((group) => {
          groupedData.forEach((router) => {
            const model = router.router_id.split("/")[0];
            const serial = router.router_id.split("/")[1];
            var group_name = "";
            if (!this.modelList.includes(model)) {
              this.modelList.push(model);
            }
            if (group.routers_set && group.routers_set.includes(router.router_id)) {
              group_name = group.group_name;
              if (!this.groupList.includes(group_name)) {
                this.groupList.push(group_name);
              }
              var routerObject = {
                router_name: router.router_name,
                group_model: model,
                group_name: group_name,
                serial_no: serial,
                router_id: router.router_id,
              };
              if (!this.deviceData.some((obj) => obj.router_id == routerObject.router_id)) {
                this.deviceData.push(routerObject);
              }
            }
          });
        });
        this.groupData = orgResponse;

      } catch (error) {
        // console.log(error);
      } finally {
        this.orgCheck = true;
        this.rowDialog = true;
        this.loading1 = false;
        this.mutationCloseProgressBar();
      }
    },

    // Fuction to display the date and time format
    convert_date(dateGMTSplit) {
      function pad(x) {
        return ("00" + x).slice(-2);
      }
      if (dateGMTSplit) {
        let b = dateGMTSplit.split(/\D+/);
        let d = new Date(Date.UTC(b[0], --b[1], b[2], b[3], b[4], b[5], b[6]));
        return `${pad(d.getMonth() + 1)}/${pad(d.getDate())}/${d.getFullYear()} : ${pad(d.getHours())}:${pad(d.getMinutes())}`;
      } else {
        return "NA";
      }
    },

    //Function gets invoked on click of add
    add_user() {
      this.addUserDialog = true;
      this.componentCheck = 1;
    },

    async get_partners() {
      if (this.getterGetCurrentUserRole == "P_ADMIN") {
        this.partnerList.push({ label: this.getterGetUserInfo.user.user_full_name, value: this.getterGetUserInfo.user.user_id });
        return;
      }
      try {
        const partnerList = await getPartnerList();
        this.partnerList = partnerList.map((element) => {
          return { label: element.user_full_name, value: element.user_id };
        });
      } catch (error) {}
    },
    async get_carrier_details() {
      this.carrierItems = [];
      try {
        const carrierList = await getCarrierList();
        carrierList.Items.forEach((element) => {
          this.carrierItems.push({
            label: element.carrier_type,
            value: element.carrier_id,
          });
        });
      } catch (err) {}
    },
    //Open delete dialog
    open_delete_organization_dialog(item) {
      this.deleteOrgName = item.organization_name;
      this.deleteOrgData = item;
      this.deleteDialog = true;
    },
    //Close delete dialog
    close_delete_organization_dialog() {
      this.deleteDialog = false;
      this.$refs.form.reset();
    },
    //Validate if org is typed correctly. Delete if true
    async deleteOrg() {
      this.isDeletingDone = true;
      if (this.deleteOrgData.no_of_routers > 0) {
        this.triggerSnackBar("error", "Must remove all devices from organization before deleting");
        this.isDeletingDone = false;
        return;
      }
      if (!(this.$refs.form && this.$refs.form.validate())) {
        this.triggerSnackBar("error", "Mandatory fields are missing or have incorrect values.");
        this.isDeletingDone = false;
        return;
      }
      try {
        let result = await API.graphql(
          graphqlOperation(delete_customer_admin, {
            input: {
              customer_id: this.deleteOrgData.customer_id,
              user_email_id: this.deleteOrgData.user_email_id,
            },
          })
        );
        this.isDeletingDone = false;
        this.close_delete_organization_dialog();
        this.refresh_list();
      } catch (error) {
        // console.log(error);
        this.isDeletingDone = false;
      }
    },
    //Delete organization validation rules
    deleteOrgRules() {
      return [(value) => !!value || "This field is required", (value) => value === this.deleteOrgName || "Organization name is incorrect"];
    },
    //method invokes on close action of add popup
    close_dialog(val) {
      if (val == 0) {
        this.addUserDialog = false;
        this.editDialog = false;
        this.toggleOrgDialog = false;
        this.deleteDialog = false;
        this.componentCheck = 0;
      } else if (val == 1) {
        this.triggerSnackBar("error", "Please Provide Mandatory Fields");
        return;
      } else if (val == 2) {
        this.triggerSnackBar("success", "Created Successfully");
        this.addUserDialog = false;
        this.get_organization_list();
      } else if (val == 3) {
        this.triggerSnackBar("success", "Updated Successfully");
        this.editDialog = false;
        this.get_organization_list();
      } else if (val == 4) {
        this.triggerSnackBar("success", "Deleted Successfully");
        this.toggleOrgDialog = false;
        this.get_organization_list();
      } else if (val == 5) {
        this.triggerSnackBar("success", "Activated Successfully");
        this.toggleOrgDialog = false;
        this.get_organization_list();
      }
    },

    //method invokes on edit action
    edit_details(item) {
      this.editDetails = item;
      this.editDialog = true;
      this.componentCheck = 2;
    },
    async login_account(item) {
      this.loginUserName = item.organization_name;
      this.userLogin = true;
      this.loginDetails = item;
    },

    async confirm_login() {
      this.userLogin = false;
      await this.actionStartImpersonation({ userEmail: this.loginDetails.user_email_id, backPath: "Organization" });
    },

    //method invokes on login action
    // async send_impersonate_email() {
    //   try {
    //     let result = await API.graphql(
    //       graphqlOperation(sendImpersonationEmail, {
    //         input: {
    //           role: this.loginDetails.role,
    //           customer_id: this.loginDetails.customer_id,
    //           email: this.loginDetails.email,
    //           user_id: this.loginDetails.user_id,
    //         },
    //       })
    //     );
    //   } catch (error) {}
    // },

    //method invokes on toggle action
    toggle_org(item, val) {
      this.itemCheck = val;
      this.componentCheck = 3;
      this.toggleItem = item;
      this.toggleOrgDialog = true;
    },

    search_deactive() {
      if (this.search == null || this.search.length == 0) {
        this.search = "";
      }
    },
    async refresh_list() {
      this.loadingIcon = true;
      this.tableData = [];
      this.get_partners();
      await this.get_organization_list();
      await this.get_carrier_details();
    },
  },
};
</script>

<style scoped>
.button.v-btn {
  text-transform: none;
  height: 34px;
}
.search-bar {
  width: 400px;
  margin-top: 25px;
}
.magnify-btn {
  color: #0195d2;
}
.search-bar >>> .v-input__slot::before,
.toolbar .container-wrapper >>> .v-input__slot {
  border-color: #0195d2 !important;
  color: #0195d2 !important;
}

.v-application .search-bar >>> .mdi-close {
  color: grey !important;
}

.filter-font {
  font-size: 14px;
}
.icon-font {
  font-size: 18px;
}
.bold-status {
  font-weight: 500;
}
</style>
