import Vue from "vue";
import Vuetify from "vuetify/lib";

Vue.use(Vuetify);

export default new Vuetify({
  icons: {
    iconfont: "mdi", // default - only for display purposes
  },
  theme: {
    options: {
      customProperties: true,
    },
    themes: {
      light: {
        "primary-blue": "#0195d2",
        "primary-red": "#cd2a32",
        "primary-gray": "#BEBFC2",
        // all of the things below is probably gonnba useless
        tabledata: "#FFFFFF",
        button: "#4FBDED",  
        dashboardCard: "#f0f0f0",
        bannercolor: "#1A1D1F",
        headerColor: "#525252",
        cardBg: "#ededed",
        cardTitle: "#cecece",
        iconCross: "#000000",
        statusOnline: "#87CB16",
        statusOffline: "#FB404B",
        dashboardCount: "#0195D2",
        dashboardOnline: "4CAF50",
        dashboardOffline: "F44336",
      },
    },
  },
});
