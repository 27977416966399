import modelOptions from "@/models";

const getOptionsByModel = (model) => {
  const options = modelOptions.find((option) => option.model === model);
  return options;
};

const getOptionsByModelName = (modelName) => {
  const options = modelOptions.find((option) => option.modelName === modelName);
  return options;
};

export const router_model_utils = {
  data: () => ({}),
  methods: {
    modelToName(model) {
      const options = getOptionsByModel(model);

      if (options == undefined) {
        return undefined;
      }

      return options.modelName;
    },

    createModelCount(key, model) {
      if (model.total_router == undefined) {
        return undefined;
      }

      let modelName = this.modelToName(key);
      if (modelName == undefined) {
        return undefined;
      }

      return {
        name: modelName,
        count: model.total_router,
      };
    },

    superCreateModelCount(key, model) {
      if (!model.business || model.business.count == undefined) return undefined;

      if (!model.individual || model.individual.count == undefined) return undefined;

      let modelName = this.modelToName(key);
      if (modelName == undefined) return undefined;

      return {
        name: modelName,
        businessCount: model.business.count,
        individualCount: model.individual.count,
      };
    },

    extractModelCounts(response, account) {
      var modelCounts = {};
      if (account === "S_ADMIN") {
        for (const key in response) {
          let modelCount = this.superCreateModelCount(key, response[key]);
          if (modelCount) {
            modelCounts[modelCount.name] = {
              businessCount: modelCount.businessCount,
              individualCount: modelCount.individualCount,
            };
          }
        }
      } else {
        for (const key in response) {
          let modelCount = this.createModelCount(key, response[key]);
          if (modelCount) {
            modelCounts[modelCount.name] = modelCount.count;
          }
        }
      }
      return modelCounts;
    },

    shouldShowEthernetLinkStatus(modelName) {
      const options = getOptionsByModelName(modelName);

      if (options == undefined || options.displayOptions.showEthernetLinkStatus == undefined) {
        return false;
      }

      return options.displayOptions.showEthernetLinkStatus;
    },

    shouldShowEthernet(modelName) {
      const options = getOptionsByModelName(modelName);

      if (options == undefined || options.displayOptions.showEthernet == undefined) {
        return false;
      }

      return options.displayOptions.showEthernet;
    },
    convert_connectedStatus(statusCode) {
      const WANConnectionType = [
        "Automatic IP",
        "Static Ip",
        "PPoE",
        "L2Tp",
        "PPTP",
        "CDMA",
        "GSM",
        "UMTS",
        "LTE",
        "5G",
      ];
      return WANConnectionType[statusCode];
    },

    convert_wifiBand(bandCode) {
      const bands = [
        "2.4 GHz",
        "5 GHz",
        "6 GHz",
      ];
      return bands[bandCode];
    },

    shouldShowBatteryLevel(modelName) {
      const options = getOptionsByModelName(modelName);

      if (options == undefined || options.displayOptions.showBatterLevel == undefined) {
        return false;
      }

      return options.displayOptions.showBatterLevel;
    },

    isMobileDevice(modelName) {
      const options = getOptionsByModelName(modelName);

      if (options == undefined || options.displayOptions.isMobileDevice == undefined) {
        return false;
      }

      return options.displayOptions.isMobileDevice;
    },

    shouldShowIMEI(modelName) {
      const options = getOptionsByModelName(modelName);

      if (options == undefined || options.displayOptions.showIMEI == undefined) {
        return false;
      }

      return options.displayOptions.showIMEI;
    },

    shouldShowCarrier(modelName) {
      const options = getOptionsByModelName(modelName);

      if (options == undefined || options.displayOptions.showCarrier == undefined) {
        return true;
      } // This should default to true if the model is missing

      return options.displayOptions.showCarrier;
    },

    shouldShowSerialNo(modelName) {
      const options = getOptionsByModelName(modelName);

      if (options == undefined || options.displayOptions.showSerialNo == undefined) {
        return false;
      }

      return options.displayOptions.showSerialNo;
    },

    shouldShowLocation(modelName) {
      const options = getOptionsByModelName(modelName);

      if (options == undefined || options.displayOptions.showLocation == undefined) {
        return false;
      }

      return options.displayOptions.showLocation;
    },

    shouldShowSignal(modelName) {
      const options = getOptionsByModelName(modelName);

      if (options == undefined || options.displayOptions.showSignal == undefined) {
        return false;
      }

      return options.displayOptions.showSignal;
    },

    supportSpeedTest(modelName) {
      const options = getOptionsByModelName(modelName);

      if (options == undefined || options.displayOptions.showSpeedTest == undefined) {
        return false;
      }

      return options.displayOptions.showSpeedTest;
    },

    shouldShowDns(modelName) {
      const options = getOptionsByModelName(modelName);

      if (options == undefined || options.displayOptions.showDns == undefined) {
        return false;
      }

      return options.displayOptions.showDns;
    },

    shouldShowSubnetMask(modelName) {
      const options = getOptionsByModelName(modelName);

      if (options == undefined || options.displayOptions.showSubnetMask == undefined) {
        return false;
      }

      return options.displayOptions.showSubnetMask;
    },

    shouldShowCPUUsage(modelName) {
      const options = getOptionsByModelName(modelName);

      if (options == undefined || options.displayOptions.showCPUUsage == undefined) {
        return false;
      }

      return options.displayOptions.showCPUUsage;
    },

    shouldShowMemoryUsage(modelName) {
      const options = getOptionsByModelName(modelName);

      if (options == undefined || options.displayOptions.showMemoryUsage == undefined) {
        return false;
      }

      return options.displayOptions.showMemoryUsage;
    },

    /**
     * Configurations
     */

    canConfigMainWifi(routerDetail) {
      const options = getOptionsByModelName(routerDetail.router_model || routerDetail.group_model);
      if (options == undefined || options.configurations.canConfigMainWifi == undefined) {
        return false;
      }

      return options.configurations.canConfigMainWifi(routerDetail);
    },

    canConfigFx20Wifi(routerDetail) {
      const options = getOptionsByModelName(routerDetail.router_model || routerDetail.group_model);

      if (options == undefined || options.configurations.canConfigFx20Wifi == undefined) {
        return false;
      }

      return options.configurations.canConfigFx20Wifi(routerDetail);
    },

    canConfigConnections(routerDetail) {
      const options = getOptionsByModelName(routerDetail.router_model || routerDetail.group_model);

      if (options == undefined || options.configurations.canConfigConnections == undefined) {
        return false;
      }

      return options.configurations.canConfigConnections(routerDetail);
    },

    canConfigDisplay(routerDetail) {
      const options = getOptionsByModelName(routerDetail.router_model || routerDetail.group_model);

      if (options == undefined || options.configurations.canConfigDisplay == undefined) {
        return false;
      }

      return options.configurations.canConfigDisplay(routerDetail);
    },

    canConfigControl(routerDetail) {
      const options = getOptionsByModelName(routerDetail.router_model || routerDetail.group_model);

      if (options == undefined || options.configurations.canConfigControl == undefined) {
        return false;
      }

      return options.configurations.canConfigControl(routerDetail);
    },

    canConfigDataUsage(routerDetail) {
      const options = getOptionsByModelName(routerDetail.router_model || routerDetail.group_model);

      if (options == undefined || options.configurations.canConfigDataUsage == undefined) {
        return false;
      }

      return options.configurations.canConfigDataUsage(routerDetail);
    },

    canConfigPreference(routerDetail) {
      const options = getOptionsByModelName(routerDetail.router_model || routerDetail.group_model);

      if (options == undefined || options.configurations.canConfigPreference == undefined) {
        return false;
      }

      return options.configurations.canConfigPreference(routerDetail);
    },

    canConfigParentalControl(routerDetail) {
      const options = getOptionsByModelName(routerDetail.router_model || routerDetail.group_model);

      if (options == undefined || options.configurations.canConfigParentalControl == undefined) {
        return false;
      }

      return options.configurations.canConfigParentalControl(routerDetail);
    },

    canConfigDMZ(routerDetail) {
      const options = getOptionsByModelName(routerDetail.router_model || routerDetail.group_model);

      if (options == undefined || options.configurations.canConfigDMZ == undefined) {
        return false;
      }

      return options.configurations.canConfigDMZ(routerDetail);
    },

    canConfigDynamicDNS(routerDetail) {
      const options = getOptionsByModelName(routerDetail.router_model || routerDetail.group_model);

      if (options == undefined || options.configurations.canConfigDynamicDNS == undefined) {
        return false;
      }

      return options.configurations.canConfigDynamicDNS(routerDetail);
    },

    canConfigLANSettings(routerDetail) {
      const options = getOptionsByModelName(routerDetail.router_model || routerDetail.group_model);

      if (options == undefined || options.configurations.canConfigLANSettings == undefined) {
        return false;
      }

      return options.configurations.canConfigLANSettings(routerDetail);
    },

    canConfigDHCPRange(routerDetail) {
      const options = getOptionsByModelName(routerDetail.router_model || routerDetail.group_model);

      if (options == undefined || options.configurations.canConfigDHCPRange == undefined) {
        return false;
      }

      return options.configurations.canConfigDHCPRange(routerDetail);
    },

    canConfigVPNPassthrough(routerDetail) {
      const options = getOptionsByModelName(routerDetail.router_model || routerDetail.group_model);

      if (options == undefined || options.configurations.canConfigVPNPassthrough == undefined) {
        return false;
      }

      return options.configurations.canConfigVPNPassthrough(routerDetail);
    },

    canConfigPortForwarding(routerDetail) {
      const options = getOptionsByModelName(routerDetail.router_model || routerDetail.group_model);

      if (options == undefined || options.configurations.canConfigPortForwarding == undefined) {
        return false;
      }

      return options.configurations.canConfigPortForwarding(routerDetail);
    },

    canConfigSecurity(routerDetail) {
      const options = getOptionsByModelName(routerDetail.router_model || routerDetail.group_model);

      if (options == undefined || options.configurations.canConfigSecurity == undefined) {
        return false;
      }

      return options.configurations.canConfigSecurity(routerDetail);
    },

    canConfigBasicSecurity(routerDetail) {
      const options = getOptionsByModelName(routerDetail.router_model || routerDetail.group_model);

      if (options == undefined || options.configurations.canConfigBasicSecurity == undefined) {
        return false;
      }

      return options.configurations.canConfigBasicSecurity(routerDetail);
    },

    canConfigMACFiltering(routerDetail) {
      const options = getOptionsByModelName(routerDetail.router_model || routerDetail.group_model);

      if (options == undefined || options.configurations.canConfigMACFiltering == undefined) {
        return false;
      }

      return options.configurations.canConfigMACFiltering(routerDetail);
    },

    canConfigTODAccess(routerDetail) {
      const options = getOptionsByModelName(routerDetail.router_model || routerDetail.group_model);

      if (options == undefined || options.configurations.canConfigTODAccess == undefined) {
        return false;
      }

      return options.configurations.canConfigTODAccess(routerDetail);
    },

    canConfigContentFiltering(routerDetail) {
      const options = getOptionsByModelName(routerDetail.router_model || routerDetail.group_model);

      if (options == undefined || options.configurations.canConfigContentFiltering == undefined) {
        return false;
      }

      return options.configurations.canConfigContentFiltering(routerDetail);
    },

    canConfigURLFiltering(routerDetail) {
      const options = getOptionsByModelName(routerDetail.router_model || routerDetail.group_model);

      if (options == undefined || options.configurations.canConfigURLFiltering == undefined) {
        return false;
      }

      return options.configurations.canConfigURLFiltering(routerDetail);
    },

    canConfigServiceFiltering(routerDetail) {
      const options = getOptionsByModelName(routerDetail.router_model || routerDetail.group_model);

      if (options == undefined || options.configurations.canConfigServiceFiltering == undefined) {
        return false;
      }

      return options.configurations.canConfigServiceFiltering(routerDetail);
    },

    canConfigSystem(routerDetail) {
      const options = getOptionsByModelName(routerDetail.router_model || routerDetail.group_model);

      if (options == undefined || options.configurations.canConfigSystem == undefined) {
        return false;
      }

      return options.configurations.canConfigSystem(routerDetail);
    },

    canConfigNTPTime(routerDetail) {
      const options = getOptionsByModelName(routerDetail.router_model || routerDetail.group_model);

      if (options == undefined || options.configurations.canConfigNTPTime == undefined) {
        return false;
      }

      return options.configurations.canConfigNTPTime(routerDetail);
    },

    canConfigMISCSettings(routerDetail) {
      const options = getOptionsByModelName(routerDetail.router_model || routerDetail.group_model);

      if (options == undefined || options.configurations.canConfigMISCSettings == undefined) {
        return false;
      }

      return options.configurations.canConfigMISCSettings(routerDetail);
    },

    canConfigRemoteAdmin(routerDetail) {
      const options = getOptionsByModelName(routerDetail.router_model || routerDetail.group_model);

      if (options == undefined || options.configurations.canConfigRemoteAdmin == undefined) {
        return false;
      }

      return options.configurations.canConfigRemoteAdmin(routerDetail);
    },

    canConfigReportSets(routerDetail) {
      const options = getOptionsByModelName(routerDetail.router_model || routerDetail.group_model);

      if (options == undefined || options.configurations.canConfigReportSets == undefined) {
        return false;
      }

      return options.configurations.canConfigReportSets(routerDetail);
    },

    canConfigAlertsSets(routerDetail) {
      const options = getOptionsByModelName(routerDetail.router_model || routerDetail.group_model);

      if (options == undefined || options.configurations.canConfigAlertsSets == undefined) {
        return false;
      }

      return options.configurations.canConfigAlertsSets(routerDetail);
    },

    canConfigDebugCelluar(routerDetail) {
      const options = getOptionsByModelName(routerDetail.router_model || routerDetail.group_model);

      if (options == undefined || options.configurations.canConfigDebugCelluar == undefined) {
        return false;
      }

      return options.configurations.canConfigDebugCelluar(routerDetail);
    },

    canConfigManageFirmware(routerDetail) {
      const options = getOptionsByModelName(routerDetail.router_model || routerDetail.group_model);

      if (options == undefined || options.configurations.canConfigManageFirmware == undefined) {
        return false;
      }

      return options.configurations.canConfigManageFirmware(routerDetail);
    },
  },
};