<template>
  <div>
    <v-main>
      <v-toolbar class="elevation-0 tablePadding" dense flat>
        <v-toolbar-title class="black--text mt-1">Individual Users</v-toolbar-title>
        <v-select class="ml-6 mt-6" style="font-size: 12px; max-width: 150px" v-model="userStatusFilter" label="Filter By" :items="userStatusFilterList" @input="filter_users_by_status()" />

        <VueJsonToCsv
          :labels="{
            user_full_name: { title: 'Name' },
            user_status: { title: 'Status' },
            fxRouters: { title: 'FX20 Count' },
            rgRouters: { title: 'RG2100 Count' },
            rg2Routers: { title: 'RG2102 Count' },
            user_email_id: { title: 'Email ID' },
            user_created_on: { title: 'Created On' },
            user_created_from: { title: 'Created From' },
          }"
          csv-title="Indivisual Users"
          :json-data="filteredUsersList"
        >
          <v-btn class="mx-6 text-none" color="#0195D2" outlined><v-icon>mdi-export-variant</v-icon>Export</v-btn>
        </VueJsonToCsv>
        <v-spacer></v-spacer>
        <div class="container-wrapper">
          <v-text-field dense clearable append-icon="mdi-magnify blue--text" @click:clearable="search = ''" v-model="search" color="blue" label="Search" style="max-width: 220px; min-width: 150px;" hide-details single-line />
        </div>
      </v-toolbar>

      <v-card-text>
        <v-data-table dense :search="search" :headers="headers" :items="filteredUsersList" class="elevation-1"
          :footer-props="{ itemsPerPageOptions: [10, 50, 100, 500, -1] }" :loading="isTableLoading" :items-per-page="10" :no-results-text="`No matching results for '${search}'`">

          <template v-slot:[`item.user_full_name`]="{ item }">
            <span class="caption">{{ item.user_full_name }} </span>
          </template>

          <template v-slot:[`item.user_status`]="{ item }">
            <span class="caption ml-2">
              <span v-if="item.user_status == 'INACTIVE'" class="caption grey--text text--darken-1">Inactive</span>
              <span v-else class="caption blue--text text--darken-2">Active</span>
            </span>
          </template>

          <template v-slot:[`item.available_devices`]="{ item }">
            <span v-if="item.available_devices.length > 0" class="caption">{{ item.available_devices.join(', ') }} </span>
            <span v-else class="caption grey--text">No Devices</span>
          </template>

          <template v-slot:[`item.user_email_id`]="{ item }">
            <span class="caption">{{ item.user_email_id }}</span>
          </template>
          
          <template v-slot:[`item.user_created_from`]="{ item }">
            <span class="caption">
              <v-icon v-if="item.user_created_from == 'PHONE'" color="blue" medium>mdi-cellphone</v-icon>
              <v-icon v-else color="green" medium>mdi-laptop</v-icon>
            </span>
          </template>

          <template v-slot:[`item.user_created_on`]="{ item }">
            <span class="caption">{{ mixinConvertUTCToDate(item.user_created_on) }}</span>
          </template>

          <template v-slot:[`item.Actions`]="{ item }">
            <v-menu bottom left>
              <template v-slot:activator="{ on, attrs }">
                <v-btn icon v-bind="attrs" v-on="on">
                  <v-icon>mdi-dots-vertical</v-icon>
                </v-btn>
              </template>
              <v-list dense>
                <v-list-item v-if="item.user_status == 'ACTIVE'" @click="edit_details(item)">
                  <v-list-item-title>Edit</v-list-item-title>
                </v-list-item>
                <v-list-item v-if="item.user_status == 'ACTIVE'" @click="login_account(item)">
                  <v-list-item-title>Login</v-list-item-title>
                </v-list-item>
                <v-list-item v-if="item.user_status == 'ACTIVE'" @click="toggle_org(item)">
                  <v-list-item-title>Delete</v-list-item-title>
                </v-list-item>
                <v-list-item v-if="item.user_status == 'INACTIVE'" @click="activate_user_item(item)">
                  <v-list-item-title>Activate</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </template>

          <template v-slot:footer.prepend>
            <div v-if="isTableLoading" class="d-flex align-center">
              <LoadingIcon class="mr-3 px-2"></LoadingIcon>
              <span>Loading individual users...</span>
            </div>
            <div v-else class="d-flex align-center">
              <v-btn class="mr-3" icon text>
                <v-icon @click="refresh_list()" dark size="30" color="cyan" id="refresh"> mdi-refresh </v-icon>
              </v-btn>
              <span>Last updated: {{ mixinGetLastUpdatedTime }}</span>
            </div>
          </template>

        </v-data-table>
      </v-card-text>

      <v-dialog v-model="activateUserDialog" persistent overflow-hidden max-width="400px">
        <v-card class="dialogCard cardBg overflow-hidden">
          <v-toolbar class="dialogToolbar cardBg" dense>
            <v-toolbar-title>Activate User</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-icon @click="activateUserDialog = false" class="ml-2" color="iconCross">mdi-close</v-icon>
          </v-toolbar>

          <v-card-text class="cardBg pt-6 justify-center">Are you sure you want to activate <b> {{ fullName }}</b> ?</v-card-text>

          <v-card-actions class="button-corner">
            <v-spacer></v-spacer>
            <v-btn class="button tabledata--text" @click="activateUserDialog = false">Cancel</v-btn>
            <v-btn class="button tabledata--text mr-2" :loading="loadingactivate" @click="activate_user()">Activate</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog v-model="userLogin" persistent overflow-hidden max-width="400px">
        <v-card class="dialogCard cardBg overflow-hidden">
          <v-toolbar class="dialogToolbar cardBg" dense>
            <v-toolbar-title>Access Account</v-toolbar-title>
            <v-spacer></v-spacer>

            <v-icon @click="userLogin = false" class="ml-2" color="iconCross">mdi-close</v-icon>
          </v-toolbar>
          <v-card-text class="cardBg pt-6 justify-center">
            Are you sure you want to login to
            <b> {{ delUserName }} </b> account.
          </v-card-text>

          <v-card-actions class="button-corner">
            <v-spacer></v-spacer>
            <v-btn class="blue--text" outlined @click="userLogin = false">Cancel</v-btn>
            <v-btn class="button tabledata--text mr-2" @click="login_confirm()">Login</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <!-- componets invokes on add and edit action -->
      <div v-if="addCheck == true">
        <AddIndividualUsers :addUserDialog="addUserDialog" :timeZoneArray="timeZoneArray" :countryCodes="countryCodes" @clicked="close_dialog"> </AddIndividualUsers>
      </div>
      <div v-if="editCheck == true">
        <EditIndividualUsers :editDialog="editDialog" :timeZoneArray="timeZoneArray" :countryCodes="countryCodes" :editDetails="editDetails" @clicked="close_edit"> </EditIndividualUsers>
      </div>
      <div v-if="componentCheck == 1">
        <DeleteUserPermenent :delUserName="delUserName" :userID="userID" :delPermanently="delPermanently" @clicked="close_dialog" v-on:routersIDs="get_router" />
      </div>
      <div v-if="componentCheck == 2">
        <IndividualRouterDelete :routerData="routerData" :userID="userID" :routersDialog="routersDialog" :userEmail="userEmail" @clicked="close_dialog" />
      </div>
    </v-main>
  </div>
</template>
<script>
import VueJsonToCsv from "vue-json-to-csv";
import { deleteUserAccount, sendImpersonationEmail, delete_user_and_reactivate_user } from "@/graphql/mutations.js";
import EditIndividualUsers from "@/components/SuperAdminAcces/Accounts/EditIndividualUsers.vue";
import AddIndividualUsers from "@/components/SuperAdminAcces/Accounts/AddIndividualUsers.vue";
import DeleteUserPermenent from "@/components/SuperAdminAcces/Accounts/DeleteUserPermenent.vue";
import IndividualRouterDelete from "@/components/SuperAdminAcces/Accounts/IndividualRouterDelete.vue";
import countryList from "@/mixins/CountryList.json";
import timeZones from "@/mixins/TimeZones.json";
import { API, graphqlOperation } from "aws-amplify";
import LoadingIcon from "@/components/LoadingIcon.vue";

import { getUserList } from "@/services/module/userService";

import { mapGetters, mapMutations, mapActions } from "vuex";
import { mixin_time_utils } from "@/mixins/timeUtils";

export default {
  mixins: [mixin_time_utils],
  components: {
    VueJsonToCsv,
    AddIndividualUsers,
    EditIndividualUsers,
    DeleteUserPermenent,
    IndividualRouterDelete,
    LoadingIcon,
  },
  data() {
    return {
      isTableLoading: false,
      headers: [
        { text: "Status", value: "user_status", class: "headerColor white--text", sortable: true },
        { text: "Name", value: "user_full_name", class: "headerColor white--text", sortable: true },
        { text: "Available Devices", value: "available_devices", class: "headerColor white--text", sortable: false},
        { text: "Total Devices", value: "total_devices", class: "headerColor white--text ", sortable: true, align: "center" },
        { text: "Email ", sortable: true, value: "user_email_id", class: "headerColor white--text" },
        { text: "Created On", sortable: true, value: "user_created_on", class: "headerColor white--text" },
        { text: "Created From", sortable: true, value: "user_created_from", class: "headerColor white--text" },
        { text: "Actions", value: "Actions", class: "headerColor white--text" },
      ],
      filteredUsersList: [],
      originalUsersList: [],
      search: "",

      modelCodeTable: {
        "cgRouters": "CG890",
        "fb2Routers": "FB2000",
        "fxRouters": "FX20",
        "fx5Routers": "FX50",
        "fx7Routers": "FX70",
        "rgRouters": "RG2100",
        "rg2Routers": "RG2102",
        "rg30Routers": "RG3100",
        "rg32Routers": "RG3102",
      },

      userStatusFilter: "ALL",
      userStatusFilterList: [ {text: "All", value: "ALL"}, {text: "Active", value: "ACTIVE"}, {text: "Inactive", value: "INACTIVE"}],
  
      userLogin: false,
      componentCheck: 0,
      addUserDialog: false,
      delPermanently: false,
      delUserName: "",
      loadingDelete: false,
      userId: "",
      countryCodes: [],
      delCustomerId: "",
      status: null,
      timeZones: [],
      timeZoneArray: [],
      addCheck: false,
      activateUserDialog: false,
      loadingactivate: false,
      editDialog: false,
      editDetails: {},
      editCheck: false,
      loginDetails: {},
      fullName: "",
      delUserID: "",
      routerData: [],
      routersDialog: false,
      last_evaluated_key: null,
      userEmail: "",
  
      isMounted: false,
    }
  },
  // This refreshes the page if the user exited impersonation mode from IndividualUsers page and navigate back to the same page.
  watch: {
    "$store.state.user.impersonationArray": {
      handler() {
        this.refresh_list();
      },
      deep: true,
    },
  },
  mounted() {
    this.isMounted = true;
    this.countries();
    this.country_timezones();
    this.fetchUsersList();
  },
  beforeDestroy() {
    this.isMounted = false;
  },
  computed: {
    ...mapGetters(["getterGetUserInfo", "getterGetCurrentUserRole", "getterGetCurrentUserEmail"]),
  },
  methods: {
    ...mapActions(["actionStartImpersonation"]),
    ...mapMutations(["mutationSetSnackBarItem"]),
    triggerSnackBar(type, text) {
      this.mutationSetSnackBarItem({ type, text });
    },
    filter_users_by_status() {
      const statusFilter = {
        "ACTIVE": () => this.filteredUsersList = this.originalUsersList.filter((user) => user.user_status == "ACTIVE"),
        "INACTIVE": () => this.filteredUsersList = this.originalUsersList.filter((user) => user.user_status == "INACTIVE"),
        "ALL": () => this.filteredUsersList = this.originalUsersList
      }
      statusFilter[this.userStatusFilter]();
    },

    //method invokes to fetch the user list
    async fetchUsersList() {
      if (!this.isMounted) return;
      this.isTableLoading = true;
      this.filteredUsersList = [];
      this.originalUsersList = [];
      try {
        const response = await getUserList(this.getterGetCurrentUserRole, this.getterGetUserInfo.customer.customer_id, 100, null);
        this.last_evaluated_key = response.next_token;
        this.filteredUsersList = response.individual.map(user => { return { ...user, available_devices: this.makeModelsList(user), total_devices: this.countTotalDevices(user) } });
        this.originalUsersList = response.individual.map(user => { return { ...user, available_devices: this.makeModelsList(user), total_devices: this.countTotalDevices(user) } });
        while (this.last_evaluated_key && this.isMounted) {
          await this.fetchMoreUsers();
        }
        this.mixinResetStartTime();
      } catch (err) {
        this.triggerSnackBar("error", err.errors ? err.errors[0].message : err);
      } finally {
        this.isTableLoading = false;
      }
    },
    async fetchMoreUsers() {
      try {
        const response = await getUserList(this.getterGetCurrentUserRole, this.getterGetUserInfo.customer.customer_id, 500, this.last_evaluated_key);
        this.last_evaluated_key = response.next_token;
        this.filteredUsersList = this.filteredUsersList.concat(response.individual.map(user => { return { ...user, available_devices: this.makeModelsList(user), total_devices: this.countTotalDevices(user) } }));
        this.originalUsersList = this.originalUsersList.concat(response.individual.map(user => { return { ...user, available_devices: this.makeModelsList(user), total_devices: this.countTotalDevices(user) } }));
      } catch (err) {
        this.triggerSnackBar("error", err.errors ? err.errors[0].message : err);
      }
    },
    makeModelsList(user) {
      let modelList = [];
      let userProperties = Object.entries(user);
      userProperties.forEach(prop => {
        if(prop[0].endsWith("Routers"))
          if(prop[1] > 0) modelList.push(`${this.modelCodeTable[prop[0]]}: ${prop[1]}`)
      })
      return modelList;
    },
    countTotalDevices(user) {
      let total = 0;
      let userProperties = Object.entries(user);
      userProperties.forEach(prop => {
        if(prop[0].endsWith("Routers")) total += prop[1];
      })
      return total;
    },

    // User activation
    activate_user_item(item) {
      this.fullName = item.full_name;
      this.delUserID = item.user_id;
      this.activateUserDialog = true;
    },

    async activate_user() {
      this.loadingactivate = true;
      try {
        let result = await API.graphql(
          graphqlOperation(delete_user_and_reactivate_user, {
            input: {
              user_id: this.delUserID,
            },
          })
        );
        this.triggerSnackBar("success", "User Activated Successfully");
        this.fetchUsersList();
      } catch (err) {
        this.triggerSnackBar("error", err.errors ? err.errors[0].message : err);
      } finally {
        this.activateUserDialog = false;
        this.loadingactivate = false;
      }
    },
    //Function gets invoked on click of add
    add_user() {
      this.addUserDialog = true;
      this.addCheck = true;
    },

    // Function of displaying the country dropdown
    countries() {
      var countryWithCc = [];
      countryList.forEach((element) => {
        countryWithCc.push({
          label: element.name + "(" + element.dial_code + ")",
          value: element.dial_code,
        });
      });

      var countryListt = countryWithCc;
      this.countryCodes = countryListt;
    },

    // Function for displaying the timezones
    country_timezones() {
      var timeZoneObj = timeZones;
      this.timeZoneArray = timeZoneObj.timezones;
    },

    //method invokes on close action of add popup
    close_dialog(val) {
      if (val == 0) {
        this.delPermanently = false;
        this.routersDialog = false;
      } else if (val == 5) {
        this.triggerSnackBar("success", "Deleted successfully");
        this.delPermanently = false;
        this.fetchUsersList();
      } else if (val == 6) {
        this.delPermanently = false;
        this.componentCheck = 2;
        this.routersDialog = true;
      } else if (val == 7) {
        this.triggerSnackBar("success", "User deleted successfully");
        this.routersDialog = false;
        this.fetchUsersList();
      }
    },

    get_router(val) {
      this.routerData = val;
    },
    //method invokes on close action of edit popup
    close_edit(val) {
      if (val == 0) {
        this.editDialog = false;
      } else if (val == 1) {
        this.triggerSnackBar("error", "Please Provide Mandatory Fields");
      } else if (val == 2) {
        this.triggerSnackBar("success", "Updated successfully");
        this.editDialog = false;
        this.fetchUsersList();
      }
    },

    //method invokes on edit action
    edit_details(item) {
      this.editDetails = item;
      this.editDialog = true;
      this.editCheck = true;
    },

    login_account(item) {
      this.delUserName = item.user_full_name;
      this.userLogin = true;
      this.loginDetails = item;
    },

    async login_confirm() {
      this.userLogin = false;
      await this.actionStartImpersonation({ userEmail: this.loginDetails.user_email_id, backPath: "IndividualUsers" });
    },

    //method invokes on login action
    async send_impersonate_email() {
      try {
        let result = await API.graphql(
          graphqlOperation(sendImpersonationEmail, {
            input: {
              role: this.loginDetails.role,
              customer_id: this.loginDetails.customer_id,
              email: this.loginDetails.email,
              user_id: this.loginDetails.user_id,
            },
          })
        );
      } catch (error) {}
    },

    //method invokes on delete action
    toggle_org(item) {
      this.componentCheck = 1;
      // console.log(item);
      this.delUserName = item.user_full_name;
      this.delCustomerId = item.customer_id;
      this.userID = item.user_id;
      this.userEmail = item.user_email_id;
      this.delPermanently = true;
    },
    async refresh_list() {
      this.filteredUsersList = [];
      await this.fetchUsersList();
    },
  },
};
</script>

<style scoped>
</style>
