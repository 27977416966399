import { VBtn } from 'vuetify/lib/components/VBtn';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VDialog,{attrs:{"overflow-hidden":"","persistent":"","max-width":"500px"},model:{value:(_vm.remoteCommandDialog),callback:function ($$v) {_vm.remoteCommandDialog=$$v},expression:"remoteCommandDialog"}},[(_vm.remoteCommandDialog)?_c('DialogCard',{attrs:{"modalIcon":'remote',"modalTitle":`Remote Command - ${_vm.singleRouterInfo.router_name}`},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c(VBtn,{attrs:{"icon":"","x-small":""},on:{"click":function($event){return _vm.close_remote_command_dialog()}}},[_c(VIcon,{attrs:{"color":"white"}},[_vm._v("mdi-close")])],1)]},proxy:true},{key:"body",fn:function(){return [_c(VRow,{staticClass:"mt-6",attrs:{"no-gutters":""}},[_c(VSelect,{attrs:{"label":"Command to send","items":_vm.$store.getters.getterGetCurrentUserRole == 'SU_ADMIN' ? _vm.superCommands : _vm.normalCommands,"outlined":"","dense":"","required":""},model:{value:(_vm.commandType),callback:function ($$v) {_vm.commandType=$$v},expression:"commandType"}})],1)]},proxy:true},{key:"footer",fn:function(){return [_c(VBtn,{staticClass:"blue--text",attrs:{"small":"","outlined":""},on:{"click":function($event){return _vm.close_remote_command_dialog()}}},[_vm._v("Cancel")]),_c(VBtn,{staticClass:"button tabledata--text mr-2",attrs:{"small":"","loading":_vm.loadingSendDeviceCommand},on:{"click":function($event){return _vm.send_command_to_device()}}},[_vm._v("Send")])]},proxy:true}],null,false,243190725)}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }