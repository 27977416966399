import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VForm } from 'vuetify/lib/components/VForm';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c(VLayout,[_c('div',{attrs:{"justify-center":""}},[_c(VCard,{staticClass:"mt-4 pt-2 justify-center"},[_c(VCardTitle,[_vm._v("Change Email")]),_c(VCardText,[_c(VForm,{ref:"form",on:{"submit":function($event){$event.preventDefault();}}},[_c('div',[_vm._v("Enter a new email address")]),_c(VTextField,{staticClass:"mt-6",staticStyle:{"width":"400px"},attrs:{"dense":"","label":"Email","rules":_vm.mixinLoginEmailRule(),"outlined":""},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;$event.preventDefault();return _vm.submit_email_change.apply(null, arguments)}},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}})],1)],1),_c(VCardActions,{staticClass:"justify-end mr-2"},[_c(VBtn,{staticClass:"blue--text mt-n4 ml-2 mb-2 normal-text",attrs:{"small":"","depressed":"","outlined":"","color":"white"},on:{"click":function($event){return _vm.previous_step()}}},[_vm._v(" Cancel ")]),_c(VBtn,{staticClass:"button tabledata--text mt-n4 ml-2 mb-2 normal-text",attrs:{"small":"","depressed":"","loading":_vm.loading},on:{"click":function($event){return _vm.submit_email_change()}}},[_vm._v(" Change Email ")])],1)],1)],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }