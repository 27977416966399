<template>
  <div :class="`signal-${signal}`" :style="`transform: scale(${scale})`">
    <i class="icon__signal-strength">
      <span class="bar-1"></span>
      <span class="bar-2"></span>
      <span class="bar-3"></span>
      <span class="bar-4"></span>
      <span class="bar-5"></span>
    </i>
  </div>
</template>

<script>
export default {
  props: {
    signal: Number,
    scale: {
      type: Number,
      default: 0.7
    }
  },
};
</script>

<style lang="scss" scoped>
i {
  border-radius: 4px;
}

i:hover span {
  animation-name: signal-intro;
  animation-play-state: running;
}

$icon-width: auto;
$icon-height: 35px;
$icon-padding: 4px;
$bar-width: 4px;
$bar-spacing: 4px;
$bar-radius: 0px;
$bar-color: var(--v-primary-blue-base);
$bar-start-height: 2px;
$bar-start-opacity: 0.2;
$bars-show-all: true; // false shows little nubs
$bars-use-grow-anim: true; // false uses fade anim

.icon__signal-strength {
  display: inline-flex;
  align-items: flex-end;
  justify-content: flex-end;
  width: $icon-width;
  height: $icon-height;
  padding: $icon-padding;
}
.icon__signal-strength span {
  display: inline-block;
  width: $bar-width;
  margin-left: $bar-spacing;
  transform-origin: 100% 100%;
  background-color: $bar-color;
  border-radius: $bar-radius;

  animation-iteration-count: 1;
  animation-timing-function: cubic-bezier(0.17, 0.67, 0.42, 1.3);
  animation-fill-mode: both;
  animation-play-state: paused;
}

.icon__signal-strength {
  .bar-1 {
    height: 20%;
    animation-duration: 0.3s;
    animation-delay: 0.1s;

  }
  .bar-2 {
    height: 40%;
    animation-duration: 0.25s;
    animation-delay: 0.2s;

  }
  .bar-3 {
    height: 60%;
    animation-duration: 0.2s;
    animation-delay: 0.3s;

  }
  .bar-4 {
    height:80%;
    animation-duration: 0.15s;
    animation-delay: 0.4s;

  }
  .bar-5 {
    height: 100%;
    animation-duration: 0.15s;
    animation-delay: 0.4s;

  }
}
.signal-0 {
  .bar-1,
  .bar-2,
  .bar-3,
  .bar-4,
  .bar-5 {
    opacity: $bar-start-opacity;

    @if $bars-show-all == false {
      height: $bar-start-height;
    }
  }
}
.signal-1 {
  .bar-2,
  .bar-3,
  .bar-4,
  .bar-5 {
    opacity: $bar-start-opacity;

    @if $bars-show-all == false {
      height: $bar-start-height;
    }
  }
}
.signal-2 {
  .bar-3,
  .bar-4,
  .bar-5 {
    opacity: $bar-start-opacity;

    @if $bars-show-all == false {
      height: $bar-start-height;
    }
  }
}
.signal-3 {
  .bar-4,
  .bar-5 {
    opacity: $bar-start-opacity;

    @if $bars-show-all == false {
      height: $bar-start-height;
    }
  }
}
.signal-4 {
  .bar-5 {
    opacity: $bar-start-opacity;

    @if $bars-show-all == false {
      height: $bar-start-height;
    }
  }
}

// -- component load animation
@keyframes signal-intro {
  from {
    opacity: $bar-start-opacity;

    @if $bars-use-grow-anim {
      height: $bar-start-height;
    }
  }
}
</style>
