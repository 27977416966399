import HelpTable from "./HelpTable.json"

const state = {
  helpObject: {},
};

const getters = {
  // This is used for both dashboard info and router count
  getterGetHelp: (state) => {
    return state.helpObject;
  },
};

const mutations = {
  mutationSetHelp: (state, { model, id }) => {
    state.helpObject = HelpTable[model][id] ? { ...HelpTable[model][id] } : {};
  },
};

export default {
  state,
  getters,
  mutations,
};
