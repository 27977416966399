import { render, staticRenderFns } from "./BasicSecurity.vue?vue&type=template&id=314e7bdf&scoped=true&"
import script from "./BasicSecurity.vue?vue&type=script&lang=js&"
export * from "./BasicSecurity.vue?vue&type=script&lang=js&"
import style0 from "./BasicSecurity.vue?vue&type=style&index=0&id=314e7bdf&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "314e7bdf",
  null
  
)

export default component.exports