import CryptoJS from 'crypto-js';

const PREFIX = "a&3+~";
export default class Crypto {
  constructor (password) {
    this.user = PREFIX + password;
    this._postfix = Math.floor(Date.now() / 60000);
  }
  encrypt(data, password) {
    return CryptoJS.AES.encrypt(data, password || this.password).toString();
  }
  decrypt(cipherParam, password) {
    return CryptoJS.AES.decrypt(cipherParam, password || this.password).toString(CryptoJS.enc.Utf8);
  }
  get password() {
    return this.user + this._postfix;
  }
  rewind(n = 0) {
    if (typeof n === "number")
      this._postfix -= n;
    return this._postfix;
  }
  cleanup () {
    this.user = Math.random() * 10;
  }
}
