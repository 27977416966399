import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c(VDialog,{attrs:{"persistent":"","overflow-hidden":"","max-width":"400px"},model:{value:(_vm.routersDialog),callback:function ($$v) {_vm.routersDialog=$$v},expression:"routersDialog"}},[_c(VCard,{staticClass:"dialogCard cardBg overflow-hidden"},[_c(VToolbar,{staticClass:"dialogToolbar cardBg",attrs:{"dense":""}},[_c(VToolbarTitle,[_vm._v("Delete Router")]),_c(VSpacer),_c(VIcon,{staticClass:"ml-2",attrs:{"color":"iconCross"},on:{"click":function($event){return _vm.close_dialog()}}},[_vm._v("mdi-close")])],1),_c(VCardText,{staticClass:"cardBg pt-6 justify-center"},[_vm._v(" Are you sure you want to delete all routers assigned to the user? "),_c('br'),_vm._l((_vm.routerData),function(router){return _c('div',{key:router.id},[_vm._v("- "+_vm._s(router))])})],2),_c(VCardActions,{staticClass:"button-corner"},[_c(VSpacer),_c(VBtn,{staticClass:"blue--text",attrs:{"outlined":"","color":"white"},on:{"click":function($event){return _vm.close_dialog()}}},[_vm._v("Cancel")]),_c(VBtn,{staticClass:"button tabledata--text mr-2",attrs:{"loading":_vm.loadingDelete},on:{"click":function($event){return _vm.delete_router()}}},[_vm._v("Delete")])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }