<template>
  <v-card v-if="hasReminder" flat class="expiration-parent-card">
    <v-carousel height="fit-content" delimiter-icon="mdi-minus" hide-delimiter-background hide-delimiters show-arrows-on-hover>
      <v-carousel-item v-for="(item, index) in totalTextData" :key="item.titleText">
        <div class="expiration-big-card">
          <p>Action Required</p>
          <h2>Your Monthly Plan <span>{{ item.titleText }}</span></h2>
          <p>Your monthly plan for "<span>{{ item.routerIDText }}</span>" <span>{{ item.pastExpiration ? "expired" : "expires" }}</span> on <span>{{ item.expiryDateText }}</span>.
            Renew now to continue enjoying features like... (FINISH THIS WITH LIST OF FEATURES)</p>
          <div class="interaction-container">
            <v-btn color="primary">Renew</v-btn>
            <p> or </p>
            <v-select class="select-dropdown" @change="onSelectChange($event, item)" :items="items" label="Hide this"></v-select>
          </div>
        </div>
      </v-carousel-item>
    </v-carousel>
  </v-card>
</template>


<script>
import { mapMutations, mapGetters } from "vuex";

const titleText = {
  cancel: "Has Been Cancelled",
  expire: "Has Expired",
}

const routerIDText = (device) => {
  return device.router_name + " (IMEI: " + device.imei_number + ")";
}

const expiryDateText = (device) => {
  return device.expire_date.substring(0, device.expire_date.indexOf("T"));
}

export default {
  name: "ExpirationCard",
  props: {
    cancelledSubs: {
      type: Array,
      default: () => []
    },
    expiredSubs: {
      type: Array,
      default: () => []
    },
  },
  data() {
    return {
      items: [
        { text: "for Today", value: 0 },
        { text: "for This Week", value: 1 },
        { text: "Do not Show Me Again", value: 2 },
      ]
    }
  },
  computed: {
    ...mapGetters(["getterGetReminderDate"]),
    cancelledTextData() {
      return this.cancelledSubs.map((device) => {
        return {
          titleText: titleText.cancel,
          routerIDText: routerIDText(device),
          expiryDateText: expiryDateText(device),
          pastExpiration: false,
          imei_number: device.imei_number,
        };
      });
    },
    expiredTextData() {
      return this.expiredSubs.map((device) => {
        return {
          titleText: titleText.expire,
          routerIDText: routerIDText(device),
          expiryDateText: expiryDateText(device),
          pastExpiration: true,
          imei_number: device.imei_number,
        };
      });
    },
    totalTextData() {
      const unfiltered = this.cancelledTextData.concat(this.expiredTextData);
      return unfiltered.filter((item) => {
        return this.isPastReminderDate(item);
      });
    },
    hasReminder() {
      return this.totalTextData.length > 0;
    }
  },
  methods: {
    ...mapMutations(["mutationSetReminderDate"]),
    addReminder(item, length) {
      const now = new Date();
      const reminderDate = length !== -1 ? new Date(now.getTime() + length * 60 * 60 * 1000) : -1;
      this.mutationSetReminderDate({
        router_id: item.imei_number,
        reminderDate: reminderDate,
      });
    },
    onSelectChange(event, item) {
      switch (event) {
        case 0:
          this.addReminder(item, 24);
          break;
        case 1:
          this.addReminder(item, 24* 7);
          break;
        case 2:
          this.addReminder(item, -1);
          break;
        default:
          break;
      }
    },
    isPastReminderDate(item) {
      const reminderDate = this.getterGetReminderDate(item.imei_number); // -1 || string (which parses to Date) || null
      return reminderDate == null || (reminderDate !== -1 && Date.parse(reminderDate) < new Date()); 
    },
  },
};

</script>

<style>
.interaction-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  min-width: fit-content;
  max-width: 20rem;
}

.interaction-container>button {
  margin-right: 0.5rem;
  margin-top: auto;
  margin-bottom: auto;  
}

.interaction-container>p {
  margin-right: 0.5rem;
  margin-left: 0.5rem;
  margin-top: auto;
  margin-bottom: auto;  
}

.interaction-container>div {
  margin-left: 0.5rem;
  margin-top: 0.5rem;
  margin-bottom: auto;  
}

.expiration-big-card {
  padding-bottom: 2rem;
  padding-left: 4rem;
  padding-right: 4rem;
  padding-top: 1rem;
}

.expiration-parent-card {
  min-height: fit-content;
  min-width: fit-content;
  margin: 1rem;
  margin-bottom: 0;
}

.select-dropdown .v-select__selections>input{
  cursor: pointer;
}

</style>

<style scoped>
.expiration-parent-card {
  border: solid 1px #E5E5EA;
}
</style>