import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VForm } from 'vuetify/lib/components/VForm';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"display":"grid","height":"100%"}},[_c(VCardText,{staticClass:"pa-6"},[_c(VForm,{ref:"form"},[_c('div',{staticStyle:{"display":"grid","grid-template-columns":"1fr 1fr","column-gap":"1rem"}},[_c(VTextField,{attrs:{"dense":"","outlined":"","label":"Device Name","rules":_vm.deviceNameRule,"maxlength":"15"},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.next()}},model:{value:(_vm.deviceName),callback:function ($$v) {_vm.deviceName=$$v},expression:"deviceName"}}),_c(VTextField,{staticClass:"ml-2",attrs:{"dense":"","outlined":"","label":"Device Description","rules":_vm.deviceDescriptionRule,"maxlength":"50"},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.next()}},model:{value:(_vm.deviceDescription),callback:function ($$v) {_vm.deviceDescription=$$v},expression:"deviceDescription"}}),_c(VSelect,{attrs:{"dense":"","outlined":"","required":"","label":"Carrier","items":_vm.carrierList,"loading":_vm.isLoading},model:{value:(_vm.carrierInfo),callback:function ($$v) {_vm.carrierInfo=$$v},expression:"carrierInfo"}})],1)])],1),_c(VCardActions,{staticClass:"pa-6"},[_c(VBtn,{staticClass:"blue--text",attrs:{"outlined":""},on:{"click":function($event){return _vm.cancel_sign_up()}}},[_vm._v("Cancel")]),_c(VSpacer),_c(VBtn,{staticClass:"button tabledata--text mr-2",on:{"click":function($event){return _vm.back()}}},[_vm._v("Back")]),_c(VBtn,{staticClass:"button tabledata--text",attrs:{"loading":_vm.isLoading},on:{"click":function($event){return _vm.next()}}},[_vm._v("Next")])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }