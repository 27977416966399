import { getSuperAdminDashboardDetails, getCustomerAdminDashboardDetails } from "@/services";

const state = {
  superAdminDashbaordInfo: null,
  customerAdminDashbaordInfo: null,
};

const getters = {
  // This is used for both dashboard info and router count
  getterGetSuperAdminDashboardInfo: (state) => {
    return state.superAdminDashbaordInfo;
  },
  getterGetCustomerAdminDashboardInfo: (state) => {
    return state.customerAdminDashbaordInfo;
  },
};

const mutations = {
  mutationSetSuperAdminDashboardInfo: (state, payload) => {
    state.superAdminDashbaordInfo = payload;
  },
  mutationSetCustomerAdminDashboardInfo: (state, payload) => {
    state.customerAdminDashbaordInfo = payload;
  },
};

const actions = {
  actionGetSuperAdminDashBoardDetails: async (context) => {
    try {
      const result = await getSuperAdminDashboardDetails();
      context.commit("mutationSetSuperAdminDashboardInfo", result);
    } catch (err) {
      throw err;
    }
  },
  actionGetCustomerAdminDashBoardDetails: async (context, customerId) => {
    try {
      const result = await getCustomerAdminDashboardDetails(customerId);
      context.commit("mutationSetCustomerAdminDashboardInfo", result);
    } catch (err) {
      throw err;
    }
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
