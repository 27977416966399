import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCarousel } from 'vuetify/lib/components/VCarousel';
import { VCarouselItem } from 'vuetify/lib/components/VCarousel';
import { VSelect } from 'vuetify/lib/components/VSelect';

var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.hasReminder)?_c(VCard,{staticClass:"expiration-parent-card",attrs:{"flat":""}},[_c(VCarousel,{attrs:{"height":"fit-content","delimiter-icon":"mdi-minus","hide-delimiter-background":"","hide-delimiters":"","show-arrows-on-hover":""}},_vm._l((_vm.totalTextData),function(item,index){return _c(VCarouselItem,{key:item.titleText},[_c('div',{staticClass:"expiration-big-card"},[_c('p',[_vm._v("Action Required")]),_c('h2',[_vm._v("Your Monthly Plan "),_c('span',[_vm._v(_vm._s(item.titleText))])]),_c('p',[_vm._v("Your monthly plan for \""),_c('span',[_vm._v(_vm._s(item.routerIDText))]),_vm._v("\" "),_c('span',[_vm._v(_vm._s(item.pastExpiration ? "expired" : "expires"))]),_vm._v(" on "),_c('span',[_vm._v(_vm._s(item.expiryDateText))]),_vm._v(". Renew now to continue enjoying features like... (FINISH THIS WITH LIST OF FEATURES)")]),_c('div',{staticClass:"interaction-container"},[_c(VBtn,{attrs:{"color":"primary"}},[_vm._v("Renew")]),_c('p',[_vm._v(" or ")]),_c(VSelect,{staticClass:"select-dropdown",attrs:{"items":_vm.items,"label":"Hide this"},on:{"change":function($event){return _vm.onSelectChange($event, item)}}})],1)])])}),1)],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }