import router from "@/router/index";
import { getCurrentUserDetails, userLogout } from "@/services";
import { currentSession, checkPintracUser } from "@/services";
import { Auth } from "aws-amplify";

const userRole = {
  SU_ADMIN: "Super Admin",
  C_ADMIN: "Admin",
  SP_ADMIN: "Support Staff",
  P_ADMIN: "Partner",
  G_M_ADMIN: "Group Manager",
  INDIVIDUAL: "User",
  C_ADMIN_1: "Org Admin",
};

function generateRandomId() {
  const randomPart = Math.random().toString(36).substring(2, 15);
  const timePart = new Date().getTime().toString(36);
  return timePart + randomPart;
}

const changeRoute = (route) => {
  router.push(route).catch(() => {});
};

const state = {
  // used for Pintrac login
  isPintracAccount: false,

  // MDM user login information
  isAuthenticated: false,
  userInfo: null,
  userEmail: "",

  // Impersonation feature variables
  isImpersonating: false,
  impersonationArray: [],
};

const getters = {
  // Pintrac login related
  getterGetIsPintracAccount: (state) => {
    return state.isPintracAccount;
  },
  // MDM user related
  getterGetIsAuthenticated: (state) => {
    return state.isAuthenticated;
  },
  getterGetOriginalUserEmail: (state) => {
    return state.userEmail;
  },
  getterGetUserInfo: (state) => {
    let currentPersonationIndex = state.impersonationArray.length - 1;
    if (state.userInfo) return state.isImpersonating ? state.impersonationArray[currentPersonationIndex].userInfo : state.userInfo;
    else return "";
  },
  getterGetCurrentUserEmail: (state) => {
    let currentPersonationIndex = state.impersonationArray.length - 1;
    return state.isImpersonating ? state.impersonationArray[currentPersonationIndex].userEmail : state.userEmail;
  },
  getterGetCurrentUserRoleString: (state) => {
    let currentPersonationIndex = state.impersonationArray.length - 1;
    return state.isImpersonating ? userRole[state.impersonationArray[currentPersonationIndex].userInfo.user.user_role] : userRole[state.userInfo.user.user_role];
  },
  getterGetCurrentUserRole: (state) => {
    let currentPersonationIndex = state.impersonationArray.length - 1;
    if (state.userInfo) return state.isImpersonating ? state.impersonationArray[currentPersonationIndex].userInfo.user.user_role : state.userInfo.user.user_role;
    else return "";
  },
  getterGetImpersonationArray: (state) => {
    return state.impersonationArray;
  },
  // flag for checking whether impersonation mode is on or off
  getterGetIsImpersonating: (state) => {
    return state.isImpersonating;
  },
};

const mutations = {
  // Pintrac login related
  mutationSetIsPintracAccount: (state, payload) => {
    state.isPintracAccount = payload;
  },
  // user information after login
  mutationSetIsAuthenticated: (state, payload) => {
    state.isAuthenticated = payload;
  },
  mutationSetUserInfo: (state, payload) => {
    state.userInfo = payload;
  },
  mutationSetCurrentUserEmail: (state, payload) => {
    state.userEmail = payload;
  },
  // impersonation setting
  mutationSetIsImpersonating: (state, payload) => {
    state.isImpersonating = payload;
  },
  mutationResetImpersonation: (state) => {
    state.impersonationArray = [];
  },
  mutationAddImpersonation: (state, { userEmail, userInfo, backPath }) => {
    state.impersonationArray.push({ id: generateRandomId(), userEmail, userInfo, backPath });
  },
  mutationRemoveImpersonation: (state, id) => {
    let impersonationIndex = state.impersonationArray.findIndex((item) => id === item.id);
    state.impersonationArray = state.impersonationArray.slice(0, impersonationIndex + 1);
  }
};

const actions = {
  actionGetUserInfo: async ({commit, state}, userEmail) => {
    try {
      commit("mutationOpenCircularProgressLoader");
      
      // Set user information that will be used for graphql requests for the rest of the application
      commit("mutationSetCurrentUserEmail", userEmail);
      commit("mutationSetIsAuthenticated", true);
      
      const result = await getCurrentUserDetails(userEmail);
      commit("mutationSetUserInfo", result);

      // check if the current user logging in is also Pintrac user
      // const session = await currentSession();
      // const isPintracUser = await checkPintracUser(state.userInfo.user.user_id, session.getIdToken().getJwtToken());
      // if(isPintracUser.code === 0) commit("mutationSetIsPintracAccount", true);
      changeRoute("/Home");
    } catch (error) {
      throw error;
    } finally {
      commit("mutationCloseCircularProgressLoader");
    }
  },
  actionLogOut: async ({ commit }, userEmail) => {
    try {
      commit("mutationOpenCircularProgressLoader");
      // clear Pintrac related info
      commit("mutationSetIsPintracAccount", false);
      // clear user related values
      commit("mutationSetIsAuthenticated", false);
      commit("mutationSetUserInfo", null);
      commit("mutationSetCurrentUserEmail", "");
      // clear impersonation mode related values
      commit("mutationSetIsImpersonating", false);
      commit("mutationResetImpersonation");
      // This graphql request doesn't seem to do anything other than saving log in the server side. It has nothing to do with frontend token management
      await userLogout(userEmail);
      // This amplify function actually logs the user out and deletes ID token, access token, and refresh token stored in the browser
      await Auth.signOut();
      changeRoute("/");
    } catch (error) {
      console.log(error);
    } finally {
      commit("mutationCloseCircularProgressLoader");
    }
  },
  // This feature logic keeps impersonation state's overall history
  // The user can go back to whatever account they came from using the history
  actionStartImpersonation: async ({ commit, state }, { userEmail, backPath = "/Home" }) => {
    try {
      commit("mutationOpenCircularProgressLoader");
      const userInfo = await getCurrentUserDetails(userEmail);
      // set impersonation mode initially
      if (!state.isImpersonating && state.impersonationArray.length === 0) commit("mutationSetIsImpersonating", true);
      commit("mutationAddImpersonation", { userEmail, userInfo, backPath });
      changeRoute("/Home");
    } catch (error) {
      throw error;
    } finally {
      commit("mutationCloseCircularProgressLoader");
    }
  },
  // action to go back to some user in the impersonation history
  actionBackTrackImpersonation: ({ commit, state }, {id, backPath}) => {
    // Do nothing if the user click on the last impersonation v-chip
    const currentItemIndex = state.impersonationArray.findIndex(item => item.id === id);
    const lastItemIndex = state.impersonationArray.length - 1;
    if(currentItemIndex === lastItemIndex) return;

    commit("mutationOpenCircularProgressLoader")
    if (state.impersonationArray.length === 1) commit("mutationSetIsImpersonating", false);
    commit("mutationRemoveImpersonation", id);
    changeRoute(`/Home/${backPath}`);
    commit("mutationCloseCircularProgressLoader");
  },
  // End impersonation and empty out the impersonation array
  actionEndImpersonation: async ({ commit, state }) => {
    try {
      // Saving backpath state first is necessary since the last item in the array will be removed the reference to backPath will no longer exist
      let backpath = state.impersonationArray[0].backPath;
      commit("mutationOpenCircularProgressLoader");
      commit("mutationSetIsImpersonating", false);
      // remove all items in the array and then call changeRoute
      // This is important since "getterGetUserInfo" changes the user role return value based on impersonationArray's length
      commit("mutationResetImpersonation");
      changeRoute(`/Home/${backpath}`);
    } catch(error) {

    } finally {
      commit("mutationCloseCircularProgressLoader");
    }
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
