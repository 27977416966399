import router from '@/router/index'

export const mixin_routing_utils = {
  methods: {
    mixinChangeRoute(route) {
      router.push(route).catch(() => {});
    },
    mixinDataChangeRoute(name, data) {
      router.push({ name: name, params: data }).catch((err) => {
        if (err.name !== 'NavigationDuplicated') console.error(err);
      });
    }
  },
};