<template>
  <v-dialog v-model="active" persistent max-width="400px">
    <DialogCard modalIcon="tag-edit-outline" modalTitle="Edit">
      <template #body>
        <v-text-field v-model="routerName" label="Device Name"></v-text-field>
        <v-textarea v-model="routerDescription" label="Device Description"></v-textarea>
      </template>

      <template #footer>
        <v-btn small @click="cancel_edit()" class="blue--text" outlined>Cancel</v-btn>
        <v-btn small @click="save_edit()" class="button tabledata--text mr-2">Save</v-btn>
      </template>
    </DialogCard>
  </v-dialog>
</template>

<script>
import DialogCard from "@/components/Slots/DialogCard.vue";

import { mapGetters, mapActions, mapMutations } from "vuex";
import { setDeviceDetails } from "@/services";

export default {
  components: {
    DialogCard,
  },
  props: {
    active: Boolean,
    initRouterName: String,
    initRouterDescription: String,
  },
  model: {
    prop: "active",
    event: "update",
  },
  data() {
    return {
      routerName: this.initRouterName,
      routerDescription: this.initRouterDescription,
      confirmation: false,
    }
  },
  computed: {
    ...mapGetters(["getterGetDeviceConfigurations"]),
    deviceId() {
      return this.getterGetDeviceConfigurations.router_id;
    },
    routerActiveStatus() {
      return this.getterGetDeviceConfigurations ? this.getterGetDeviceConfigurations.router_details.router_activated : "";
    },
  },
  methods: {
    ...mapActions(["actionGetSingleDeviceInfo", "actionGetDeviceConfiguration"]),
    ...mapMutations(["mutationSetSnackBarItem", "mutationOpenProgressBar", "mutationCloseProgressBar"]),
    cancel_edit() {
      this.routerName = this.initRouterName;
      this.routerDescription = this.initRouterDescription;
      this.$emit("update", false);
    }, 
    save_edit() {
      this.confirm_device_detail_changes();
      this.$emit("update", false);
    },
    triggerSnackBar(type, text) {
      this.mutationSetSnackBarItem({ type, text });
    },
    async confirm_device_detail_changes(){
      const descriptionObj = {
        router_id: this.deviceId,
        router_name: this.routerName,
        router_description: this.routerDescription,
        router_activated: this.routerActiveStatus,
      };
      this.mutationOpenProgressBar();
      try {
        await setDeviceDetails(descriptionObj);
        await this.actionGetSingleDeviceInfo(this.deviceId);
        await this.actionGetDeviceConfiguration(this.deviceId);
        this.triggerSnackBar("success", "Updated device information successfully");
        this.$emit("close");
      } catch (err) {
        this.triggerSnackBar("error", err.errors ? err.errors[0].message : err);
      } finally {
        this.mutationCloseProgressBar();
      }
    }
  },
}
</script>