import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
import { VDataTable } from 'vuetify/lib/components/VDataTable';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VCard,{attrs:{"flat":""}},[_c(VCardTitle,[_vm._v(" Connected Device History ")]),_c(VCardText,[_c(VDataTable,{staticClass:"connected-table dtwidth",attrs:{"mobile-breakpoint":0,"headers":_vm.routerDeviceData,"items":_vm.getDeviceDHCPClients,"sort-by":['updated_on'],"sort-desc":[true],"footer-props":{ itemsPerPageText: 'Items per page' },"dense":""},scopedSlots:_vm._u([{key:`item.host_name`,fn:function({ item }){return [_c('span',{staticClass:"caption",class:item.connected ? 'primary-blue--text' : 'grey--text'},[_vm._v(_vm._s(item.host_name))])]}},{key:`item.updated_on`,fn:function({ item }){return [_c('span',{staticClass:"caption"},[_vm._v(_vm._s(_vm.convert_date(item.updated_on)))])]}},{key:`item.ip_address`,fn:function({ item }){return [_c('span',{staticClass:"caption"},[_vm._v(_vm._s(item.ip_address))])]}},{key:`item.interface_type`,fn:function({ item }){return [_c(VChip,{staticClass:"white--text my-2",attrs:{"x-small":"","color":item.interface_type == 'Wireless' ? 'orange' : 'primary'}},[_vm._v(" "+_vm._s(item.interface_type == "Wireless" ? "Wi-Fi" : "Ethernet")+" ")])]}},{key:`item.mac_address`,fn:function({ item }){return [_c('span',{staticClass:"caption",domProps:{"textContent":_vm._s(item.mac_address ? item.mac_address : '-')}})]}}],null,true)})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }