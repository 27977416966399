import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c(VMenu,{attrs:{"close-on-content-click":false,"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c(VBtn,_vm._g(_vm._b({staticClass:"text-none",attrs:{"elevation":"0","outlined":"","small":"","color":_vm.buttonColor}},'v-btn',attrs,false),on),[_c(VIcon,[_vm._v("mdi-view-column-outline")]),_vm._v("Columns")],1)]}}]),model:{value:(_vm.openMenu),callback:function ($$v) {_vm.openMenu=$$v},expression:"openMenu"}},[_c(VCard,{staticStyle:{"width":"36rem"}},[_c(VCardText,{staticClass:"d-flex flex-row flex-wrap"},[_vm._l((Object.keys(_vm.allHeaders)),function(value,index){return [_c(VCheckbox,{staticStyle:{"width":"11rem"},attrs:{"value":_vm.includeArray[index],"label":_vm.allHeaders[value].text},model:{value:(_vm.includeArray[index]),callback:function ($$v) {_vm.$set(_vm.includeArray, index, $$v)},expression:"includeArray[index]"}})]})],2),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"text":""},on:{"click":function($event){return _vm.selectAll()}}},[_vm._v("Select All")]),_c(VBtn,{attrs:{"text":""},on:{"click":function($event){return _vm.unselectAll()}}},[_vm._v("Unselect All")]),_c(VBtn,{staticClass:"white--text",attrs:{"color":"#0195D2"},on:{"click":function($event){_vm.openMenu = false}}},[_vm._v("Close")])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }