const snackBarHeight = 135;

function generateRandomId() {
  const randomPart = Math.random().toString(36).substring(2, 15);
  const timePart = new Date().getTime().toString(36);
  return timePart + randomPart;
}

const state = {
  snackBarItems: [],
};

const getters = {
  getterGetSnackBarItems: (state) => {
    return state.snackBarItems;
  },
};

const mutations = {
  mutationSetSnackBarItem: (state, payload) => {
    const iconTable = {
      pause: "mdi-pause-circle-outline",
      success: "mdi-check-circle-outline",
      warning: "mdi-alert",
      error: "mdi-close-circle-outline",
      notification: "mdi-alarm-check",
    };
    const colorTable = {
      pause: "#4CAF50",
      success: "#4CAF50",
      warning: "#FFB74D",
      error: "#F44336",
      notification: "#42A5F5",
    };

    payload.id = generateRandomId();
    payload.visibility = true;
    payload.icon = iconTable[payload.type];
    payload.color = colorTable[payload.type];
    payload.timeout = 5000;
    payload.height = snackBarHeight;
    payload.bottomPosition = state.snackBarItems.length * (snackBarHeight + 10) + 10; // make sure this is added before it is pushed into the array to maintain reactivity

    state.snackBarItems.push(payload);
  },
  mutationRemoveSnackBarItem: (state, payload) => {
    state.snackBarItems = state.snackBarItems.filter((item) => payload !== item.id);
  },
  mutationResetBottomPosition: (state, payload) => {
    let bottomPositionTotal = 10;
    state.snackBarItems.forEach((item) => {
      item.bottomPosition = bottomPositionTotal;
      bottomPositionTotal += snackBarHeight + 10;
    });
  },
};

const actions = {};

export default {
  state,
  getters,
  mutations,
  actions,
};
