<!-- This widget is currently only used by the FX20 -->
<!-- The widget is planned to be used with the RG3100 but with only one port -->

<template>
  <div class="d-flex flex-row" style="width: 100%">
    <EthernetIcon
      class="ma-1"
      width="20%"
      :active="deviceLANStatus.WANPortStatus === 'up'"
      innerLabel="0"
      outerLabel="WAN"
    />
    <EthernetIcon
      class="ma-1"
      width="20%"
      :active="deviceLANStatus.LAN1PortStatus === 1"
      innerLabel="1"
      outerLabel="LAN"
    />
    <EthernetIcon
      class="ma-1"
      width="20%"
      :active="deviceLANStatus.LAN2PortStatus === 1"
      innerLabel="2"
      outerLabel="LAN"
    />
    <EthernetIcon
      class="ma-1"
      width="20%"
      :active="deviceLANStatus.LAN3PortStatus === 1"
      innerLabel="3"
      outerLabel="LAN"
    />
    <EthernetIcon
      class="ma-1"
      width="20%"
      :active="deviceLANStatus.LAN4PortStatus === 1"
      innerLabel="4"
      outerLabel="LAN"
    />
  </div>
</template>

<script>
import EthernetIcon from "./EthernetIcon.vue";

export default {
  name: "Ethernet",
  components: {
    EthernetIcon,
  },
  props: {
    deviceLANStatus: {
      type: Object,
      default: {},
    },
  },
};
</script>
