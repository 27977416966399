const errorListTable = {
  RG2100:{
    // Main Wi-Fi
    "mainWifi.wifiName": "Main Wi-Fi > Wi-Fi SSID",
    "mainWifi.wifiPassword": "Main Wi-Fi > Wi-Fi Password",
    // Guest Wi-Fi
    "guestWifi.wifiName": "Guest Wi-Fi > Wi-Fi SSID",
    "guestWifi.wifiPassword": "Guest Wi-Fi > Wi-Fi Password",
    // Control
    "control.Password": "Control > Password",
    // Data Usage
    "dataUsage.MonthlyLimit_Data": "Data Usage > Usage",
    // DHCP Range
    "dhcpRange.DefaultGateway": "DHCP Range > Default Gateway",
    "dhcpRange.LeaseTime": "DHCP Range > Lease Time"
  },
  RG3100:{
    // Main Wi-Fi
    "mainWifi.wifiName": "Main Wi-Fi > Wi-Fi SSID",
    "mainWifi.wifiPassword": "Main Wi-Fi > Wi-Fi Password",
    // Guest Wi-Fi
    "guestWifi.wifiName": "Guest Wi-Fi > Wi-Fi SSID",
    "guestWifi.wifiPassword": "Guest Wi-Fi > Wi-Fi Password",
    // Control
    "control.Password": "Control > Password",
    // Data Usage
    "dataUsage.MonthlyLimit_Data": "Data Usage > Usage",
    // DHCP Range
    "dhcpRange.DefaultGateway": "DHCP Range > Default Gateway",
    "dhcpRange.LeaseTime": "DHCP Range > Lease Time"
  },
  FX20:{
    // WirelessLAN Wi-Fi
    "wirlessLAN.wifiName": "Wireless LAN > Wi-Fi SSID 2.4GHz",
    "wirlessLAN.wifiPassword": "Wireless LAN > Wi-Fi Password 2.4GHz",
    "wirlessLAN.guestWifiName": "Wireless LAN > Wi-Fi SSID 5.0GHz",
    "wirlessLAN.guestWifiPassword": "Wireless LAN > Wi-Fi Password 5.0GHz",
    // DMZ
    "DMZ.HostIPAddress": "DMZ > Default Gateway",
    // Dynamic DNS
    "DDNS.DomainName": "Dynamic DNS > Domain Name",
    "DDNS.UserID": "Dynamic DNS > User ID",
    "DDNS.UserPassword": "Dynamic DNS > User Password",
    // DHCP Range
    "dhcpRange.DefaultGateway": "DHCP Range > Default Gateway",
    "dhcpRange.LeaseTime": "DHCP Range > Lease Time",
    // MISC Settings(System)
    "System.HTTPPort": "MISC Settings > HTTP Port",
    // Remote Admin(Router Admin)
    "RouterAdmin.UserName": "Remote Admin > Username",
    "RouterAdmin.Password": "Remote Admin > Password",
  }

}

export const mixin_error_list_utils = {
  methods: {
    mixinGetConfigPath(model, path) {
      return errorListTable[model][path];
    }
  }
};