<template>
  <v-card v-if="deviceLocation" :style="cssVars" flat>
    <v-card-title>Latest Location</v-card-title>
    <v-card-text>
      <GmapMap class="device-map" :center=center :zoom="15" ref="map" :options="{
        zoomControl: true,
        mapTypeControl: false,
        scaleControl: false,
        streetViewControl: false,
        rotateControl: true,
        fullscreenControl: true,
      }">
        <GmapMarker :position="center" :clickable="false" :draggable="false"
          :title="deviceLocation.latitude + ', ' + deviceLocation.longitude" />
        
      </GmapMap>
      <p class="address-display">{{ address }}</p>
      <div ref="result" class="d-none"></div>
      <p class="last-updated-display" :hidden="!(deviceLocation.lastUpdated)">
        Last Updated: {{ deviceLocation.lastUpdated }}
      </p>
    </v-card-text>
  </v-card>
</template>

<script>

export default {
  name: "DeviceLocation",
  props: {
    // deviceLocation: {
    //   latitude: Number, 
    //   longitude: Number, 
    //   lastUpdated: Date,
    // },
    deviceLocation: {
      type: Object,
      required: true,
    },
    height: {
      type: String,
      default: "16rem",
    },
  },
  data: () => ({
    address: "",
  }),
  computed: {
    cssVars: function () {
      return {
        "--map-height": this.height,
      };
    },
    center: function () {
      return {
        lat: this.deviceLocation.latitude,
        lng: this.deviceLocation.longitude,
      };
    },
  },
  methods: {
    addCenterControl(controlDiv, map) {
      const controlUI = document.createElement('div');

      controlUI.innerHTML = `
        <button class="center-button" >
          <svg width="100%" height="100%" viewBox="0 0 22 22" fill="#666666" xmlns="http://www.w3.org/2000/svg">
            <path d="M10.2508 21.2008V19.47C8.16745 19.2559 6.41361 18.4366 4.98928 17.0123C3.56495 15.5879 2.7457 13.8341 2.53153 11.7508H0.800781V10.2508H2.53153C2.7457 8.16745 3.56495 6.41361 4.98928 4.98928C6.41361 3.56495 8.16745 2.7457 10.2508 2.53153V0.800781H11.7508V2.53153C13.8341 2.7457 15.5879 3.56495 17.0123 4.98928C18.4366 6.41361 19.2559 8.16745 19.47 10.2508H21.2008V11.7508H19.47C19.2559 13.8341 18.4366 15.5879 17.0123 17.0123C15.5879 18.4366 13.8341 19.2559 11.7508 19.47V21.2008H10.2508ZM11.0008 18.0008C12.9341 18.0008 14.5841 17.3174 15.9508 15.9508C17.3174 14.5841 18.0008 12.9341 18.0008 11.0008C18.0008 9.06745 17.3174 7.41745 15.9508 6.05078C14.5841 4.68411 12.9341 4.00078 11.0008 4.00078C9.06745 4.00078 7.41745 4.68411 6.05078 6.05078C4.68411 7.41745 4.00078 9.06745 4.00078 11.0008C4.00078 12.9341 4.68411 14.5841 6.05078 15.9508C7.41745 17.3174 9.06745 18.0008 11.0008 18.0008ZM11.0008 14.5008C10.0383 14.5008 9.21437 14.158 8.52903 13.4725C7.84353 12.7872 7.50078 11.9633 7.50078 11.0008C7.50078 10.0383 7.84353 9.21437 8.52903 8.52903C9.21437 7.84353 10.0383 7.50078 11.0008 7.50078C11.9633 7.50078 12.7872 7.84353 13.4725 8.52903C14.158 9.21437 14.5008 10.0383 14.5008 11.0008C14.5008 11.9633 14.158 12.7872 13.4725 13.4725C12.7872 14.158 11.9633 14.5008 11.0008 14.5008Z"/>
          </svg>
        </button>
      `;

      controlDiv.appendChild(controlUI);
      controlUI.addEventListener('click', () => {
        map.setZoom(15);
        map.panTo(this.center);
      });
    },
  },
  mounted: function () {
    this.address = "Device Coordinates: " + this.deviceLocation.latitude + ", " + this.deviceLocation.longitude;

    this.$refs.map.$mapPromise.then((map) => { 
      const centerControlDiv = document.createElement('div');
      this.addCenterControl(centerControlDiv, map);
      map.controls[google.maps.ControlPosition.RIGHT_BOTTOM].push(
        centerControlDiv
      );
    });

    this.$gmapApiPromiseLazy().then(() => {
      const service = new google.maps.Geocoder();
      const request = {
        location: this.center,
      };
      service.geocode(request, (results, status) => {
        if (status === google.maps.GeocoderStatus.OK) {
          this.address = results[0].formatted_address;
        }
      });
    });
  }
};

</script>

<style scoped>
.device-map {
  height: var(--map-height);
  width: 100%;
  background: #F7F7F7;
}

.address-display {
  margin-top: 1rem;
  margin-bottom: 0;
  font-size: 1.25rem;
}

.last-updated-display {
  margin-top: 0.5rem;
  margin-bottom: 0;
  font-size: 0.875rem;
}

.device-map >>> .center-button {
  background-color: white; 
  width: 35px; 
  height: 35px; 
  margin-right: 12.5px; 
  padding: 5px; 
  border-radius: 25%; 
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.2);
}

.device-map >>> .center-button svg:hover {
  fill: #111111;
}
</style>
