<template>
  
  <v-tabs v-model="navTabs" dense right dark>
    <v-tabs-slider class="button"></v-tabs-slider>

    <!-- Devices Menu -->
    <v-menu open-on-hover bottom offset-y>
      <template v-slot:activator="{ on, attrs }">
        <v-tab v-bind="attrs" v-on="on" class="text-capitalize" @click="pushRoute({ name: 'Individualist' })">Manage</v-tab>
      </template>
      <v-list style="background-color: #d9d9d9" dense>
        <v-list-item v-for="menu in manageMenu" :key="menu.title" @click="pushRoute(menu.name)">
          <v-list-item-title>{{ menu.title }}</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>

  </v-tabs>
</template>
<script>
import { mixin_routing_utils } from "@/mixins/routingUtils.js";
export default {
  mixins: [ mixin_routing_utils ],
  data() {
    return {
      navTabs: 0,
      manageMenu: [
       { title: "Devices", name: { name: "Individualist" } },
       { title: "Group", name: { name: "DeviceGroupsList" } },
      ]
    };
  },
  // watch, created and positionMenuSlider is not necessary in this page since there is only one main menu tab
  // However, this is just left as is in case of future updates
  watch: {
    '$route'(to, from) {
      this.navTabs = this.positionMenuSlider(to.name);
    }
  },
  created () {
    this.navTabs = this.positionMenuSlider(this.$route.name);
  },
  methods: {
    pushRoute(menuRoute) {
      this.mixinChangeRoute(menuRoute);
    },
    positionMenuSlider(name){
      if(this.manageMenu.some(item => name === item.name.name)) return 0;
    }
  },
};
</script>