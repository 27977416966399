<template>
  <v-app-bar flat style="background-color: black" fixed height="70">
    <v-toolbar-title @click="close_device_details_dialog()" class="white--text" style="display: flex; align-items: center; cursor: pointer">
      <v-icon class="white--text" x-large>mdi-chevron-left</v-icon>
      <div><span style="font-weight: bold" class="d-none d-sm-inline">Device Detail - </span>{{ deviceName }}</div>
      <em style="margin-left: 0.5rem" class="d-none d-sm-inline">({{ idString }})</em>
    </v-toolbar-title>
    <v-spacer />
    <v-btn @click="refreshDeviceDetails" icon large color="white">
      <v-icon>mdi-refresh</v-icon>
    </v-btn>
    <v-btn v-if="validDevice" @click="open_device_configs_dialog()" :disabled="isUnlicensed" rounded class="ml-4 mr-4 white--text text-none" color="#0195D2">
      <v-icon class="mr-md-1">mdi-cog</v-icon>
      <span class="d-none d-md-inline">Configuration</span>
    </v-btn>
  </v-app-bar>
</template>

<script>

export default {
  props: {
    deviceName: String, 
    idString: String, 
    validDevice: Boolean,
    isUnlicensed: Boolean,
    refreshDeviceDetails: Function,
  },
  methods: {
    close_device_details_dialog() {
      this.$emit("close");
    }, 
    open_device_configs_dialog() {
      this.$emit("openConfigs")
    },
  },
}

    
</script>