import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';
import { VTabsSlider } from 'vuetify/lib/components/VTabs';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VTabs,{attrs:{"dense":"","right":"","dark":""},model:{value:(_vm.navTabs),callback:function ($$v) {_vm.navTabs=$$v},expression:"navTabs"}},[_c(VTabsSlider,{staticClass:"button"}),_c(VMenu,{attrs:{"open-on-hover":"","bottom":"","offset-y":"","nudge-top":"2"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c(VTab,_vm._g(_vm._b({staticClass:"text-capitalize",on:{"click":function($event){return _vm.pushRoute({ name: 'CustomerDeviceList' })}}},'v-tab',attrs,false),on),[_vm._v("Manage")])]}}])},[_c(VList,{staticStyle:{"background-color":"#d9d9d9"},attrs:{"dense":""}},_vm._l((_vm.manageMenu),function(menu){return _c(VListItem,{key:menu.title,on:{"click":function($event){return _vm.pushRoute(menu.name)}}},[_c(VListItemTitle,[_vm._v(_vm._s(menu.title))])],1)}),1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }