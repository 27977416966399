import Crypto from './crypto-string';

function isBrowser() {
  if (typeof process === 'object' && process.versions && process.versions.node) {
    return false;
  }
  return true;
}

function getBrowserInfo() {
  let browserName = "";
  let fullVersion = '' + Math.random() * 10;
  let majorVersion = Math.random() * 10;
  let ua = "";
  if (isBrowser()) {
    let nameOffset, verOffset, ix;
    ua = navigator.userAgent;
    browserName = navigator.appName;
    fullVersion = '' + parseFloat(navigator.appVersion);
    majorVersion = parseInt(navigator.appVersion, 10);
  
    // In Opera, the true version is after "Opera" or after "Version"
    if ((verOffset = ua.indexOf("Opera")) != -1) {
      browserName = "Opera";
      fullVersion = ua.substring(verOffset + 6);
      if ((verOffset = ua.indexOf("Version")) != -1)
        fullVersion = ua.substring(verOffset + 8);
    }
    // In MSIE, the true version is after "MSIE" in userAgent
    else if ((verOffset = ua.indexOf("MSIE")) != -1) {
      browserName = "Microsoft Internet Explorer";
      fullVersion = ua.substring(verOffset + 5);
    }
    // In Chrome, the true version is after "Chrome"
    else if ((verOffset = ua.indexOf("Chrome")) != -1) {
      browserName = "Chrome";
      fullVersion = ua.substring(verOffset + 7);
    }
    // In Safari, the true version is after "Safari" or after "Version"
    else if ((verOffset = ua.indexOf("Safari")) != -1) {
      browserName = "Safari";
      fullVersion = ua.substring(verOffset + 7);
      if ((verOffset = ua.indexOf("Version")) != -1)
        fullVersion = ua.substring(verOffset + 8);
    }
    // In Firefox, the true version is after "Firefox"
    else if ((verOffset = ua.indexOf("Firefox")) != -1) {
      browserName = "Firefox";
      fullVersion = ua.substring(verOffset + 8);
    }
    // In most other browsers, "name/version" is at the end of userAgent
    else if ((nameOffset = ua.lastIndexOf(' ') + 1) <
      (verOffset = ua.lastIndexOf('/'))) {
      browserName = ua.substring(nameOffset, verOffset);
      fullVersion = ua.substring(verOffset + 1);
      if (browserName.toLowerCase() == browserName.toUpperCase()) {
        browserName = navigator.appName;
      }
    }
    // Trim the fullVersion string at semicolon/space if present
    if ((ix = fullVersion.indexOf(";")) != -1)
      fullVersion = fullVersion.substring(0, ix);
    if ((ix = fullVersion.indexOf(" ")) != -1)
      fullVersion = fullVersion.substring(0, ix);
  
    majorVersion = parseInt('' + fullVersion, 10);
    if (isNaN(majorVersion)) {
      fullVersion = '' + parseFloat(navigator.appVersion);
      majorVersion = parseInt(navigator.appVersion, 10);
    }
  }
  
  return {
    browserName: browserName,
    fullVersion: fullVersion,
    majorVersion: majorVersion,
    userAgent: ua
  };
}

const browserInfo = getBrowserInfo();
const EXPIRATION = 9997;
export const encode = function(data) {
  try {
    let timestamp = Date.now();
    let param = {
      n: Number(Math.random().toString().split('.')[1]),
      d: data,
      t: timestamp,
    };
    let crypto = new Crypto(browserInfo.browserName + browserInfo.fullVersion);
    let encrypted = crypto.encrypt(JSON.stringify(param));
    return encodeURIComponent(encrypted);
  } catch (e) {
    console.log("error:", e.message);
  }
}

export const decode = function(encoded) {
  try {
    let crypto = new Crypto(browserInfo.browserName + browserInfo.fullVersion);
    let decoded = decodeURIComponent(encoded);
    let decrypted;
    const points = Array.from({ length: 2 }, (_, i) => i);
    const decrypt = function(n) {
      crypto.rewind(n);
      try {
        decrypted = crypto.decrypt(decoded);
        if (decrypted) {
          return true;
        }
      } catch (e) {
        console.log("=> ", e.message);
      }
    };
    if (points.some(decrypt)) {
      let timestamp = Date.now();
      let param = JSON.parse(decrypted);
      if (param.t <= timestamp && timestamp <= param.t + EXPIRATION)
        return param.d;
    }
  } catch (e) {
    console.log("error:", e.message);
  }
}
