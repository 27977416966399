import { render, staticRenderFns } from "./DeleteUserPermenent.vue?vue&type=template&id=95fe0234&"
import script from "./DeleteUserPermenent.vue?vue&type=script&lang=js&"
export * from "./DeleteUserPermenent.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports