import { API, graphqlOperation } from "aws-amplify";
import { get_current_user_details, list_Users } from "@/graphql/queries/userQueries";
import { user_logout_details_reports, delete_user_permanently, update_cognito_user } from "@/graphql/mutations/userMutations";

// Queries
export const getCurrentUserDetails = async (userEmail) => {
  try {
    const result = await API.graphql(
      graphqlOperation(get_current_user_details, {
        input: {
          user_email_id: userEmail,
        },
      })
    );
    const response = JSON.parse(result.data.get_current_user_deatils);
    return response;
  } catch (error) {
    console.error("Error fetching user details", error);
    throw error;
  }
};

export const getUserList = async (userRole, customerId, limit, nextToken) => {
  try {
    const result = await API.graphql(
      graphqlOperation(list_Users, {
        input: {
          user_role: userRole,
          customer_id: customerId,
          limit: limit,
          next_token: nextToken,
        },
      })
    );
    const response = JSON.parse(result.data.list_Users);
    return response;
  } catch (error) {
    console.error("Error fetching user list", error);
    throw error;
  }
};

// Mutations
export const userLogout = async (userEmail) => {
  try {
    const result = await API.graphql(
      graphqlOperation(user_logout_details_reports, {
        input: {
          user_email_id: userEmail,
        },
      })
    );
    return result;
  } catch (error) {
    console.error("Error logging out", error);
    throw error;
  }
};

export const deleteUserPermanently = async (userId) => {
  try {
    let result = await API.graphql(
      graphqlOperation(delete_user_permanently, {
        input: {
          user_id: userId,
        },
      })
    );
  } catch (error) {
    console.error("Error deleting the user", error);
    throw error;
  }
};

// Accepts { email, password, userId } as input arguments.
// userId is mandatory, both email and password is optional
// providing either one will incur changes in the backend data
// For example, providing email alone will change the email address only
// The parameter should be passed as collection of objects
export const updateCognitoUser = async (userInfo) => {
  try {
    let result = await API.graphql(
      graphqlOperation(update_cognito_user, {
        input: userInfo
      })
    );
    const response = JSON.parse(result.data.update_cognito_user);
    return response;
  } catch (error) {
    console.error("Error changing the user", error);
    throw error;
  }
};
