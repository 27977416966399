<template>
  <v-card flat>
    <v-card-title class="d-flex flex-row justify-space-between"> 
      WAN Details
      <div class="d-flex flex-row">
        <strong class="mr-4" style="font-size: 0.75rem;">Default Gateway</strong>
        <p style="font-size: 0.75rem" class="ma-0"> {{ defaultLAN }} </p>
      </div> 
    </v-card-title>
    <v-card-text>
      <v-simple-table class="dtwidth">
        <template v-slot:default>
          <thead>
            <tr class="wan-header">
              <th>Type</th>
              <th>IP Address</th>
              <th>Primary DNS</th>
              <th>Secondary DNS</th>
              <th>Gateway</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>IPv4</td>
              <td>{{ getWANIPv4Status.IPAddress || '-' }}</td>
              <td>{{ getWANIPv4Status.DNSServer1 || '-' }}</td>
              <td>{{ getWANIPv4Status.DNSServer2 || '-' }}</td>
              <td>{{ getWANIPv4Status.Gateway || '-' }}</td>
            </tr>
            <tr>
              <td>IPv6</td>
              <td>{{ getWANIPv6Status.IPAddress || '-' }}</td>
              <td>{{ getWANIPv6Status.DNSServer1 || '-' }}</td>
              <td>{{ getWANIPv6Status.DNSServer2 || '-' }}</td>
              <td>{{ getWANIPv6Status.Gateway || '-' }}</td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-card-text>
  </v-card>
</template>

<script>
import { router_model_utils } from "@/mixins/routerModelUtils.js";
import { mapGetters } from "vuex";

const defaultIPStatus = {
  IPAddress: "-",
  DNSServer1: "-",
  DNSServer2: "-",
  Gateway: "-",
};

export default {
  mixins: [
    router_model_utils
  ],
  computed: {
    ...mapGetters([ 'getterGetDeviceWANStatus', 'getterGetDeviceConfigurations' ]),
    getDeviceModel() {
      return this.getterGetDeviceConfigurations.router_details.router_model;
    },
    getWANIPv4Status() {
      return this.getterGetDeviceWANStatus ? this.getterGetDeviceWANStatus.WANIPv4Status[0] : defaultIPStatus;
    },
    getWANIPv6Status() {
      return this.getterGetDeviceWANStatus ? this.getterGetDeviceWANStatus.WANIPv6Status[0] : defaultIPStatus;
    }, 
    defaultLAN() {
      if(this.getDeviceModel === "FX20") {
        return this.getterGetDeviceConfigurations.configuration_module.ConnectedStatus ? this.getterGetDeviceConfigurations.configuration_module.ConnectedStatus.Value.LANStatus[0].IPv4Address : this.getterGetDeviceConfigurations.configuration_module.WiredLAN.Value.DHCPServer.DefaultGateway;
      }
      return this.getterGetDeviceConfigurations.configuration_module.WiredLAN ? this.getterGetDeviceConfigurations.configuration_module.WiredLAN.Value.DHCPServer.DefaultGateway : '';
    }
  },
};
</script>

<style>
.wan-header {
  background-color: #F1F1F1;
}

.wan-header th {
  color: black !important;
}
</style>
