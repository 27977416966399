import TimeZones from "@/mixins/TimeZones.json";
import { mixin_time_utils } from "@/mixins/timeUtils.js"

export const mixin_list_table_utils = {
  components: {
    TimeZones
  },
  mixins: [ mixin_time_utils ],
  data(){
    return {
      
    }
  },
  methods: {
    // Below contains return methods for dropdown list items in configuration forms
    // Common
    mixinGetEnableList(){
      return [
        { text: "Disabled", value: 0 },
        { text: "Enabled", value: 1 },
      ]
    },
    // Wi-Fi related
    mixinGetWifiBandsList(){
      return [
        { text: "2.4GHz", value: 0 },
        { text: "5.0GHz", value: 1 },
      ]
    },
    mixinGetWifiAuthList() {
      return [
        { value: 0, text: "Open" },
        { value: 1, text: "WPA2" },
        { value: 2, text: "WPA3" },
        { value: 3, text: "WPA/WPA2 Mixed" },
        { value: 4, text: "WPA2/WPA3 Mixed" }
      ]
    },
    mixinGetWifiEncryptionList() {
      return [
        { value: 1, text: "AES-CCMP" },
        { value: 2, text: "AES-TKIP" },
        { value: 3, text: "AES-CCMP/TKIP" }
      ]
    },
    mixinGetInactiveTimeList() {
      return [
        { text: '10 minutes', value: 0 },
        { text: '20 minutes', value: 1 },
        { text: '30 minutes', value: 2 },
        { text: 'Always-On', value: 3 }
      ]
    },

    // APN related
    mixinGetPDPList() {
      return [
        { value: 1, text: "IPv4" },
        { value: 2, text: "IPv6" },
        { value: 3, text: "IPv4v6" },
        { value: 4, text: "PPP" }
      ]
    },
    mixinGetAuthList() {
      return [
        { value: 0, text: "None" },
        { value: 1, text: "PAP" },
        { value: 2, text: "CHAP" },
        { value: 3, text: "PAPCHAP" }
      ]
    },
    mixinGetIPList() {
      return [
        { value: 0, text: "NAS" },
        { value: 1, text: "DHCP" },
      ]
    },
    // CellularGeneral related
    mixinGetConnectionModeList(){
      return [
        { value: 0, text: "Automatic" },
        { value: 1, text: "5G Only" },
        { value: 2, text: "5G/LTE" },
        { value: 3, text: "LTE/3G" },
        { value: 4, text: "LTE Only" },
        { value: 5, text: "3G" },
      ]
    },
    mixinGetConnectionTypeList(){
      return [
        { value: 0, text: "Wi-Fi + USB" },
        { value: 1, text: "Wi-Fi Only" },
        { value: 2, text: "USB Only" },
      ]
    },
    // MISC(display) related
    mixinGetShowUsageList(){
      return [
        { value: 0, text: "Web UI Only" },
        { value: 1, text: "Both web UI and device display" },
      ]
    },
    mixinGetDisplayTimeoutList(){
      return [
        { value: 0, text: "30 seconds" },
        { value: 1, text: "A minute" },
        { value: 2, text: "5 minutes" },
        { value: 3, text: "Never turn off" },
      ]
    },
    mixinGetDisplayWifiInfoList(){
      return [
        { value: 0, text: "Disabled" },
        { value: 1, text: "Primary Wi-Fi Only" },
        { value: 2, text: "Guest Wi-Fi Only" },
        { value: 3, text: "Primary and Guest Wi-Fi" },
      ]
    },
    mixinGetControlBatteryLevelList(){
      return [
        { value: 0, text: "5%" },
        { value: 1, text: "25%" },
        { value: 2, text: "50%" },
      ]
    },
    // Usage related
    mixinGetMonthDayList(){
      return [
        { value: 1, text: "1st" },
        { value: 2, text: "2nd" },
        { value: 3, text: "3rd" },
        { value: 4, text: "4th" },
        { value: 5, text: "5th" },
        { value: 6, text: "6th" },
        { value: 7, text: "7th" },
        { value: 8, text: "8th" },
        { value: 9, text: "9th" },
        { value: 10, text: "10th" },
        { value: 11, text: "11th" },
        { value: 12, text: "12th" },
        { value: 13, text: "13th" },
        { value: 14, text: "14th" },
        { value: 15, text: "15th" },
        { value: 16, text: "16th" },
        { value: 17, text: "17th" },
        { value: 18, text: "18th" },
        { value: 19, text: "19th" },
        { value: 20, text: "20th" },
        { value: 21, text: "21th" },
        { value: 22, text: "22th" },
        { value: 23, text: "23th" },
        { value: 24, text: "24th" },
        { value: 25, text: "25th" },
        { value: 26, text: "26th" },
        { value: 27, text: "27th" },
        { value: 28, text: "28th" },
        { value: 29, text: "29th" },
        { value: 30, text: "30th" },
        { value: 31, text: "31th" },
      ]
    },
    mixinGetYearDayList(val){
      let yearDayList = [
        { value: "01", text: "1st" },
        { value: "02", text: "2nd" },
        { value: "03", text: "3rd" },
        { value: "04", text: "4th" },
        { value: "05", text: "5th" },
        { value: "06", text: "6th" },
        { value: "07", text: "7th" },
        { value: "08", text: "8th" },
        { value: "09", text: "9th" },
        { value: "10", text: "10th" },
        { value: "11", text: "11th" },
        { value: "12", text: "12th" },
        { value: "13", text: "13th" },
        { value: "14", text: "14th" },
        { value: "15", text: "15th" },
        { value: "16", text: "16th" },
        { value: "17", text: "17th" },
        { value: "18", text: "18th" },
        { value: "19", text: "19th" },
        { value: "20", text: "20th" },
        { value: "21", text: "21th" },
        { value: "22", text: "22th" },
        { value: "23", text: "23th" },
        { value: "24", text: "24th" },
        { value: "25", text: "25th" },
        { value: "26", text: "26th" },
        { value: "27", text: "27th" },
        { value: "28", text: "28th" },
        { value: "29", text: "29th" },
        { value: "30", text: "30th" },
        { value: "31", text: "31th" },
      ]
      if(val === "02") yearDayList = yearDayList.splice(0, 29);
      if(Number(val) <= 7 && Number(val)%2 === 0 && val !== "02") yearDayList = yearDayList.splice(0, 30);
      if(Number(val) > 7 && Number(val)%2 === 1) yearDayList = yearDayList.splice(0, 30);
      return yearDayList;
    },
    mixinGetYearMonthList(){
      return [
        { value: "01", text: "January" },
        { value: "02", text: "February" },
        { value: "03", text: "March" },
        { value: "04", text: "April" },
        { value: "05", text: "May" },
        { value: "06", text: "June" },
        { value: "07", text: "July" },
        { value: "08", text: "August" },
        { value: "09", text: "September" },
        { value: "10", text: "October" },
        { value: "11", text: "November" },
        { value: "12", text: "December" }
      ]
    },
    // PortForwarding related
    mixinGetInternetProtocolList(){
      return [
        { value: 0, text: "TCP" },
        { value: 1, text: "UDP" },
        { value: 2, text: "TCP & UDP" },
      ]
    },
    // DDNS Related
    mixinGetDDNSServiceProviderList(){
      return [
        { value: 0, text: "DynDNS (dydns.org)*" },
        { value: 1, text: "Google (google.com)" },
        { value: 2, text: "no-ip (no-ip.com)" },
      ]
    },
    // NTP Time Server
    mixinGetTimeZoneList(){
      return TimeZones.timezones;
    },
    // MISC Settings(System) 
    mixinGetDegredationPercentageList(){
      return [
        { text: "10%", value: 10 },
        { text: "20%", value: 20 },
        { text: "30%", value: 30 },
        { text: "40%", value: 40 },
        { text: "50%", value: 50 },
        { text: "60%", value: 60 },
        { text: "70%", value: 70 },
        { text: "80%", value: 80 },
        { text: "90%", value: 90 },
        { text: "100%", value: 100 },
      ]
    },
    // Any list that uses filter set(Service Filtering)
    mixinGetFilterSetList() {
      return [
        { text: "Disable", value: 0 },
        { text: "White List", value: 1 },
        { text: "Black List", value: 2 },
      ]
    },
    mixinGetHoursList(){
      return [
        { value: 0, text: "Off" },
        { value: 1, text: "1hr" },
        { value: 2, text: "2hrs" },
        { value: 3, text: "3hrs" },
        { value: 4, text: "4hrs" },
        { value: 5, text: "5hrs" },
        { value: 6, text: "6hrs" },
        { value: 7, text: "7hrs" },
        { value: 8, text: "8hrs" },
        { value: 9, text: "9hrs" },
        { value: 10, text: "10hrs" },
        { value: 11, text: "11hrs" },
        { value: 12, text: "12hrs" },
        { value: 13, text: "13hrs" },
        { value: 14, text: "14hrs" },
        { value: 15, text: "15hrs" },
        { value: 16, text: "16hrs" },
        { value: 17, text: "17hrs" },
        { value: 18, text: "18hrs" },
        { value: 19, text: "19hrs" },
        { value: 20, text: "20hrs" },
        { value: 21, text: "21hrs" },
        { value: 22, text: "22hrs" },
        { value: 23, text: "23hrs" },
        { value: 24, text: "24hrs" },
      ]
    },

    // functions below are data translation functions used in configuration tables and config history panel
    // common
    mixinOnOffReturn(val) {
      const returnObj = ["Off", "On"];
      return returnObj[val];
    },
    mixinSecondsReturn(val) {
      return `${val} seconds`;
    },
    // This should commonly be used with all lists that do not require special formatting
    mixinCommonListReturn(list, val){
      let newList = list.map(item => item.text);
      return newList[val];
    },
    mixinEnableReturn(val){
      const returnObj = [ "Disabled", "Enabled" ];
      return returnObj[val];
    },
    // Wi-Fi related
    mixinWifiBandReturn(val) {
      const returnObj = ["2.4GHz", "5.0GHz", "6.0GHz"];
      return returnObj[val];
    },
    mixinWifiAuthenticationReturn(val) {
      const returnObj = ["Open", "WPA2", "WPA3", "WPA/WPA2 Mixed", "WP2/WPA3 Mixed"];
      return returnObj[val];
    },
    mixinWifiEncryptionReturn(val) {
      const returnObj = ["None", "AES-CCMP", "AES-TKIP", "AES-CCMP/TKIP"];
      return returnObj[val];
    },
    // APN related
    mixinPDPTypeReturn(val) {
      const returnObj = { "1": "IPv4", "2": "IPv6", "3": "IPv4v6", "4": "PPP" };
      return returnObj[val];
    },
    mixinAPNAuthTypeReturn(val) {
      const returnObj = { "0": "None", "1": "PAP", "2": "CHAP", "3": "PAPCHAP" };
      return returnObj[val];
    },
    mixinAPNIPAllocationReturn(val) {
      const returnObj = [ "NAS", "DHCP" ];
      return returnObj[val];
    },
    // CellularGeneral related
    mixinConnectionModeReturn(val) {
      const returnObj = [ "Automatic", "5G Only", "5G/LTE", "LTE/3G", "LTE Only", "3G" ];
      return returnObj[val];
    },
    mixinConnectionTypeReturn(val) {
      const returnObj = [ "Wi-Fi + USB", "Wi-Fi Only", "USB Only" ];
      return returnObj[val];
    },
    // MISC(display) related
    mixinShowUsageReturn(val) {
      const returnObj = [ "Web UI Only", "Both web UI and device display" ];
      return returnObj[val];
    },
    mixinDisplayTimeoutReturn(val){
      const returnObj = [ "30 seconds", "A minute", "5 minutes", "Never turn off" ]
      return returnObj[val];
    },
    mixinDisplayWifiInfoReturn(val){
      const returnObj = [ "Disabled", "Primary Wi-Fi Only", "Guest Wi-Fi Only", "Primary and Guest Wi-Fi" ]
      return returnObj[val];
    },
    mixinGetControlBatteryLevelReturn(val){
      const returnObj = [ "5%", "25%", "50%" ]
      return returnObj[val];
    },
    // Usage related
    mixinUsageCycleReturn(val){
      const returnObj = ["Monthly", "Yearly"];
      return returnObj[val];
    },
    mixinMonthDayReturn(val){
      const returnObj = [ "", "1st", "2nd", "3rd", "4th", "5th", "6th", "7th", "8th", "9th", "10th", "11th", "12th", "13th", "14th", "15th", 
      "16th", "17th", "18th", "19th", "20th", "21th", "22th", "23th", "24th", "25th", "26th", "27th", "28th", "29th", "30th", "31th" ]
      return returnObj[val];
    },
    mixinYearDayReturn(val){
      let date = val;
      if(typeof date === "number") date = this.mixinConvertIntDateToString(date);
      const month = date.substring(0, 2);
      const day = date.substring(2, 4);
      const returnObj = `${this.mixinGetYearMonthList().find(item => item.value === month).text} ${this.mixinGetYearDayList().find(item => item.value === day).text}`;
      return returnObj;
    },
    mixinUsageLimitReturn(val) {
      return `${val / 1024} GB`
    },
    mixinInternetProtocolReturn(val){
      const returnObj = [ "TCP", "UDP", "TCP & UDP" ];
      return returnObj[val];
    },
    mixinMACAddressReturn(val){
      return val.match(/.{1,2}/g).join(':');
    },
    // Note that this was the original spec that was never followed
    // Use the integer version below
    mixinTimeReturn(val){
      return `${val.substring(0,2)}:${val.substring(2,4)}`;
    },
    mixinIntegerTimeReturn(val){
      let tempVal = val;
      if(tempVal >= 100 && tempVal < 1000) {
        tempVal = String(tempVal).slice(0,1);
      } else if (tempVal >= 1000) {
        tempVal = String(tempVal).slice(0,2);
      }
      return `0${tempVal}:00`.slice(-5);
    },
    mixinDDNSServiceProviderReturn(val) {
      const returnObj = [ "DynDNS (dydns.org)*", "Google (google.com)", "no-ip (no-ip.com)" ]
      return returnObj[val];
    },
    mixinPercentageReturn(val) {
      return `${val}%`
    },
    mixinModelList() {
      return [
        { text: "RG2100", value: { model: "RG2100", TAC: "35856379" } },
        // { text: "RG2102", value: { model: "RG2102", TAC: "35871837" } },
        { text: "RG3100", value: { model: "RG3100", TAC: "35212493" } },
        { text: "RG3102", value: { model: "RG3102", TAC: "35490740" } },
      ]
    }
  }
}