<template>
  <v-form ref="form" class="pa-5">
    <v-select ref="connectionMode" class="form-text-box" outlined dense label="Connection Mode" :items="mixinGetConnectionModeList()" v-model="connectionMode" @input="mixinUpdateFormField('connections.cellularGeneral.connectionMode', `${configPath}.ConnectionMode`, connectionMode)">
      <template v-if="mixinCheckGroupConfigChange('CellularGeneral', 'ConnectionMode')" slot="prepend">
        <UnchangedGroupConfigIcon />
      </template>
    </v-select>

    <v-select ref="connectionType" class="form-text-box" outlined dense label="Connection Type" :items="mixinGetConnectionTypeList()" v-model="connectionType" @input="mixinUpdateFormField('connections.cellularGeneral.connectionType', `${configPath}.ConnectionType`, connectionType)">
      <template v-if="mixinCheckGroupConfigChange('CellularGeneral', 'ConnectionType')" slot="prepend">
        <UnchangedGroupConfigIcon />
      </template>
    </v-select>

    <div class="d-flex align-center mt-2">
      <div style="padding-right: 9px" v-if="mixinCheckGroupConfigChange('CellularGeneral', 'DataRoaming')">
        <UnchangedGroupConfigIcon />
      </div>
      <v-checkbox ref="dataRoaming" class="form-text-box" dense v-model="dataRoaming" hide-details @click="mixinUpdateFormField('connections.cellularGeneral.dataRoaming', `${configPath}.DataRoaming`, dataRoaming ? 1 : 0)">
        <template v-slot:label>
          <div>Data Roaming</div>
        </template>
      </v-checkbox>
    </div>

    <div class="d-flex align-center mt-4">
      <div style="padding-right: 9px" v-if="mixinCheckGroupConfigChange('CellularGeneral', 'AutomaticDNSEnable')">
        <UnchangedGroupConfigIcon />
      </div>
      <v-checkbox ref="automaticDNSEnable" class="form-text-box" dense v-model="automaticDNSEnable" hide-details @click="mixinUpdateFormField('connections.cellularGeneral.automaticDNSEnable', `${configPath}.AutomaticDNSEnable`, automaticDNSEnable ? 1 : 0)">
        <template v-slot:label>
          <div>Automatic DNS Enable</div>
        </template>
      </v-checkbox>
    </div>


    <div v-show="!automaticDNSEnable" class="form-text-box form-two-column-grid mt-4">
      <v-text-field ref="PrimaryDNSAddress" class="form-text-box" outlined dense label="Primary DNS Address" v-model="PrimaryDNSAddress" :rules="!automaticDNSEnable ? mixinMandatoryIPv4Rule() : []" autocomplete="new-password" @blur="mixinUpdateFormField('connections.cellularGeneral.PrimaryDNSAddress', `${configPath}.PrimaryDNSAddress`, PrimaryDNSAddress)">
        <template v-if="mixinCheckGroupConfigChange('CellularGeneral', 'PrimaryDNSAddress')" slot="prepend">
          <UnchangedGroupConfigIcon />
        </template>
      </v-text-field>

      <v-text-field ref="SecondaryDNSAddress" class="form-text-box" outlined dense label="Secondary DNS Address" v-model="SecondaryDNSAddress" :rules="mixinOptionalIPv4Rule()" autocomplete="new-password" @blur="mixinUpdateFormField('connections.cellularGeneral.SecondaryDNSAddress', `${configPath}.SecondaryDNSAddress`, SecondaryDNSAddress)">
        <template v-if="mixinCheckGroupConfigChange('CellularGeneral', 'SecondaryDNSAddress')" slot="prepend">
          <UnchangedGroupConfigIcon />
        </template>
      </v-text-field>
    </div>
  </v-form>
</template>

<script>
import { mapGetters } from "vuex";
import { mixin_form_validation_utils } from "@/mixins/formValidationUtils.js";
import { mixin_list_table_utils } from "@/mixins/listTableUtils.js";
import UnchangedGroupConfigIcon from "@/components/DeviceConfigurations/DeviceConfigurationComponents/SharedComponents/UnchangedGroupConfigIcon.vue";

export default {
  mixins: [mixin_form_validation_utils, mixin_list_table_utils],
  components: {
    UnchangedGroupConfigIcon,
  },
  data() {
    return {
      connectionMode: -1,
      connectionType: -1,
      dataRoaming: false,
      automaticDNSEnable: false,
      PrimaryDNSAddress: "",
      SecondaryDNSAddress: "",
      configPath: "configuration_module.CellularGeneral.Value",
    };
  },
  created() {
    this.initCellularGeneral();
  },
  watch: {
    automaticDNSEnable: {
      handler() {
        this.PrimaryDNSAddress = "";
        this.SecondaryDNSAddress = "";
        this.$nextTick(() => {
          if (this.automaticDNSEnable) {
            this.mixinUpdateFormField("connections.cellularGeneral.PrimaryDNSAddress", `${this.configPath}.PrimaryDNSAddress`, this.PrimaryDNSAddress, true);
            this.mixinUpdateFormField("connections.cellularGeneral.SecondaryDNSAddress", `${this.configPath}.SecondaryDNSAddress`, this.SecondaryDNSAddress, true);
          } else {
            // only primary dns address is needed, as it is a mandatory field if the automatic DNS is disabled
            this.mixinUpdateFormField("connections.cellularGeneral.PrimaryDNSAddress", `${this.configPath}.PrimaryDNSAddress`, this.PrimaryDNSAddress);
          }
        });
      },
      immediate: true,
      deep: true
    },
  },
  computed: {
    ...mapGetters(["getterGetNewDeviceConfigurations"]),
  },
  methods: {
    initCellularGeneral() {
      const defaultCellularGeneral = this.getterGetNewDeviceConfigurations.configuration_module.CellularGeneral.Value;
      this.connectionMode = defaultCellularGeneral.hasOwnProperty("ConnectionMode") ? defaultCellularGeneral.ConnectionMode : -1;
      this.connectionType = defaultCellularGeneral.hasOwnProperty("ConnectionType") ? defaultCellularGeneral.ConnectionType : -1;
      this.dataRoaming = defaultCellularGeneral.hasOwnProperty("DataRoaming") ? (defaultCellularGeneral.DataRoaming ? true : false) : false;
      this.automaticDNSEnable = defaultCellularGeneral.hasOwnProperty("AutomaticDNSEnable") ? (defaultCellularGeneral.AutomaticDNSEnable ? true : false) : false;
      this.PrimaryDNSAddress = defaultCellularGeneral.hasOwnProperty("PrimaryDNSAddress") ? defaultCellularGeneral.PrimaryDNSAddress : "";
      this.SecondaryDNSAddress = defaultCellularGeneral.hasOwnProperty("SecondaryDNSAddress") ? defaultCellularGeneral.SecondaryDNSAddress : "";

      this.$nextTick(() => {
        this.mixinValidateForm("cellularGeneral", "connections");
      });
    },
  },
};
</script>
