import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VCard,{staticClass:"d-flex flex-column",attrs:{"flat":""}},[_c(VCardTitle,[_vm._v("Device Status")]),_c(VCardText,{staticClass:"d-flex flex-column my-auto"},[_vm._l((_vm.splitComponentList),function(row,rowi){return [_c(VRow,{staticClass:"d-flex justify-space-around"},[_vm._l((row),function(item,x){return [_c(VCol,{staticClass:"d-flex flex-column align-center mt-4",attrs:{"cols":_vm.colWidths[rowi]}},[_vm._t(item)],2),(x !== row.length - 1)?_c(VDivider,{attrs:{"vertical":"","inset":""}}):_vm._e()]})],2),(rowi !== _vm.numRows - 1)?_c(VDivider):_vm._e()]})],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }