import { mixin_list_table_utils } from './listTableUtils.js';

export const mixin_config_history_utils = {
  mixins: [ mixin_list_table_utils ],
  data(){
    return {
      // The table below defines all configuration value or names for change history tracking
      // Each key name corresponds to "path" fron json diff array
      mixinFieldNames: {
        // WirelessLAN Mapping(FX20, RG2100)
        "configuration_module.WirelessLAN.Value.InActiveTime" : {
          name: "Inactive Time",
          value: (val) => {
            const returnObj = ["10 minutes", "20 minutes", "30 minutes", "Always On"];
            return returnObj[val];
          }
          
        },
        "configuration_module.WirelessLAN.Value.AllowGuestWiFiToAccessWebUI" : {
          name: "Allow Guest WiFi To Access Web UI",
          value: (val) => this.mixinOnOffReturn(val)
        },
        "configuration_module.WirelessLAN.Value.Guest3WiFiEnable" : {
          name: "Enable Guest Wi-Fi",
          value: (val) => this.mixinOnOffReturn(val)
        },
        "configuration_module.WirelessLAN.Value.Guest3WiFi.MaximumConnections" : {
          name: "Guest Wi-Fi Connections Allowed"
        },
        "configuration_module.WirelessLAN.Value.Guest3WiFi.WiFiName" : {
          name: "Guest Wi-Fi Name(SSID)"
        },
        "configuration_module.WirelessLAN.Value.Guest3WiFi.WiFiPassword" : {
          name: "Guest Wi-Fi Password"
        },
        "configuration_module.WirelessLAN.Value.Guest3WiFi.PrivacySeparator" : {
          name: "Guest Wi-Fi Privacy Separator",
          value: (val) => this.mixinOnOffReturn(val)
        },
        "configuration_module.WirelessLAN.Value.Guest3WiFi.RadioBand" : {
          name: "Guest Wi-Fi Band",
          value: (val) => this.mixinWifiBandReturn(val)
        },
        "configuration_module.WirelessLAN.Value.Guest3WiFi.SSIDStealth" : {
          name: "Guest Wi-Fi SSID Stealth",
          value: (val) => this.mixinOnOffReturn(val)
        },
        "configuration_module.WirelessLAN.Value.Guest3WiFi.AuthenticationMethod" : {
          name: "Guest Wi-Fi Authentication",
          value: (val) => this.mixinWifiAuthenticationReturn(val)
        },
        "configuration_module.WirelessLAN.Value.Guest3WiFi.EncryptionMethod" : {
          name: "Guest Wi-Fi Encryption Method",
          value: (val) => this.mixinWifiEncryptionReturn(val)
        },
        "configuration_module.WirelessLAN.Value.MainWiFi.MaximumConnections" : {
          name: "Main Wi-Fi Connections Allowed"
        },
        "configuration_module.WirelessLAN.Value.MainWiFi.WiFiName" : {
          name: "Main Wi-Fi Name(SSID)"
        },
        "configuration_module.WirelessLAN.Value.MainWiFi.WiFiPassword" : {
          name: "Main Wi-Fi Password"
        },
        "configuration_module.WirelessLAN.Value.MainWiFi.PrivacySeparator" : {
          name: "Main Wi-Fi Privacy Separator",
          value: (val) => this.mixinOnOffReturn(val)
        },
        "configuration_module.WirelessLAN.Value.MainWiFi.RadioBand" : {
          name: "Main Wi-Fi Band",
          value: (val) => this.mixinWifiBandReturn(val)
        },
        "configuration_module.WirelessLAN.Value.MainWiFi.SSIDStealth" : {
          name: "Main Wi-Fi SSID Stealth",
          value: (val) => this.mixinOnOffReturn(val)
        },
        "configuration_module.WirelessLAN.Value.MainWiFi.AuthenticationMethod" : {
          name: "Main Wi-Fi Authentication",
          value: (val) => this.mixinWifiAuthenticationReturn(val)
        },
        "configuration_module.WirelessLAN.Value.MainWiFi.EncryptionMethod" : {
          name: "Main Wi-Fi Encryption Method",
          value: (val) => this.mixinWifiEncryptionReturn(val)
        },

        // APN Info Mappings(RG2100)
        "configuration_module.APNInfo.Value.ActiveProfileIndex" : {
          name: "Active Profile Index"
        },
        "configuration_module.APNInfo.Value.AttachedProfileIndex" : {
          name: "Attached Profile Index"
        },
        "configuration_module.APNInfo.Value.ProfileList" : {
          name: "APN List"
        },
        // CellularGeneral Mapping(RG2100)
        "configuration_module.CellularGeneral.Value.ConnectionMode" : {
          name: "Connection Mode",
          value: (val) => this.mixinConnectionModeReturn(val)
        },
        "configuration_module.CellularGeneral.Value.ConnectionType" : {
          name: "Connection Type",
          value: (val) => this.mixinConnectionTypeReturn(val)
        },
        "configuration_module.CellularGeneral.Value.DataRoaming" : {
          name: "Data Roaming",
          value: (val) => this.mixinOnOffReturn(val)
        },
        "configuration_module.CellularGeneral.Value.AutomaticDNSEnable" : {
          name: "Automatic DNS Enable",
          value: (val) => this.mixinOnOffReturn(val)
        },
        "configuration_module.CellularGeneral.Value.PrimaryDNSAddress" : {
          name: "Primary DNS Address",
        },
        "configuration_module.CellularGeneral.Value.SecondaryDNSAddress" : {
          name: "Secondary DNS Address",
        },
        // MISC(display) Mapping(RG2100)
        "configuration_module.MISC.Value.ShowUsage" : {
          name: "Show Usage",
          value: (val) => this.mixinShowUsageReturn(val)
        },
        "configuration_module.MISC.Value.DisplayTimeout" : {
          name: "Device Display Timeout",
          value: (val) => this.mixinDisplayTimeoutReturn(val)
        },
        "configuration_module.MISC.Value.LEDIndicatorEnable" : {
          name: "Power LED Indicator",
          value: (val) => this.mixinEnableReturn(val)
        },
        "configuration_module.MISC.Value.DisplayWiFiNamePassword" : {
          name: "Display Wi-Fi SSID & Password",
          value: (val) => this.mixinDisplayWifiInfoReturn(val)
        },
        "configuration_module.MISC.Value.WebAdminPageInfoDisplay" : {
          name: "Display Web Admin Page Info",
          value: (val) => this.mixinEnableReturn(val)
        },
        "configuration_module.MISC.Value.FactoryResetButtonEnable" : {
          name: "Factory Reset Button",
          value: (val) => this.mixinEnableReturn(val)
        },
        "configuration_module.MISC.Value.PowerBankEnable" : {
          name: "Factory Reset Button",
          value: (val) => this.mixinEnableReturn(val)
        },
        "configuration_module.MISC.Value.StopChargingBatteryLevel" : {
          name: "Battery Level",
          value: (val) => this.mixinGetControlBatteryLevelReturn(val)
        },
        "configuration_module.MISC.Value.AccessWebInterface" : {
          name: "Access Web Interface",
          value: (val) => this.mixinEnableReturn(val)
        },
        // Usage related(RG2100)
        "configuration_module.Usage.Value.UsageCycle" : {
          name: "Data Cycle Resets",
          value: (val) => this.mixinUsageCycleReturn(val)
        },
        "configuration_module.Usage.Value.CycleStartDayofMonth" : {
          name: "Monthly Cycle Day",
          value: (val) => this.mixinMonthDayReturn(val)
        },
        "configuration_module.Usage.Value.CycleStartDayofYear" : {
          name: "Yearly Cycle Month and Day",
          value: (val) => this.mixinYearDayReturn(val)
        },
        "configuration_module.Usage.Value.MonthlyLimit" : {
          name: "Monthly Limit",
          value: (val) => this.mixinUsageLimitReturn(val)
        },
        "configuration_module.Usage.Value.MonthlyLimitAlert" : {
          name: "Monthly Limit Alert",
          value: (val) => this.mixinOnOffReturn(val)
        },
        // WiredLAN(DHCP Range)(RG2100, FX20)
        "configuration_module.WiredLAN.Value.DHCPServer.DefaultGateway" : {
          name: "Default Gateway"
        },
        "configuration_module.WiredLAN.Value.DHCPServer.IPAddrStarting4th" : {
          name: "IP Pool Starting Address"
        },
        "configuration_module.WiredLAN.Value.DHCPServer.IPAddrEnding4th" : {
          name: "IP Pool Ending Address"
        },
        "configuration_module.WiredLAN.Value.DHCPServer.LeaseTime" : {
          name: "Lease Time"
        },
        // Basic Security(Firewall)(RG2100)
        "configuration_module.FirewallGeneral.Value.IPv4Firewall" : {
          name: "Firewall Switch",
          value: (val) => this.mixinOnOffReturn(val)
        },
        // Basic Security(PortForwarding)(RG2100, FX20)
        "configuration_module.PortForwarding.Value.Enable" : {
          name: "Enable Port Forwarding",
          value: (val) => this.mixinEnableReturn(val)
        },
        "configuration_module.PortForwarding.Value.PortMappingList" : {
          name: "Port Mapping List"
        },
        // MAC Filtering(RG2100, FX20)
        "configuration_module.MACAddressFiltering.Value.FilterSet" : {
          name: "Allow only users on this list",
          value: (val) => this.mixinEnableReturn(val)
        },

        // Parental Control(FX20)
        "configuration_module.ParentalControl.Value.Enable" : {
          name: "Enable Parental Control",
          value: (val) => this.mixinEnableReturn(val)
        },
        // DMZ(FX20)
        "configuration_module.DMZ.Value.Enable" : {
          name: "Enable DMZ",
          value: (val) => this.mixinEnableReturn(val)
        },
        "configuration_module.DMZ.Value.HostIPAddress" : {
          name: "Host IP Address"
        },
        // DDNS(FX20)
        "configuration_module.DynamicDNS.Value.Enable" : {
          name: "Enable",
          value: (val) => this.mixinEnableReturn(val)
        },
        "configuration_module.DynamicDNS.Value.ServiceProvider" : {
          name: "Service Provider",
          value: (val) => this.mixinDDNSServiceProviderReturn(val)
        },
        "configuration_module.DynamicDNS.Value.DomainName" : {
          name: "Domain Name"
        },
        "configuration_module.DynamicDNS.Value.UserID" : {
          name: "User ID"
        },
        "configuration_module.DynamicDNS.Value.UserPassword" : {
          name: "User Password"
        },
        //VPN Passthrough(FX20)
        "configuration_module.VPNPassthrough.Value.PPTPPassthrough" : {
          name: "PPTP Passthrough",
          value: (val) => this.mixinEnableReturn(val)
        },
        "configuration_module.VPNPassthrough.Value.L2TPPassthrough" : {
          name: "L2TP Passthrough",
          value: (val) => this.mixinEnableReturn(val)
        },
        // Content Filtering(FX20)
        "configuration_module.ContentFiltering.Value.Enable" : {
          name: "Enable Content Filtering",
          value: (val) => this.mixinEnableReturn(val)
        },
        // URL Filtering(FX20)
        "configuration_module.URLFiltering.Value.FilterSet" : {
          name: "Enable",
          value: (val) => this.mixinEnableReturn(val)
        },
        // Service Filtering(FX20)
        "configuration_module.ServiceFiltering.Value.FilterSet" : {
          name: "Enable",
          value: (val) => this.mixinCommonListReturn(this.mixinGetFilterSetList(), val)
        },
        // NTP Time(FX20)
        "configuration_module.NTPTimeServer.Value.EnableNTP" : {
          name: "Enable NTP Server",
          value: (val) => this.mixinEnableReturn(val)
        },
        "configuration_module.NTPTimeServer.Value.DayLightSavingTime" : {
          name: "Enable Day Light Saving Time",
          value: (val) => this.mixinEnableReturn(val)
        },
        "configuration_module.NTPTimeServer.Value.TimeZone" : {
          name: "Time Zone"
        },
        // MISC Settings(System)(FX20)
        "configuration_module.System.Value.MISC.HTTPPort" : {
          name: "HTTP Port"
        },
        "configuration_module.System.Value.MISC.InternetDegradationAlertPercentage" : {
          name: "Internet Degradation Alert Percentage",
          value: (val) => this.mixinPercentageReturn(val)
        },
        "configuration_module.System.Value.MISC.FactoryResetButtonEnable" : {
          name: "Factory Reset Button Enable",
          value: (val) => this.mixinEnableReturn(val)
        },
        "configuration_module.System.Value.MISC.SSHAccess" : {
          name: "SSH Access",
          value: (val) => this.mixinEnableReturn(val)
        },
        "configuration_module.System.Value.MISC.WebUIAccess" : {
          name: "Web UI Access",
          value: (val) => this.mixinEnableReturn(val)
        },
        // Router Admin(FX20)
        "configuration_module.RouterAdmin.Value.RouterAccountList.0.UserName" : {
          name: "Username"
        },
        "configuration_module.RouterAdmin.Value.RouterAccountList.0.Password" : {
          name: "Password"
        },
        // Report Sets(FX20)
        "configuration_module.ReportSets.Value.ConnectedStatusReportInterval" : {
          name: "Connected Status Report",
          value: (val) => this.mixinCommonListReturn(this.mixinGetHoursList(), val)
        },
        "configuration_module.ReportSets.Value.DHCPLeaseReportInterval" : {
          name: "DHCP Lease Report Interval",
          value: (val) => this.mixinCommonListReturn(this.mixinGetHoursList(), val)
        },
        // Alerts Sets(FX20)
        "configuration_module.AlertsSets.Value.InternetInitiatedAlert" : {
          name: "Internet Initiated Alert",
          value: (val) => this.mixinEnableReturn(val)
        },
        "configuration_module.AlertsSets.Value.ConfigurationStatusAlert" : {
          name: "Configuration Status Alert",
          value: (val) => this.mixinEnableReturn(val)
        },
        "configuration_module.AlertsSets.Value.IPAddressFilteringAlert" : {
          name: "IPAddress Filtering Alert",
          value: (val) => this.mixinEnableReturn(val)
        },
        "configuration_module.AlertsSets.Value.SystemHealthWarningAlert" : {
          name: "System Health Warning Alert",
          value: (val) => this.mixinEnableReturn(val)
        },
        "configuration_module.AlertsSets.Value.ServiceControlAlert" : {
          name: "Service Control Alert",
          value: (val) => this.mixinEnableReturn(val)
        },
        "configuration_module.AlertsSets.Value.TimeControlAlert" : {
          name: "Time Control Alert",
          value: (val) => this.mixinEnableReturn(val)
        },
        "configuration_module.AlertsSets.Value.InternetSpeedDegradationAlert" : {
          name: "Internet Speed Degradation Alert",
          value: (val) => this.mixinEnableReturn(val)
        },
        "configuration_module.AlertsSets.Value.WiFiWrongAccessAlert" : {
          name: "Wi-Fi Wrong Access Alert",
          value: (val) => this.mixinEnableReturn(val)
        },
      },
      mixinConfigArrayNames: {
        // APN ProfileList Mapping(RG2100)
        Index: {
          name: "Index"
        },
        Active: {
          name: "Active",
          value: (val) => this.mixinOnOffReturn(val)
        },
        ProfileName: {
          name: "Profile Name",
        },
        APNName: {
          name: "APN Name"
        },
        PDPType: {
          name: "PDP Type",
          value: (val) => this.mixinPDPTypeReturn(val)
        },
        AuthType: {
          name: "Authentication Type",
          value: (val) => this.mixinAPNAuthTypeReturn(val)
        },
        UserName: {
          name: "Username"
        },
        Password: {
          name: "Password"
        },
        InactiveTime: {
          name: "Inactive Time",
          value: (val) => this.mixinSecondsReturn(val)
        },
        IPAllocation: {
          name: "IP Allocation",
          value: (val)=> this.mixinAPNIPAllocationReturn(val)
        },
        // PortMappingList(RG2100, FX20)
        RuleName: {
          name: "Port Mapping Rule Name"
        },
        WANPortStart: {
          name: "WAN Port Range Start"
        },
        WANPortEnd: {
          name: "WAN Port Range End"
        },
        LANIPAddress: {
          name: "LAN IP Address"
        },
        LANPort: {
          name: "Local Port"
        },
        Protocol: {
          name: "Port Internet Protocol",
          value: (val) => this.mixinInternetProtocolReturn(val)
        },
        ActiveRule: {
          name: "Active Rule",
          value: (val) => this.mixinEnableReturn(val)
        },
        // MAC Filtering(RG2100)
        HostName: {
          name: "Host Name",
        },
        MACAddress: {
          name: "MAC Address",
          value: (val) => this.mixinMACAddressReturn(val)
        },
        // Time of Day Access(RG2100)
        ScheduleName: {
          name: "Time of Day Access Schedule Name"
        },
        StartTime: {
          name: "Schedule Start Time",
          value: (val) => this.mixinIntegerTimeReturn(val)
        },
        EndTime: {
          name: "Schedule End Time",
          value: (val) => this.mixinIntegerTimeReturn(val)
        },
        DayOfWeek: {
          name: "Schedule End Time"
        },
        Enable: {
          name: "Enable",
          value: (val) => this.mixinEnableReturn(val)
        },
        // Content Filtering Rules(FX20)
        Keyword: {
          name: "Keyword"
        },
        // URL Filtering(FX20)
        URL: {
          name: "URL"
        },
        // NTP Time(FX20)
        NTPServerName: {
          name: "NTP Server Name"
        },
        // Service Filtering(FX20)
        SourceIP: {
          name: "Source IP Address"
        },
        SourcePortRangeStart: {
          name: "Source Port Range Start"
        },
        SourcePortRangeEnd: {
          name: "Source Port Range End"
        },
        DestinationIP: {
          name: "Destination IP Address"
        },
        DestinationPortRangeStart: {
          name: "Destination Port Range Start"
        },
        DestinationPortRangeEnd: {
          name: "Destination Port Range End"
        },
      }
    }
  },
  methods: {
    // 아래 method들은 SingleCard.vue에서 사용함
    // 
    mixinGetPropertyValue(object, path, val){
      return object[path].value ? object[path].value(val) : val;
    },
    mixinSplitPropName(path) {
      const pathArr = path.split('.');
      return pathArr[pathArr.length - 1];
    },
    // mixinGetTypeIcon(type){
    //   const changeTypeColor = {
    //     replace: {
    //       icon: "mdi-text-box-edit-outline",
    //       color: "#2697F2"
    //     },
    //     add: {
    //       icon: "mdi-plus-outline",
    //       color: "#2FC722"
    //     },
    //     delete: {
    //       icon: "mdi-trash-can-outline",
    //       color: "#F17808"
    //     }
    //   }
    //   return changeTypeColor[type];
    // },

    // Array로 되어 있는 데이터 구조의 변경내용을 UI에 표시하기 위해 다음과 같이 parsing함
    // 다음과 같은 구조에서
    // [
    //   {
    //     "type": "replace",
    //     "path": "configuration_module.APNInfo.Value.ProfileList.0.Active",
    //     "before": 1,
    //     "after": 0
    //   },
    //   {
    //     "type": "delete",
    //     "path": "configuration_module.APNInfo.Value.ProfileList.0.UserName",
    //     "before": ""
    //   },
    //   {
    //     "type": "delete",
    //     "path": "configuration_module.APNInfo.Value.ProfileList.0.Password",
    //     "before": ""
    //   }
    // ]
    //
    // 아래와 같은 구조로 변경됨
    // [
    //   {
    //     "key": "ProfileList-0",
    //     "index": "0",
    //     "diffs": [
    //       {
    //         "type": "replace",
    //         "path": "configuration_module.APNInfo.Value.ProfileList.0.Active",
    //         "before": 1,
    //         "after": 0
    //       },
    //       {
    //         "type": "delete",
    //         "path": "configuration_module.APNInfo.Value.ProfileList.0.UserName",
    //         "before": ""
    //       },
    //       {
    //         "type": "delete",
    //         "path": "configuration_module.APNInfo.Value.ProfileList.0.Password",
    //         "before": ""
    //       }
    //     ]
    //   }
    // ]
    mixinParseListData(diffs, customPattern) {
      const changesGroupedByIndex = {};
      // custom regex pattern이 없는 경우 리스트에서 ".ProfileList.0"와 같은 패턴만 골라냄
      const defaultPattern = /\.(\w+)\.(\d+)/;
      const pattern = customPattern || defaultPattern;

      diffs.forEach((diff) => {
        // Use the provided custom pattern or the default pattern
        const match = diff.path.match(pattern);
        if (match) {
          // Create a unique key for grouping based on the matched pattern
          const groupKey = match.slice(1).join("-");
          const index = match[2];

          // Initialize the group for this key if it doesn't exist
          if (!changesGroupedByIndex[groupKey]) {
            changesGroupedByIndex[groupKey] = {
              key: groupKey,
              index: index,
              diffs: []
            };
          }

          // Add the diff to the group
          changesGroupedByIndex[groupKey].diffs.push(diff);
        }
      });

      // Convert the object into an array for easy iteration in Vue
      return Object.values(changesGroupedByIndex);
    }
  }
}