import { VIcon } from 'vuetify/lib/components/VIcon';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"supported-devices"},[_vm._m(0),_c('div',{staticClass:"supported-device-list"},[_c('h2',{staticClass:"grey--text text--darken-2"},[_vm._v("Jextream* Supported Devices:")]),_c('ul',[_c('li',[_c('p',{staticClass:"grey--text text--darken-3"},[_c(VIcon,{staticClass:"mr-1 black--text",attrs:{"small":""}},[_vm._v("mdi-devices")]),_vm._v(" RG2100 ")],1),_vm._m(1)]),_c('li',[_c('p',{staticClass:"grey--text text--darken-3"},[_c(VIcon,{staticClass:"mr-1 black--text",attrs:{"small":""}},[_vm._v("mdi-devices")]),_vm._v(" RG2102 ")],1),_vm._m(2)]),_c('li',[_c('p',{staticClass:"grey--text text--darken-3"},[_c(VIcon,{staticClass:"mr-1 black--text",attrs:{"small":""}},[_vm._v("mdi-devices")]),_vm._v(" RG2100N ")],1),_vm._m(3)]),_c('li',[_c('p',{staticClass:"grey--text text--darken-3"},[_c(VIcon,{staticClass:"mr-1 black--text",attrs:{"small":""}},[_vm._v("mdi-devices")]),_vm._v(" CG890 ")],1),_vm._m(4)])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('figure',[_c('img',{staticStyle:{"max-width":"200px"},attrs:{"src":require("@/assets/jextreamDevices.png"),"alt":"Jextream Devices"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('em',{staticClass:"grey--text text--darken-1"},[_vm._v("(T-Mobile)")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('em',{staticClass:"grey--text text--darken-1"},[_vm._v("(AT&T / Enterprise only)")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('em',{staticClass:"grey--text text--darken-1"},[_vm._v("(C-spire coming soon)")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('em',{staticClass:"grey--text text--darken-1"},[_vm._v("(Coming soon)")])])
}]

export { render, staticRenderFns }