import { VBtn } from 'vuetify/lib/components/VBtn';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VForm } from 'vuetify/lib/components/VForm';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSelect } from 'vuetify/lib/components/VSelect';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VDialog,{attrs:{"overflow-hidden":"","persistent":"","max-width":"600px"},model:{value:(_vm.organizationGroupDialog),callback:function ($$v) {_vm.organizationGroupDialog=$$v},expression:"organizationGroupDialog"}},[(_vm.organizationGroupDialog)?_c('DialogCard',{attrs:{"modalIcon":'format-list-group-plus',"modalTitle":'Add devices to group'},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c(VBtn,{attrs:{"icon":"","x-small":""},on:{"click":function($event){return _vm.close_create_group_dialog()}}},[_c(VIcon,{attrs:{"color":"white"}},[_vm._v("mdi-close")])],1)]},proxy:true},{key:"body",fn:function(){return [_c(VForm,{ref:"form"},[_c(VSelect,{attrs:{"label":"Select a group","dense":"","outlined":"","items":_vm.groupList},scopedSlots:_vm._u([(_vm.isLoadingGroups == true)?{key:"label",fn:function(){return [_c('i',{staticClass:"fas fa-spinner fa-spin mr-1",staticStyle:{"color":"#949494"}}),_c('span',[_vm._v(" Loading "+_vm._s(_vm.groupModel)+" groups ")])]},proxy:true}:null],null,true),model:{value:(_vm.group),callback:function ($$v) {_vm.group=$$v},expression:"group"}})],1)]},proxy:true},{key:"footer",fn:function(){return [_c(VBtn,{staticClass:"blue--text",attrs:{"small":"","outlined":""},on:{"click":function($event){return _vm.close_create_group_dialog()}}},[_vm._v("Cancel")]),_c(VBtn,{staticClass:"button tabledata--text mr-2",attrs:{"small":"","loading":_vm.loadingAddGroup},on:{"click":function($event){return _vm.add_to_group()}}},[_vm._v("Assign")])]},proxy:true}],null,false,280590941)}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }