<template>
  <div>
    <v-btn class="button tabledata--text ml-2" @click="download_template()">
      <v-icon class="ma-1">mdi-download</v-icon>
      Download
    </v-btn>

    <v-btn class="button tabledata--text ml-2" @click="trigger_excel_file_upload">
      <v-icon class="ma-1">mdi-upload</v-icon>
      Upload
    </v-btn>
    <input ref="excelUploadInput" style="display: none;" type="file" accept=".xlsx, .xls,text/csv" @change="upload_excel_sheet" />
    </div>
</template>

<script>
import { mapMutations } from "vuex";

import XLSX from "xlsx";
export default {
  props: {
    validateExcelFileUpload: Function,
  },
  created() {},
  methods: {
    ...mapMutations(["mutationSetSnackBarItem"]),
    triggerSnackBar(type, text) {
      this.mutationSetSnackBarItem({ type, text });
    },
    //method invokes on download action
    download_template() {
      window.open("https://jexstream-routers-excels.s3.amazonaws.com/pmdmTemplate/RG2100_Template.xlsx", "_blank");
    },

    //built in methods for handling file uploads
    trigger_excel_file_upload() {
      this.$refs["excelUploadInput"].click();
    },

    //built in methods for handling file uploads
    async upload_excel_sheet(e) {
      const rawFile = e.target.files[0];
      if (!rawFile) return this.triggerSnackBar("error", "The file is either unsupported or does not exist.");
      this.$refs["excelUploadInput"].value = null;
      try {
        const data = await rawFile.arrayBuffer();
        const workbook = XLSX.read(data, { type: "array" });
        const firstSheet = workbook.Sheets[workbook.SheetNames[0]];
        const header = this.getHeaderRow(firstSheet); //Header is used nowhere currently
        const excelDeviceList = XLSX.utils.sheet_to_json(firstSheet);
        this.validateExcelFileUpload(excelDeviceList);
      } catch(error) {
        this.triggerSnackBar("error", error);
      }
    },

    //built in methods for handling file uploads
    getHeaderRow(sheet) {
      const headers = [];
      const range = XLSX.utils.decode_range(sheet["!ref"]);
      const firstRow = range.s.r;

      for (let col = range.s.c; col <= range.e.c; ++col) {
        const cellAddress = XLSX.utils.encode_cell({ c: col, r: firstRow });
        const cell = sheet[cellAddress];
        const header = cell ? XLSX.utils.format_cell(cell) : `UNKNOWN ${col}`;
        headers.push(header);
      }
      return headers;
    },
  },
};
</script>