<template>
  <div class="d-flex flex-row justify-space-between align-center">
    <v-progress-circular class="mr-4" v-if="deviceMonthlyDataUsageLimit" :size="60" :width="10" :rotate="-90" :value="usagePercentage" color="#0195D2"/>
    <h1 class="dtwidth">{{ deviceDataUsage.toString().substr(0, 5) }}GB</h1>
  </div>
</template>

<script>

export default {
  name: "DataUsage",
  props: {
    deviceDataUsage: {
      type: Number,
      default: 0,
    },
    deviceMonthlyDataUsageLimit: {
      type: Number,
      default: 0,
    }
  },
  computed: {
    usagePercentage() {
      return this.deviceMonthlyDataUsageLimit === 0 ? 100 : 
        (this.deviceDataUsage / this.deviceMonthlyDataUsageLimit) * 100;
    }, 
  },
};
</script>