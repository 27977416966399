<template>
  <v-app v-if="getterGetIsAuthenticated" id="topbar" elevation="0">
    <!-- v-if start -->
    <v-app-bar app :extension-height="getterGetIsImpersonating ? '60' : '70'" :height="getterGetIsImpersonating ? '' : '0'" clipped-left dense elevation="0" tile color="bannercolor" :class="getterGetIsImpersonating ? 'impersonate-user' : ''">
      <v-container v-if="getterGetIsImpersonating" class="impersonate-header ma-0 pa-0">
        <div class="d-flex align-center">
          <span class="ml-4 mt-1 white--text"><b class="mr-1">Admin View:</b> {{ getterGetOriginalUserEmail }}</span>
          <v-btn class="ml-2" x-small icon color="white" @click="open_impersonation_panel">
            <v-icon>mdi-account-clock</v-icon>
          </v-btn>
          <v-btn class="ml-auto white--text mt-1 mr-4" x-small text @click="back_to_admin()">
            Back to Admin
            <v-icon right class="white--text mr-1">mdi-close</v-icon>
          </v-btn>
        </div>
      </v-container>

      <template #extension>
        <v-img @click="mixinChangeRoute('/Home')" class="d-md-none d-inline" :max-width="60" :width="60"  src="@/assets/Artboard1.svg" style="cursor: pointer"></v-img>
        <v-img @click="mixinChangeRoute('/Home')" class="d-none d-md-inline":max-width="240" :width="240"  src="@/assets/JextreamLogoWht.png" style="cursor: pointer"></v-img>

        <div class="white--text ml-5 mt-5">{{ deployVersion }}</div>
        <v-spacer></v-spacer>

        <!-- Menu -->
        <v-card flat :class="getterGetIsImpersonating ? 'impersonate-user' : ''">
          <TopMenu></TopMenu>
        </v-card>
        <!-- Pintrac Button -->
        <div>
          <v-btn v-if="getterGetIsPintracAccount" class="move-to-pintrac ml-2" color="#1E1E1E" x-small depressed @click="redirect_to_pintrac">
            Swtich To
            <v-img class="mr-1" src="@/assets/MoveToPintrac.svg" alt="Pintrac Logo" />
          </v-btn>
        </div>

        <v-card flat color="bannercolor" class="ml-2 mt-2" @click.stop="drawer = !drawer">
          <v-card-text class="mt-n4">
            <b class="button--text">{{ getUserEmail }}</b>
            <br />
            <span class="button--text">{{ getCurrentUserRoleString }}</span>
            <br />
          </v-card-text>
        </v-card>
      </template>
    </v-app-bar>

    <router-view @openManageSubscription="openManageSubscription()"></router-view>

    <!-- Navigation drawer for info details -->
    <SideBar :drawer.sync="drawer" @view_profile="view_profile_details()" @openCustomerPortal="openCustomerPortal()" @openManageSubscription="openManageSubscription()" @unsubscribe="open_subscription_dialog()"></SideBar>

    <div v-if="profileCheck">
      <UpdateProfile :updateDialog="updateDialog" @clicked="close_dialog"></UpdateProfile>
    </div>

    <!-- <PricingPage :pricingPageDialog="pricingPageDialog" @close="closePricingPageDialog" /> -->
    <Subscription :subscriptionDialog="subscriptionDialog" @close="close_subscription_dialog" />
    <CustomerPortal :customerPortalDialog="customerPortalDialog" @close="closeCustomerPortalDialog" />
    <ManageSubscription :manageSubscriptionDialog="manageSubscriptionDialog" @close="closeManageSubscriptionDialog" />
    <ImpersonationPanel :impersonationPanel="impersonationPanel" @close="close_impersonation_panel"/>
  </v-app>
</template>

<script>
import alerticon from "@/assets/alerticon.png";
import dashicon from "@/assets/dashicon.png";


import TopMenu from "../components/MenuAccess/TopNav/TopMenu.vue";
import Subscription from "@/components/Subscription.vue";
import UpdateProfile from "@/components/UpdateProfileInfo/UpdateProfile.vue";
import SideBar from "@/components/MenuAccess/SideNav/SideMenu.vue";
import { mixin_routing_utils } from "@/mixins/routingUtils.js";

// Pintrac redirection related
import { loginToPintrac, currentSession } from "@/services"

// import PricingPage from "../components/Stripe/PricingPage.vue";
import CustomerPortal from "../components/Stripe/CustomerPortal.vue";
import { mapGetters, mapActions, mapMutations } from "vuex";
import ManageSubscription from "../components/Stripe/ManageSubscription.vue";

import ImpersonationPanel from "@/components/MenuAccess/TopNav/ImpersonationPanel.vue";

export default {
  mixins: [mixin_routing_utils],
  components: {
    alerticon,
    dashicon,
    TopMenu,
    UpdateProfile,
    Subscription,
    SideBar,
    // PricingPage,
    CustomerPortal,
    ManageSubscription,
    ImpersonationPanel
  },
  data: () => ({
    updateDialog: false,
    drawer: false,
    profileCheck: false,
    events: ["mousemove", "mousedown", "scroll", "click", "load", "keypress", "mouseup", "dblclick", "mouseover", "mousewheel", "mouseout", "contextmenu"],
    logoutTimer: null,
    subscriptionDialog: false,
    pricingPageDialog: false,
    customerPortalDialog: false,
    manageSubscriptionDialog: false,
    impersonationPanel: false,
  }),
  // add event listeners for auto logout feature
  mounted() {
    this.events.forEach((event) => {
      window.addEventListener(event, this.resetTimer);
    });
    this.setTimers();
  },
  // remove event listeners on unmount
  beforeDestroy() {
    this.events.forEach((event) => {
      window.removeEventListener(event, this.resetTimer);
    });
  },
  computed: {
    ...mapGetters(["getterGetUserInfo", "getterGetCurrentUserRoleString", "getterGetIsAuthenticated", "getterGetCurrentUserEmail", "getterGetIsImpersonating", "getterGetOriginalUserEmail", "getterGetIsPintracAccount"]),
    getCurrentUserRoleString() {
      return this.getterGetCurrentUserRoleString;
    },
    getUserEmail() {
      return this.getterGetCurrentUserEmail;
    },
    deployVersion() {
      return process.env.VUE_APP_DEPLOY === "development" ? `${process.env.VUE_APP_VERSION} in Staging` : process.env.VUE_APP_VERSION;
    },
  },

  methods: {
    ...mapMutations([ "mutationOpenCircularProgressLoader", "mutationCloseCircularProgressLoader" ]),
    ...mapActions(["actionLogOut", "actionEndImpersonation"]),
    // logout automatically after period of time if no activity
    setTimers() {
      this.logoutTimer = setTimeout(() => {
        this.mixinChangeRoute("/");
      }, 3600 * 1000);
    },
    resetTimer() {
      clearTimeout(this.logoutTimer);
      this.setTimers();
    },

    async redirect_to_pintrac() {
      try {
        this.mutationOpenCircularProgressLoader();
        const session = await currentSession();
        await loginToPintrac(this.getterGetUserInfo.user.user_id, session.getIdToken().getJwtToken());
      } catch (error){
        console.log(error)
      } finally {
        this.mutationCloseCircularProgressLoader();
      }
    },

    // impersonation relate
    open_impersonation_panel() {
      this.impersonationPanel = true;
    },
    close_impersonation_panel() {
      this.impersonationPanel = false;
    },
    back_to_admin() {
      this.drawer = false;
      this.impersonationPanel = false;
      this.actionEndImpersonation();
    },

    //method invokes on update profile action
    view_profile_details() {
      this.profileCheck = true;
      this.updateDialog = true;
    },

    // method invokes on close actions
    close_dialog(val) {
      this.updateDialog = false;
      this.profileCheck = false;
      setTimeout(() => {
        if (val == 1) {
          this.drawer = true;
        }
      }, 1);
    },
    // Customer Portal
    openCustomerPortal() {
      this.customerPortalDialog = true;
    },
    closeCustomerPortalDialog() {
      this.customerPortalDialog = false;
    },
    // Subscription Dialog
    openManageSubscription() {
      this.manageSubscriptionDialog = true;
    },
    closeManageSubscriptionDialog() {
      this.manageSubscriptionDialog = false;
    },

    open_subscription_dialog() {
      this.subscriptionDialog = true;
    },
    close_subscription_dialog(val) {
      if (val == 0) {
        this.subscriptionDialog = false;
      } else if (val == 1) {
        this.$router.push("/");
      }
    },
  },
};
</script>

<style>
.acc-caption {
  font-size: 0.75em;
  font-weight: 500;
  color: #616161;
  letter-spacing: 0.06rem;
}

.acc-info {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 200px;
}

.no-uppercase {
  text-transform: none;
}

.custom-btn .v-btn__content u {
  left: 0;
  position: absolute;
  width: 100%;
}

.logout-icon {
  margin-left: -3.2em;
  margin-right: 2.4em;
}

.impersonate-header {
  background-color: #0195d2;
  width: 100%;
  max-width: 100%;
  height: 100%;
}

.impersonate-user .v-toolbar__content {
  padding-left: 0px;
  padding-right: 0px;
  height: 32px !important;
}

.custom-disabled {
  opacity: 0.3;
  cursor: not-allowed;
  color: white;
  background-color: rgb(255, 255, 255) !important;
}

.move-to-pintrac {
  height: initial !important;
}

.move-to-pintrac .v-btn__content{
  color: white;
}

.move-to-pintrac::after {
  content: "";
  background: linear-gradient(
    45deg,
    #FF0000, #FF7300, #FFFB00, #48FF00,
    #00FFD5, #002BFF, #FF00CB, #FF0000
  );
  position: absolute;
  top: -2px;
  left: -2px;
  background-size: 600%;
  z-index: -1;
  height: calc(100% + 4px);
  width: calc(100% + 4px);
  filter: blur(8px);

  animation: glowing 20s linear infinite;
  transition: opacity .3s ease-in-out;
  border-radius: 10px;
  opacity: 0;
}

.move-to-pintrac:hover::after {
  opacity: 1;
}

@keyframes glowing {
  0% { background-position: 0 0; }
  50% { background-position: 400% 0; }
  100% { background-position: 0 0; }
}
</style>