export const utilHandleExceptions = (error) => {
  const { code, message } = error;
  const errorMessages = {
    UserNotFoundException: "Either the account you've entered does not exist or the password is incorrect.",
    UserLambdaValidationException: "Either the account you've entered does not exist or the password is incorrect.",
    NotAuthorizedException: message.startsWith("Password") ? "Maximum number of login attempts exceeded. Please try again later." : "Either the account you've entered does not exist or the password is incorrect.",
    PasswordResetRequiredException: "Please reset your password to continue.",
    UserNotConfirmedException: "Your account has not been activated yet. Please check your email for a verification code to activate your account.",
    UsernameExistsException: "An account with this email already exists. Please sign in or use a different email.",
    ExpiredCodeException: "The verification code has expired. Please request a new one.",
    CodeMismatchException: "The verification code you entered is incorrect. Please try again.",
    LimitExceededException: "You have exceeded the number of allowed attempts. Please try again later.",
    InvalidParameterException: "One or more input parameters are invalid. Please check your details and try again.",
    TooManyFailedAttemptsException: "Too many failed attempts. Please try again later.",
    TooManyRequestsException: "Too many requests in a short period of time. Please wait a moment and try again.",
  };
  return errorMessages[code] || message;
}