<template>
  <div>
    <v-main>
      <v-toolbar class="elevation-0 mt-2 pa-0" :class="{ 'mb-5': customerAdminCheck, 'mb-4': !customerAdminCheck }" dense flat>
        <v-toolbar-title class="pl-1 mt-1 mr-4">Routers</v-toolbar-title>

        <v-text-field dense clearable append-icon="mdi-magnify blue--text" v-model="search" class="search-bar" color="blue" label="Search" single-line style="max-width: 220px"> </v-text-field>

        <FilterButtonMenu class="ml-2"
          v-model="filters" :groupOptions="groupOptions" :modelOptions="modelOptions" />
        
        <ColumnButtonMenu class="ml-2"
          :allHeaders="allHeaders" v-model="headers"/>

        <v-spacer></v-spacer>
        
        <v-tooltip bottom :disabled="!multipleModelsSelected">
          <template v-slot:activator="{ on }">
            <span v-on="on">
              <v-btn 
                v-if="!supportAdminCheck"
                class="ml-6 mr-2 text-none"
                :disabled="selectedRecordsIsEmpty || multipleModelsSelected"
                @click="open_assign_to_group_dialog(selectedRecords)"
                color="#0195D2"
                outlined
              >
                <v-icon>mdi-plus</v-icon>
                Add To Group
              </v-btn>
            </span>
          </template>
          <span>Cannot add two different model types to the same group</span>
        </v-tooltip>

        <v-btn 
          v-if="customerAdminCheck" 
          class="mr-2 text-none"
          :disabled="selectedRecordsIsEmpty"  
          @click="open_reset_config_dialog"
          color="#0195D2"
          outlined
        >
          <v-icon>mdi-cog-refresh</v-icon>
          Reset Config
        </v-btn>
      </v-toolbar>

      <!-- Start of the Table rows -->
      <v-card class="tablePadding" flat>
        <v-card-text class="pt-0">
          <v-data-table
            dense
            v-model="selectedRecords"
            show-select
            :headers="headers"
            :search="search"
            :items="filteredDeviceList"
            :items-per-page="10"
            :item-class="() => 'device-row'"
            :loading="isTableLoading"
            :footer-props="{ 'items-per-page-options': [10, 25, 50, 100] }"
            :no-data-text="isTableLoading ? 'Loading items...' : 'No devices available'"
            :no-results-text="`No matching results for '${search}'`"
            item-key="router_id"
            @click:row="open_device_details_dialog"
            @toggle-select-all="toggleSelectAll"
            class="elevation-1 dtwidth device-table">

            <template v-slot:[`item.router_name`]="{ item }">
              <span>{{ item.router_name }}</span>
            </template>

            <template v-slot:[`item.time_of_day`]="{ item }">
              <SuspendInternetButton v-if="item.time_of_day" class="mr-2" :scheduleList="item.time_of_day.ScheduleList" @click="open_suspend_internet_dialog(item)" />
              <div v-else>-</div>
            </template>


            <template v-slot:[`item.config_updated_on`]="{ item }">
              <v-tooltip v-if="item.config_updated_on" bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-chip v-on="on" v-bind="attrs" dark small :color="item.indicate_color">
                    <span class="caption">{{ mixinConvertUTCToDate(item.config_updated_on) }}</span>
                  </v-chip>
                </template>
                <span v-if="item.indicate_color === 'red'">The device has never connected to the server</span>
                <span v-else-if="item.indicate_color === 'orange'">The device has yet to receive the latest configuration from the server</span>
                <span v-else-if="item.indicate_color === 'green'">The configuration is up to date</span>
              </v-tooltip>
              <div v-else>-</div>
            </template>

            <template v-slot:[`item.last_reported_on`]="{ item }">
              <v-tooltip v-if="item.last_reported_on" bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-chip v-on="on" v-bind="attrs" dark small :color="mixinGetTimeDifference(item.last_reported_on) >= 1 ? 'grey' : '#4CAF50'">
                    <span class="caption">{{ mixinConvertUTCToDate(item.last_reported_on) }}</span>
                  </v-chip>
                </template>
                <span v-if="mixinGetTimeDifference(item.last_reported_on) >= 1">Last reported a day or more ago</span>
                <span v-else>Last reported within 24hrs</span>
              </v-tooltip>
              <div v-else>-</div>
            </template>

            <template v-slot:[`item.mac_address`]="{ item }">
              <span>{{ item.mac_address ? item.mac_address : "-" }}</span>
            </template>

            <template v-slot:[`item.imei_number`]="{ item }">
              <span>{{ item.imei_number && item.imei_number != "N/A" ? item.imei_number : "-" }}</span>
            </template>

            <template v-slot:[`item.msisdn`]="{ item }">
              <span>{{ item.msisdn && item.msisdn != "N/A" ? item.msisdn : "-" }}</span>
            </template>

            <template v-slot:[`item.organization_name`]="{ item }">
              <span>{{ item.organization_name ? item.organization_name : "-" }}</span>
            </template>

            <template v-slot:[`item.partner_info`]="{ item }">
              <span>{{ item.partner_info ? item.partner_info.user_full_name : "-" }}</span>
            </template>

            <template v-slot:[`item.carrier_info`]="{ item }">
              <span>{{ item.carrier_info ? item.carrier_info : "-" }}</span>
            </template>

            <template v-slot:[`item.signal`]="{ item }">
              <SignalBar v-if="shouldShowSignal(item.router_model)" :signal="item.signal" />
              <span v-else class="ml-1"> - </span>
            </template>

            <template v-slot:[`item.usage`]="{ item }">
              <span>{{ item.usage ? item.usage : "0.0" }}</span>
            </template>

            <template v-slot:[`item.router_description`]="{ item }">
              <span :class="{ 'ml-1': !item.router_description }">
                {{ item.router_description ? item.router_description : "-" }}
              </span>
            </template>

            <template v-slot:[`item.data_usage`]="{ item }">
              <span :class="{ 'ml-1': !item.data_usage }">
                {{ item.data_usage ? item.data_usage : "-" }}
              </span>
            </template>

            <template v-slot:[`item.firmware_version`]="{ item }">
              <span :class="{ 'ml-1': !item.firmware_version }">
                {{ item.firmware_version ? item.firmware_version : "-" }}
              </span>
            </template>

            <template v-slot:[`item.router_group`]="{ item }">
              <span :class="{ 'ml-1': !item.router_group }">
                {{ item.router_group ? item.router_group : "-" }}
              </span>
            </template>

            <template v-slot:[`item.add_ons`]="{ item }">
              <span>
                {{ item.add_ons ? item.add_ons : "-" }}
              </span>
            </template>

            <template v-slot:[`item.router_id`]="{ item }">
              <span>{{ item.router_id.split("/")[1] }}</span>
            </template>

            <template v-slot:[`item.is_individual_router`]="{ item }">
              <span class="caption">
                <span v-if="!item.is_individual_router" class="caption orange--text">Business</span>
                <span v-else class="caption green--text">Individual</span>
              </span>
            </template>

            <!-- Actions dropdown list -->
            <template v-slot:[`item.Actions`]="{ item }">
              <div ref="actions">
                <v-menu bottom left content-class="menu-offset">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn icon v-bind="attrs" v-on="on">
                      <v-icon>mdi-dots-vertical</v-icon>
                    </v-btn>
                  </template>

                  <v-list dense>
                    <v-list-item @click="open_device_details_dialog(item)">
                      <v-list-item-title>Device Details</v-list-item-title>
                    </v-list-item>

                    <v-list-item v-if="superAdminCheck" @click="open_edit_device_dialog(item)">
                      <v-list-item-title>Edit</v-list-item-title>
                    </v-list-item>

                    <v-list-item @click="open_configs_dialog(item)">
                      <v-list-item-title>Configuration</v-list-item-title>
                    </v-list-item>

                    <v-list-item v-if="!partnerAdminCheck" @click="open_remote_command_dialog(item)">
                      <v-list-item-title>Remote Command</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </div>
            </template>

            <template v-slot:[`item.online_status`]="{ item }">
              <span class="caption" :class="item.online_status == 'ONLINE' ? 'green--text text--darken-3' : 'red--text text--darken-2'">
                {{ item.online_status == "ONLINE" ? "Online" : "Offline" }}
              </span>
            </template>

            <!-- Footer -->
            <template v-slot:footer>
              <div>
                <div v-if="isTableLoading">
                  <LoadingIcon style="position: absolute" class="ml-2 mt-0"></LoadingIcon>
                </div>
                <div v-else>
                  <v-icon @click="handle_refresh_list()" class="ml-1 mt-1 btn-fix" dark outline="none" color="cyan" style="position: absolute; font-size: 34px" id="refresh"> mdi-refresh </v-icon>
                  <span style="position: absolute" class="ml-11 pl-0 mt-3">Last updated: {{ mixinGetLastUpdatedTime }}</span>
                </div>
              </div>
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
    </v-main>

    <router-view></router-view>

    <DeviceDetailsDialog :deviceDetailsDialog="deviceDetailsDialog" :singleRouterInfo="singleRouterInfo" @openConfigs="open_configs_dialog" @close="close_device_details_dialog" />

    <RemoteCommandDialog :remoteCommandDialog="remoteCommandDialog" :singleRouterInfo="singleRouterInfo" @close="close_remote_command_dialog"> </RemoteCommandDialog>

    <AssignToGroupDialog :assignToGroupDialog="assignToGroupDialog" :selectedDevices="selectedDevices" :groupModel="groupModel" :deviceOrganization="getCurrentUserCustomerID" @close="close_assign_to_group_dialog" />

    <EditADeviceDialog :editDeviceDialog="editDeviceDialog" :editDeviceInfo="editDeviceInfo" @clicked="close_edit_device_dialog" />

    <ResetDeviceConfigsDialog :resetConfigDialog="resetConfigDialog" :selectedRecords="selectedRecords" @close="close_reset_config_dialog" />

    <DeviceConfigDialog :deviceConfigDialog="deviceConfigDialog" :isGroupConfig="false" :singleRouterInfo="singleRouterInfo" @close="close_configs_dialog" />

    <SuspendInternetDialog :suspendInternetDialog="suspendInternetDialog" :singleRouterInfo="singleRouterInfo" @close="close_suspend_internet_dialog" />
  </div>
</template>

<script>
import DeviceDetailsDialog from "@/components/Devices/DeviceDialogs/DeviceDetailsDialog/DeviceDetailsDialog.vue";
import RemoteCommandDialog from "@/components/Devices/DeviceDialogs/RemoteCommandDialog.vue";
import EditADeviceDialog from "@/components/Devices/DeviceDialogs/EditADeviceDialog.vue";
import DeviceConfigDialog from "@/components/DeviceConfigurations/DeviceConfigDialog.vue";

import SuspendInternetDialog from "./SharedComponents/SuspendInternetDialog.vue";
import SuspendInternetButton from "./SharedComponents/SuspendInternetButton.vue";
import AssignToGroupDialog from "./DeviceDialogs/AssignToGroupDialog.vue";
import ResetDeviceConfigsDialog from "@/components/Devices/DeviceDialogs/ResetDeviceConfigsDialog.vue";
import LoadingIcon from "@/components/LoadingIcon.vue";
import SignalBar from "./SharedComponents/SignalBar.vue";

import FilterButtonMenu from "@/components/Devices/SharedComponents/FilterButtonMenu.vue";
import ColumnButtonMenu from "@/components/Devices/SharedComponents/ColumnButtonMenu.vue";

import { router_model_utils } from "@/mixins/routerModelUtils.js";
import { mixin_time_utils } from "@/mixins/timeUtils";

import { getDeviceList, requestQueryToRouter } from "@/services";

import { mapGetters, mapActions, mapMutations } from "vuex";


export default {
  mixins: [router_model_utils, mixin_time_utils],
  components: {
    AssignToGroupDialog,
    EditADeviceDialog,
    DeviceDetailsDialog,
    RemoteCommandDialog,
    FilterButtonMenu,
    LoadingIcon,
    ResetDeviceConfigsDialog,
    DeviceConfigDialog,
    SuspendInternetDialog,
    SignalBar,
    SuspendInternetButton,
    ColumnButtonMenu,
  },
  data() {
    return {
      deviceConfigDialog: false,

      //DeviceDetailsDialog Component Variables
      deviceDetailsDialog: false,
      singleRouterInfo: {},

      //RemoteCommand Component Variables
      remoteCommandDialog: false,

      // Add to group variables
      assignToGroupDialog: false,
      selectedDevices: [],
      groupModel: "",

      //DeviceEdit Component Variables
      editDeviceDialog: false,
      editDeviceInfo: {},


      // ResetConfig Component Variables
      resetConfigDialog: false,

      suspendInternetDialog: false,

      search: "",

      filters: {
        status: "",
        model: "",
        group: "",
      },

      fetchedDevices: [],
      last_evaluated_key: {},
      selectedRecords: [],
      isTableLoading: false, // Flag for v-data-table loading

      tableHeaderFilterDialog: false,
      allHeaders: {
        Status: { text: "Status", align: "start", sortable: true, class: "headerColor white--text ", value: "online_status" },
        DeviceName: { text: "Device Name", sortable: true, class: "headerColor white--text ", value: "router_name" },
        Group: { text: "Group", sortable: true, class: "headerColor white--text ", value: "router_group" },
        Model: { text: "Model", align: "start", sortable: true, class: "headerColor white--text ", value: "router_model" },
        DeviceSN: { text: "Device SN", sortable: true, class: "headerColor white--text ", value: "router_id" },
        InternetAccess: { text: "Internet Access", sortable: true, class: "headerColor white--text ", value: "time_of_day" },
        ConfigUpdated: { text: "Config Updated", sortable: true, class: "headerColor white--text ", value: "config_updated_on" },
        LastReported: { text: "Last Reported", sortable: true, class: "headerColor white--text ", value: "last_reported_on" },
        DeviceVersion: { text: "Device Version", sortable: true, class: "headerColor white--text ", value: "firmware_version" },
        Carrier:{ text: "Carrier", sortable: true, class: "headerColor white--text ", value: "carrier_info" },
        Usage: { text: "Usage(GB)", sortable: true, class: "headerColor white--text ", value: "data_usage" },
        Description: { text: "Description", sortable: true, class: "headerColor white--text ", value: "router_description" }, 
        Signal: { text: "Signal", sortable: true, class: "headerColor white--text ", value: "signal" },
        Actions: { text: "Actions", class: "headerColor white--text ", readOnly: true, value: "Actions" },
      },
      headers: [],
    };
  },
  // --------------------------Created----------------------------------
  async created() {
    this.filters.model = this.$route.params.model ? this.$route.params.model : "";
    this.filters.status = this.$route.params.status ? this.$route.params.status : "";
    
    await this.handle_refresh_list();
  },

  // --------------------------Computed----------------------------------
  computed: {
    ...mapGetters(["getterGetUserInfo", "getterGetCurrentUserRole"]),
    getCurrentUserID() {
      return this.getterGetUserInfo.user.user_id;
    },
    getCurrentUserCustomerID() {
      return this.getterGetUserInfo.user.customer_id;
    },
    getCurrentUserRole() {
      return this.getterGetCurrentUserRole;
    },
    superAdminCheck() {
      return this.getterGetCurrentUserRole === "SU_ADMIN";
    },
    customerAdminCheck() {
      return this.getterGetCurrentUserRole === "C_ADMIN" || this.getterGetCurrentUserRole === "C_ADMIN_1";
    },
    supportAdminCheck() {
      return this.getterGetCurrentUserRole === "SP_ADMIN";
    },
    partnerAdminCheck() {
      return this.getterGetCurrentUserRole === "P_ADMIN";
    },
    filteredDeviceList() {
      return this.fetchedDevices.filter(device => {
        const isMatchingModel = this.filters.model ? device.router_model === this.filters.model : true;
        const isMatchingOrganization = this.filters.group ? device.router_group === this.filters.group : true;
        const isMatchingOnlineStatus = this.filters.status ? device.online_status === this.filters.status : true;

        return isMatchingModel && isMatchingOrganization && isMatchingOnlineStatus;
      });
    },
    modelOptions() {
      const modelNames = this.fetchedDevices.reduce((acc, device) => {
        acc[device.router_model] = true;
        return acc;
      }, {});
      return Object.keys(modelNames);
    },
    groupOptions() {
      const groupNames = this.fetchedDevices.reduce((acc, device) => {
        if (!device.router_group) return acc;
        acc[device.router_group] = true;
        return acc;
      }, {});
      return Object.keys(groupNames).sort((a, b) => a.toLowerCase().localeCompare(b.toLowerCase()));
    },
    partner() {
      return this.getterGetUserInfo.customer.partner_name;
    },
    selectedRecordsIsEmpty() {
      return this.selectedRecords.length === 0;
    }, 
    selectedRecordsModels() {
      const modelNames = this.selectedRecords.reduce((acc, curr) => {
        acc[curr.router_model] = true;
        return acc;
      }, {});
      return Object.keys(modelNames);
    },
    multipleModelsSelected() {
      return this.selectedRecordsModels.length > 1;
    },
  },

  // --------------------------Methods----------------------------------
  methods: { 
    ...mapActions(["actionGetSuperAdminDashBoardDetails", "actionGetCustomerAdminDashBoardDetails", "actionGetSingleDeviceInfo", "actionGetDeviceConfiguration"]),
    ...mapMutations(["mutationSetSnackBarItem", "mutationOpenProgressBar", "mutationCloseProgressBar"]),
    triggerSnackBar(type, text) {
      this.mutationSetSnackBarItem({ type, text });
    },
    async open_configs_dialog(item) {
      this.singleRouterInfo = item;
      this.mutationOpenProgressBar();
      try {
        await this.actionGetDeviceConfiguration(item.router_id);
      } catch (err) {
        this.triggerSnackBar("error", err.errors ? err.errors[0].message : err);
      } finally {
        this.deviceConfigDialog = true;
        this.mutationCloseProgressBar();
      }
    },
    close_configs_dialog() {
      this.deviceConfigDialog = false;
    },

    // ------------- Suspend Snternet ---------------------
    open_suspend_internet_dialog(item) {
      this.singleRouterInfo = item;
      this.suspendInternetDialog = true;
    },
    close_suspend_internet_dialog(val) {
      this.suspendInternetDialog = false;
    },

    // ------------- Methods used in this form only ---------------
    //query call to fetch the router list to store
    async fetchAllDevices() {
      this.isTableLoading = true;

      // Fetch only 10 devices for first query
      let queryObj = {
        user_role: this.getCurrentUserRole,
        count: 10,
      };

      this.partnerAdminCheck ? (queryObj.partner_id = this.getCurrentUserID) : (queryObj.customer_id = this.getCurrentUserCustomerID);

      try {
        // Note that `fetchedDevices` is different function from `this.fetchDevices`
        const fetchedDevices = await getDeviceList(queryObj);
        this.fetchedDevices = fetchedDevices.items;
        this.last_evaluated_key = fetchedDevices.last_evaluated_key;

        this.load_more_router_data(queryObj); // No await, perform in background
        this.mixinResetStartTime();
      } catch (err) {
        this.triggerSnackBar("error", err.errors ? err.errors[0].message : err);
      } finally {
        // this.isTableLoading = false;
      }
    },
    async load_more_router_data(queryObj) {
      queryObj.count = 100; // Fetch up to 100 devices per query after initial query
      while (this.last_evaluated_key) {
        queryObj.last_evaluated_key = this.last_evaluated_key;
        try {
          const fetchedDevices = await getDeviceList(queryObj);
          this.fetchedDevices = this.fetchedDevices.concat(fetchedDevices.items);
          this.last_evaluated_key = fetchedDevices.last_evaluated_key;
        } catch (err) {
          this.triggerSnackBar("error", err.errors ? err.errors[0].message : err);
        }
      }
      this.isTableLoading = false;
    },    
    async handle_refresh_list() {
      if (this.isTableLoading) {
        return; // Exit the method if refresh is already in progress
      }
      this.isTableLoading = true;
      this.fetchedDevices = [];
      
      await this.fetchAllDevices();   
    },

    // -------------- Group Related ------------------
    async open_assign_to_group_dialog(selectedRecords) {
      if (this.selectedRecordsModels.length !== 1) {
        this.triggerSnackBar("error", "Different device models cannot be added to the same group!");
        return;
      }
      selectedRecords.forEach((device) => {
        this.selectedDevices.push(device.router_id);
      });
      this.groupModel = selectedRecords[0].router_model;
      this.assignToGroupDialog = true;
    },
    close_assign_to_group_dialog(val) {
      this.assignToGroupDialog = false;
      this.selectedRecords = [];
      this.selectedDevices = [];
      if (val === 1) {
        setTimeout(() => {
          this.handle_refresh_list();
        }, 3000);
      }
    },

    // ------------------ Reset Router --------------------
    open_reset_config_dialog() {
      this.resetConfigDialog = true;
    },

    close_reset_config_dialog() {
      this.resetConfigDialog = false;
    },

    // ---------------- CSV related -------------------------
    // Another seemingly useless code that will never reach its condition
    //method to validate if not item is slected on export action
    export_Method() {
      if (this.selectedRecords.length == 0) {
        this.triggerSnackBar("error", "Please select at least one item");
      }
    },

    // --------------- Row Dialog Related ----------------
    async open_device_details_dialog(item) {
      this.mutationOpenProgressBar();
      try {
        this.singleRouterInfo = item;
        await requestQueryToRouter(item.router_id);
        await this.actionGetSingleDeviceInfo(item.router_id);
        await this.actionGetDeviceConfiguration(item.router_id);
        this.deviceDetailsDialog = true;
      } catch (err) {
        // Do not show any errors
      } finally {
        this.mutationCloseProgressBar();
      }
    },
    close_device_details_dialog() {
      this.deviceDetailsDialog = false;
    },

    // -------------- Device Edit related -------------------
    // opens the device edit dialog
    // "editDeviceInfo" = selected information data from <v-data-table>.
    // The "router_id" and "customer_id" are used to fetch an appropriate list of organization and filter it down.
    async open_edit_device_dialog(selectedDeviceInfo) {
      this.editDeviceInfo = selectedDeviceInfo;
      this.editDeviceDialog = true;
    },
    // method to validate the data that invoked from child component
    // "clickStatus" meaning:
    // 0 = The user just closed the dialog without any changes
    // 1 = The user closed the dialog after pressing save async request. Information on the local device list also has to change
    // in order to sync up with the changed data
    close_edit_device_dialog(editObj) {
      if (editObj.status === 0) {
        this.editDeviceDialog = false;
      } else if (editObj.status === 1) {
        this.editDeviceDialog = false;
        // search for edited device from router list and update information locally
        this.fetchedDevices.forEach((device) => {
          if (device.router_id === editObj.router_id) {
            device.router_activated = editObj.router_activated;
            if (!device.is_individual_router) {
              device.customer_id = editObj.customer_id;
              device.organization_name = editObj.organization_name;
            }
          }
        });
      }
    },

    // ----------- Remote Command --------------
    open_remote_command_dialog(item) {
      this.singleRouterInfo = item;
      this.remoteCommandDialog = true;
    },
    close_remote_command_dialog() {
      this.remoteCommandDialog = false;
    },

    // ------------------------- Misc ----------------------------
    toggleSelectAll(item) {
      if(item.value) {
        this.selectedRecords = this.filteredDeviceList;
      } else {
        this.selectedRecords = [];
      }
    }
  },
};
</script>

<style lang="scss" scoped>
// get rid of default table hover background coloring

.columns-btn {
  color: #0195d2;
}

.v-toolbar__content .search-bar {
  margin-top: 25px;
  width: 400px;
}
.columns-btn::before,
.columns-btn:hover,
.columns-btn:hover .column-icon {
  background-color: white;
  cursor: pointer;
  color: #0193d2d5;
  fill: #0193d2d5;
}

.menu-offset {
  transform: translateY(10px) translateX(-20px) !important;
}

.filter >>> fieldset {
  border-bottom: 1px solid;
  border-top: 0px;
  border-left: 0px;
  border-right: 0px;
  border-radius: 0px;
}
.filter >>> .v-label.theme--light {
  font-size: 16px;
  margin-top: -2px;
}

.filter >>> .v-select__selection.v-select__selection--comma {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-right: 0px;
}
</style>
<style scoped>

.tooltip-adjust {
  position: absolute;
  top: -1.2em;
  left: -2.2em;
  background-color: #202020;
  color: #fff;
  padding: 8px;
  border-radius: 4px;
  width: 72px;
  display: flex;
  align-items: center;
  height: 30px;
}

.v-toolbar__title {
  text-overflow: clip;
  overflow: visible;
}

.container-wrapper {
  display: inline-flex;
}

.filter {
  max-width: 130px;
  width: 130px;
}

.search-bar {
  width: 400px;
  margin-top: 80px;
}

.v-data-table.v-data-table--has-bottom >>> .v-data-footer,
.v-data-table.v-data-table--has-bottom >>> .v-data-footer .v-data-footer__select {
  height: 45px;
}

.v-application .caption.green--text.text--darken-3 {
  color: #2e7d32 !important;
  caret-color: #2e7d32 !important;
}

.container-wrapper > .v-text-field > .v-input__control > .v-input__slot::before {
  border-color: #0195d2 !important;
  color: #0195d2 !important;
}
.filter-font {
  font-size: 14px;
}
.search-bar >>> .v-input__slot::before,
.container-wrapper .search-bar >>> .v-input__slot {
  border-color: #0195d2 !important;
  color: #0195d2 !important;
}
.search-bar >>> .mdi-close {
  color: grey !important;
}
.container-wrapper .search-bar >>> .v-input__slot label,
.container-wrapper .search-bar >>> .v-input__slot input {
  margin-bottom: 4px;
}
</style>

<style scoped> 
.device-table >>> .device-row {
  cursor: pointer;
}
</style>
