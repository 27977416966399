<template>
  <v-card flat class="parent-card" :style="middleTextVar">
    <v-card-title>{{ title }}</v-card-title>
    <v-card-text >
      <DoughnutChart ref="chart" class="card-content" />
      <div class="legend-container">
        <div class="inline-container" v-for="(item, index) in chartData.labels" :key="title+index">
          <div class="legend-item" v-if="shouldDisplayNoTooltip(item)">
            <div class="legend-color" :style="{ backgroundColor: chartData.datasets[0].backgroundColor[index] }"></div>
            <div class="legend-label">{{ item }}</div>
          </div>
          <v-tooltip v-else bottom>
            <template v-slot:activator="{ on, attrs }">
              <div class="legend-item" v-bind="attrs" v-on="on">
                <div class="legend-color" :style="{ backgroundColor: chartData.datasets[0].backgroundColor[index] }"></div>
                <div class="legend-label">{{ item }}</div>
              </div>
            </template>
            <span>{{ unknownText }}</span>
          </v-tooltip>
        </div>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import DoughnutChart from "@/components/Charts/DoughnutChart.js";

export default {
  name: "DoughnutChartCard",
  props: {
    title: {
      type: String,
      default: ""
    },
    countObject: {
      type: Object,
      required: true
    },
    middleText: {
      type: String,
      default: ""
    },
    unknownText: {
      type: String,
      default: ""
    },
  },
  components: {
    DoughnutChart
  },
  data() {
    return {
      chartOptions: {
        responsive: true,
        maintainAspectRatio: true,
        legend: {
          display: false,
        },
        cutoutPercentage: 70,
      },
    };
  },
  computed: {
    chartData: function () {
      const countCopy = structuredClone(this.countObject);
      if (countCopy["Total"] !== undefined) delete countCopy["Total"];
      if (countCopy["Unknown"] !== undefined && countCopy["Unknown"] === 0) delete countCopy["Unknown"];
      return {
        labels: Object.keys(countCopy),
        datasets: [
          {
            // Replace this array of colors with one matching our color pallette
            backgroundColor: ["#598FF9", "#9BBCFB", "#6C61EA", "#B5AFF4", "#D71C7A", "#E777AF", "#FA5918", "#FC9B74", "#FCAB2E", "#FDD496"],
            data: Object.values(countCopy),
          },
        ],
      };
    },
    middleTextVar() { 
      return `--middle-text: '${this.middleText}'`;
    }
  },
  methods: {
    shouldDisplayNoTooltip(category) {
      return category !== "Unknown" || this.unknownText === "";
    },
  },
  watch: {
    chartData: function () {
      this.$refs.chart.renderChart(this.chartData, this.chartOptions);  // When input data changed, re-render the chart
    }
  },
};
</script>

<style scoped>
.card-content {
  position: relative;
  width: 8rem;
  height: auto;
  margin-left: auto;
  margin-right: auto;
  padding-bottom: 0rem;
}

.card-content::after {
  content: var(--middle-text);
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 50%;
  width: 8rem;
  margin-left: -4rem;
  height: fit-content;
  text-align: center;
  font-weight: bold;
  font-size: 1.1rem;
}

.parent-card {
  width: 22%;
  min-width: fit-content;
  border: solid 1px #E5E5EA;
  margin-left: 0.25rem;
  margin-right: 0.25rem;
}

.legend-container {
  overflow: auto;
  width: 14rem;
  margin-left: auto;
  margin-right: auto;
  padding-top: 1rem;
  text-align: center;
}

.inline-container {  
  display: inline-block;
}

.legend-item {
  display: flex;
  align-items: center;
  width: fit-content;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

.legend-color {
  width: 1rem;
  height: 1rem;
  margin-right: 0.25rem;
  border-radius: 0.5rem;
}

.legend-label {
  font-size: 0.75rem;
  width: fit-content;
}

</style>