<template>
  <v-dialog overflow-hidden v-model="suspendInternetDialog" persistent max-width="400px">
    <DialogCard v-if="suspendInternetDialog" :modalIcon="connectionAllowed ? 'lan-disconnect' : 'lan-connect'" :modalTitle="connectionAllowed ? 'Suspend Internet' : 'Resume Internet'">
      <template #header>
        <v-btn :disabled="loading" icon x-small @click="close_suspend_internet_dialog()">
          <v-icon color="white">mdi-close</v-icon>
        </v-btn>
      </template>

      <template #body>
        <p>Would you like to {{ connectionAllowed ? "suspend" : "resume" }} internet access?</p>
        <p v-if="connectionAllowed" class="mt-2 font-italic text-caption grey--text">Note: If you suspend internet access, the device will only be able to connect to the internet between 02:00 AM and 03:00 AM. To adjust this time frame, please go to <span class="font-weight-bold">Configuration > TOD Access.</span></p>
      </template>

      <template #footer>
        <v-btn :disabled="loading" small class="blue--text" outlined @click="close_suspend_internet_dialog()">Cancel</v-btn>
        <v-btn :loading="loading" small depressed :color="connectionAllowed ? 'red' : 'blue'" @click="confirm_suspend_dialog()" class="tabledata--text mr-2">{{ connectionAllowed ? "Suspend" : "Resume" }}</v-btn>
        
      </template>
    </DialogCard>
  </v-dialog>
</template>

<script>
import DialogCard from "@/components/Slots/DialogCard.vue";
import { mapGetters, mapActions, mapMutations } from "vuex";

const suspensionScheduleList = {
  ScheduleList: [
    {
      Enable: 1,
      StartTime: 2,
      EndTime: 3,
      DayOfWeek: "All",
      Index: 0,
    },
  ],
}
const allowedScheduleList = {
  ScheduleList: [],
}

export default {
  components: {
    DialogCard,
  },
  props: {
    suspendInternetDialog: Boolean,
    singleRouterInfo: Object,
  },
  data(){
    return {
      loading: false
    }
  },
  computed: {
    ...mapGetters(["connectionAllowed", "getterGetDeviceConfigurations"]),
    TODConfig() {
      return {
        record_type: "TimeOfDayAccess",
        configuration_value: {
          payload_type: "Structure",
          payload_value:  this.connectionAllowed ? JSON.stringify(suspensionScheduleList) : JSON.stringify(allowedScheduleList)
        }
      }
    },
    connectionAllowed(){
      let isConnectionOn = true;
      if(this.singleRouterInfo.time_of_day.ScheduleList.length === 1) 
        isConnectionOn = !(this.singleRouterInfo.time_of_day.ScheduleList[0].Enable && this.singleRouterInfo.time_of_day.ScheduleList[0].StartTime === 2 && this.singleRouterInfo.time_of_day.ScheduleList[0].EndTime === 3);
      return isConnectionOn;
    },
  },
  methods: {
    ...mapActions(["actionSetDeviceConfiguration", "actionGetDeviceConfiguration"]),
    ...mapMutations(["mutationSetSnackBarItem"]),
    triggerSnackBar(type, text) {
      this.mutationSetSnackBarItem({ type, text });
    },
    close_suspend_internet_dialog() {
      this.$emit("close", 0);
    },
    async confirm_suspend_dialog() {
      try {
        this.loading = true;

        // Upload the new configuration and then immediately fetch the latest configuration from the server
        await this.actionSetDeviceConfiguration({ router_id: this.singleRouterInfo.router_id, configuration_module: this.TODConfig });
        await this.actionGetDeviceConfiguration(this.singleRouterInfo.router_id);

        // Compare the new configuration with the server's configuration
        const serverTODScheduleList = this.getterGetDeviceConfigurations.configuration_module.TimeOfDayAccess.Value.ScheduleList;
        const newTODScheduleList = (this.connectionAllowed ? suspensionScheduleList : allowedScheduleList).ScheduleList;
        console.assert(serverTODScheduleList.length === newTODScheduleList.length, "Configuration Failed to Apply, Please Try Again");
        
        if (newTODScheduleList.length) {
          const newObj = newTODScheduleList[0];
          const serverObj = serverTODScheduleList[0];
          console.assert(newObj.Enable === serverObj.Enable, "Configuration Failed to Apply, Please Try Again");
          console.assert(newObj.StartTime === serverObj.StartTime, "Configuration Failed to Apply, Please Try Again");
          console.assert(newObj.EndTime === serverObj.EndTime, "Configuration Failed to Apply, Please Try Again");
          console.assert(newObj.DayOfWeek === serverObj.DayOfWeek, "Configuration Failed to Apply, Please Try Again");
          console.assert(newObj.Index === serverObj.Index, "Configuration Failed to Apply, Please Try Again");
        }

        // If the configurations match, the upload was successful and singleRouterInfo can be updated
        this.singleRouterInfo.time_of_day.ScheduleList = this.connectionAllowed ? suspensionScheduleList.ScheduleList : allowedScheduleList.ScheduleList;
        this.triggerSnackBar("success", "Changed internet access.");
        this.$emit("close", 1);
      } catch (err) {
        this.triggerSnackBar("error", err.errors ? err.errors[0].message : err);
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
