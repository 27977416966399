<template>
  <div style="display: grid; height: 100%;">
    <v-card-text class="pa-6">
      <v-form ref="form">
        <div style="display: grid; grid-template-columns: 1fr 1fr; column-gap: 1rem;">
          <v-text-field outlined dense label="First Name*" v-model="firstName" maxlength="40" :disabled="isLoading" :rules="mixinPersonNameRule('first')" @keyup.enter="next()" />
          <v-text-field outlined dense label="Last Name*" v-model="lastName" maxlength="40" :disabled="isLoading" :rules="mixinPersonNameRule('last')" @keyup.enter="next()" />

          <v-text-field outlined dense label="Email*" v-model="signUpEmail" :disabled="isLoading" :rules="mixinLoginEmailRule()" @keyup.enter="next()" />
          <v-select outlined dense required label="Country Code*" v-model="countryCode" :items="countryList" :disabled="isLoading" />

          <v-text-field outlined dense label="Enter New Password" v-model="signUpPassword" :append-icon="isPassword ? 'mdi-eye-off' : 'mdi-eye'" :type="isPassword ? 'password' : 'text'" @keydown.space="prevent_leading_space" @click:append="isPassword = !isPassword" :disabled="isLoading" :rules="mixinLoginPasswordRule()" @keyup.enter="next()" />
          <v-text-field outlined dense label="Confirm Password" v-model="confirmNewPassword" :append-icon="isPassword ? 'mdi-eye-off' : 'mdi-eye'" :type="isPassword ? 'password' : 'text'" @click:append="isPassword = !isPassword" @keydown.space="prevent_leading_space" :disabled="isLoading" :rules="confirmNewPasswordRule()" @keyup.enter="next()" />
        </div>
      </v-form>
    </v-card-text>
    <v-card-actions class="pa-6">
      <v-btn @click="cancel_sign_up()" outlined class="blue--text">Cancel</v-btn>
      <v-spacer></v-spacer>
      <v-btn class="button tabledata--text mr-2" @click="back()">Back</v-btn>
      <v-btn class="button tabledata--text" :loading="isLoading" @click="next()">Next</v-btn>
    </v-card-actions>
  </div>
</template>

<script>
import axios from 'axios';
import { mixin_routing_utils } from "@/mixins/routingUtils.js";
import { mixin_form_validation_utils } from "@/mixins/formValidationUtils.js";
import { mapGetters, mapMutations } from "vuex";

export default {
  components: {},
  mixins: [mixin_routing_utils, mixin_form_validation_utils],
  data() {
    return {
      isLoading: false,
      isPassword: true,
      confirmNewPassword: "",
      countryList: ["USA"]
    };
  },
  computed: {
    ...mapGetters(["getterGetSignUpWindowPage", "getterGetFirstName", "getterGetLastName", "getterGetSignUpEmail", "getterGetCountryCode", "getterGetSignUpPassword"]),
    windowPage: {
      get() {
        return this.getterGetSignUpWindowPage;
      },
      set(value) {
        this.mutationSetSignUpWindowPage(value);
      },
    },
    firstName: {
      get() {
        return this.getterGetFirstName;
      },
      set(value) {
        this.mutationSetFirstName(value);
      },
    },
    lastName: {
      get() {
        return this.getterGetLastName;
      },
      set(value) {
        this.mutationSetLastName(value);
      },
    },
    // Note that cognito backend is not capable of recognizing captial letters and have to force lowercase letters
    signUpEmail: {
      get() {
        return this.getterGetSignUpEmail;
      },
      set(value) {
        this.mutationSetSignUpEmail(value);
      },
    },
    countryCode: {
      get() {
        return this.getterGetCountryCode;
      },
      set(value) {
        this.mutationSetCountryCode(value);
      },
    },
    signUpPassword: {
      get() {
        return this.getterGetSignUpPassword;
      },
      set(value) {
        this.mutationSetSignUpPassword(value);
      },
    }
  },
  methods: {
    ...mapMutations(["mutationSetSnackBarItem", "mutationSetSignUpWindowPage", "mutationSetFirstName", "mutationSetLastName", "mutationSetSignUpEmail", "mutationSetCountryCode", "mutationSetSignUpPassword"]),
    triggerSnackBar(type, text) {
      this.mutationSetSnackBarItem({ type, text });
    },
    back(){
      this.windowPage = 1;
    },
    async next() {
      if(!this.$refs.form.validate()) return this.triggerSnackBar("error", "Fill in the mandatory fields.");
      const data = {
        command: "requestingOtpForTheVerfication",
        user_email_id: this.signUpEmail.toLowerCase(),
        user_country_code: this.countryCode,
      }
      this.isLoading= true;
      try {
        const response = await axios({ method: "POST", url: process.env.VUE_APP_MDM_ROUTER_API + "/requesting_otp_for_the_verfication", headers: { "Content-Type": "application/json" }, data });
        if(response.data.errorType === "Error") throw new Error(response.data.errorMessage);
        this.triggerSnackBar("success", `OTP has been delivered to ${this.userEmail}`);
        this.windowPage = 3;
      } catch(error) {
        this.triggerSnackBar("error", error);
      } finally {
        this.isLoading = false;
      }
    },
    // Note that the sign up form reset action will be performed in router gaurd
    // Whenever the user returns from /SignUp page, the router guard will automatically reset the signup vuex states
    cancel_sign_up() {
      this.mixinChangeRoute("/");
    },
    confirmNewPasswordRule() {
      return [
        ...this.mixinLoginPasswordRule(),
        (value) => value === this.signUpPassword || "The passwords must match"
      ]
    },
  },
};
</script>

<style scoped>
</style>
