<template>
  <v-overlay v-model="getterGetCircularProgressState" z-index="9999">
    <v-progress-circular indeterminate size="64"></v-progress-circular>
  </v-overlay>
</template>
<script>
import { mapGetters } from 'vuex';

export default {
  computed: {
    ...mapGetters(["getterGetCircularProgressState"])
  }
};
</script>