import { Auth } from "aws-amplify";
import axios from "axios";
import { encode } from "@/utils";

// AWS dependent
export const signIn = async (username, password) => {
  try {
    const response = await Auth.signIn(username, password);
    return response;
  } catch (error) {
    console.error("Error logging in", error);
    throw error;
  }
};

export const currentSession = async () => {
  try {
    const response = await Auth.currentSession();
    return response;
  } catch (error) {
    console.error("Error fetching login info", error);
    throw error;
  }
};

export const currentAuthenticatedUser = async () => {
  try {
    const response = await Auth.currentAuthenticatedUser();
    return response;
  } catch (error) {
    console.error("Error fetching login info", error);
    throw error;
  }
};

export const confirmSignIn = async (loginInfo, mfaCode) => {
  try {
    const response = await Auth.confirmSignIn(loginInfo, mfaCode, "SOFTWARE_TOKEN_MFA");
    return response;
  } catch (error) {
    console.error("Error resetting password", error);
    throw error;
  }
};


export const forgotPassword = async (username) => {
  try {
    const response = await Auth.forgotPassword(username);
    return response;
  } catch (error) {
    console.error("Error resetting password", error);
    throw error;
  }
};

export const forgotPasswordSubmit = async (username, activationCode, password) => {
  try {
    const response = await Auth.forgotPasswordSubmit(username, activationCode, password);
    return response;
  } catch (error) {
    console.error("Error submitting reset password", error);
    throw error;
  }
};

export const completeNewPassword = async (loginInfo, newPassword) => {
  try {
    const response = await Auth.completeNewPassword(loginInfo, newPassword);
    return response;
  } catch (error) {
    console.error("Error submitting reset password", error);
    throw error;
  }
};

// Pintrac User Check
const pintracUsers = axios.create({ baseURL: 'https://dzbbixgky9.execute-api.us-east-1.amazonaws.com/Stage/pintrac/users'});
const PINTRAC_LOGIN_URL = "http://staging-pintrac.s3-website-us-east-1.amazonaws.com/login/";

export const checkPintracUser = async (userId, idToken) => {
  try {
    const {data: result} = await pintracUsers.get(`/${userId}`, { headers: { 'Authorization': `Bearer ${idToken}` }});
    return result;
  } catch (error) {
    console.error("Error checking Pintrac user", error);
    throw error;
  }
};

export const loginToPintrac = async (userId, idToken) => {
  try {
    const {data: result} = await pintracUsers.get(`/${userId}/token`, { headers: { 'Authorization': `Bearer ${idToken}` }});
    if(result.code === 0) {
    window.open(`${PINTRAC_LOGIN_URL}?token=${encode(result.data.token)}`, '_blank');
    } else {
      throw new Error("Something went wrong");
    }
    return result;
  } catch (error) {
    console.error("Error checking Pintrac user", error);
    throw error;
  }
};

