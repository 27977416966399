import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VSubheader } from 'vuetify/lib/components/VSubheader';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.informationCheck == 0)?_c('div',[_c('SnackBar',{attrs:{"SnackBarComponent":_vm.SnackBarComponent}}),_c(VSubheader,{staticClass:"justify-center text-h5"},[_vm._v("Account Settings")]),_c(VCard,{staticClass:"mt-3",attrs:{"flat":""}},[_c(VRow,{attrs:{"justify":"start","no-gutters":""}},[_c(VCol,{attrs:{"cols":"4","sm":"12","xs":"12","md":"6"}},[_c(VSelect,{attrs:{"label":"Time Zone*","dense":"","items":_vm.timeZoneArray,"menu-props":{ bottom: true, offsetY: true },"outlined":"","required":""},model:{value:(_vm.timeZonesDisplay),callback:function ($$v) {_vm.timeZonesDisplay=$$v},expression:"timeZonesDisplay"}})],1),_c(VCol,{attrs:{"cols":"4","sm":"12","xs":"12","md":"6"}},[_c(VSelect,{staticClass:"ml-2",attrs:{"dense":"","item-text":"text","item-value":"value","items":[
              { text: '5 minutes', value: 300 },
              { text: '10 minutes', value: 600 },
              { text: '1 hour', value: 3600 },
            ],"label":"User Inactive Time","outlined":""},model:{value:(_vm.userInterval),callback:function ($$v) {_vm.userInterval=$$v},expression:"userInterval"}})],1)],1),_c(VRow,{attrs:{"justify":"start","no-gutters":""}},[_c(VCol,{attrs:{"cols":"4","sm":"12","xs":"12","md":"6"}},[_c(VSelect,{attrs:{"dense":"","label":"Email Alerts","items":['ENABLED', 'DISABLED'],"outlined":""},model:{value:(_vm.emailAlerts),callback:function ($$v) {_vm.emailAlerts=$$v},expression:"emailAlerts"}})],1)],1),_c(VCardActions,{staticClass:"justify-end"},[_c(VBtn,{staticClass:"button tabledata--text",attrs:{"depressed":"","loading":_vm.loading},on:{"click":function($event){return _vm.update_success()}}},[_vm._v(" Update ")])],1)],1)],1):_vm._e(),(_vm.informationCheck == 1)?_c('div',[_c(VLayout,{attrs:{"justify-center":""}},[_c(VCard,{staticClass:"justify-center mt-4",staticStyle:{"margin-left":"250px"},attrs:{"flat":""}},[_c('div',{staticClass:"dashboard-router ml-16"},[_vm._v("Account Settings Updated Succesfully")]),_c(VCardActions,{staticClass:"justify-center"},[_c(VBtn,{staticClass:"button tabledata--text mt-4",attrs:{"small":"","depressed":""},on:{"click":function($event){return _vm.completion()}}},[_vm._v(" Ok ")])],1)],1)],1)],1):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }