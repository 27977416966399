<template>
  <v-container>
    <h1>Page Not Found</h1>
    <p>The page you've requested does not exist</p>
    <v-btn class="blue--text" depressed color="transparent" href="/Home">Go Back</v-btn>
  </v-container>
</template>

<script>
export default {};
</script>
