import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c(VDialog,{attrs:{"persistent":"","overflow-hidden":"","max-width":"400px"},model:{value:(_vm.toggleOrgDialog),callback:function ($$v) {_vm.toggleOrgDialog=$$v},expression:"toggleOrgDialog"}},[_c(VCard,{staticClass:"dialogCard cardBg overflow-hidden"},[_c(VToolbar,{staticClass:"dialogToolbar cardBg",attrs:{"dense":""}},[_c(VToolbarTitle,[_vm._v(_vm._s(_vm.itemCheck == 1 ? "Deactivate Organization" : "Activate Organization"))]),_c(VSpacer),_c(VIcon,{staticClass:"ml-2",attrs:{"color":"iconCross"},on:{"click":function($event){return _vm.close_toggle()}}},[_vm._v("mdi-close")])],1),(_vm.itemCheck == 1)?_c(VCardText,{staticClass:"cardBg pt-6 justify-center"},[_vm._v(" Are you sure you want to deactivate "),_c('b',[_vm._v(" "+_vm._s(_vm.toggleItem.organization_name))]),_vm._v(" ? ")]):_vm._e(),(_vm.itemCheck == 2)?_c(VCardText,{staticClass:"cardBg pt-6 justify-center"},[_vm._v(" Are you sure you want to activate "),_c('b',[_vm._v(" "+_vm._s(_vm.toggleItem.organization_name))]),_vm._v(" ? ")]):_vm._e(),_c(VCardActions,{staticClass:"button-corner"},[_c(VSpacer),_c(VBtn,{staticClass:"blue--text",attrs:{"color":"button","outlined":""},on:{"click":function($event){return _vm.close_toggle()}}},[_vm._v("Cancel")]),(_vm.itemCheck == 1)?_c(VBtn,{staticClass:"button tabledata--text",attrs:{"loading":_vm.loadingDelete},on:{"click":function($event){return _vm.delete_func()}}},[_vm._v("Deactivate")]):_vm._e(),(_vm.itemCheck == 2)?_c(VBtn,{staticClass:"button tabledata--text",attrs:{"loading":_vm.loadingDelete},on:{"click":function($event){return _vm.delete_func()}}},[_vm._v("Activate")]):_vm._e()],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }