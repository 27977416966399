<template>
  <div style="display: grid; height: 100%;">
    <v-card-text class="pa-6">
      <v-select label="Select Model" v-model="selectedModel" :items="mixinModelList()" outlined required dense />
      <div style="display: grid; grid-template-columns: 1fr 1fr 2fr">
        <v-text-field dense class="remove-border" label="model" v-model="selectedModel.model" outlined readonly />
        <v-text-field dense class="remove-border" v-model="selectedModel.TAC" outlined readonly />
        <v-text-field outlined :disabled="isLoading" class="ml-4" maxlength="7" label="Device Serial Number*" dense v-model="deviceSerialNumber" @keyup.enter="does_router_exist()" @keypress="mixinNumberOnlyKeyPress($event)" @paste="mixinNumberOnlyPaste($event)" />
      </div>
    </v-card-text>
    <v-card-actions class="pa-6">
      <v-btn @click="cancel_sign_up()" outlined class="blue--text justify-end">Cancel</v-btn>
      <v-spacer></v-spacer>
      <v-btn :loading="isLoading" @click="does_router_exist()" class="button tabledata--text">Verify</v-btn>
    </v-card-actions>
  </div>
</template>

<script>
import axios from "axios";
import { mixin_routing_utils } from "@/mixins/routingUtils.js";
import { mixin_form_validation_utils } from "@/mixins/formValidationUtils.js";
import { mixin_list_table_utils } from "@/mixins/listTableUtils.js"
import { mapGetters, mapMutations } from "vuex";

export default {
  components: {},
  mixins: [mixin_routing_utils, mixin_form_validation_utils, mixin_list_table_utils],
  data() {
    return {
      isLoading: false,
    };
  },
  computed: {
    ...mapGetters(["getterGetSignUpWindowPage", "getterGetSelectedModel", "getterGetDeviceSerialNumber"]),
    windowPage: {
      get() {
        return this.getterGetSignUpWindowPage;
      },
      set(value) {
        this.mutationSetSignUpWindowPage(value);
      },
    },
    selectedModel: {
      get() {
        return this.getterGetSelectedModel;
      },
      set(value) {
        this.mutationSetSelectedModel(value);
      },
    },
    deviceSerialNumber: {
      get() {
        return this.getterGetDeviceSerialNumber
      },
      set(value) {
        this.mutationSetDeviceSerialNumber(value);
      },
    }
  },
  methods: {
    ...mapMutations(["mutationSetSnackBarItem", "mutationSetSignUpWindowPage", "mutationSetSelectedModel", "mutationSetDeviceSerialNumber"]),
    triggerSnackBar(type, text) {
      this.mutationSetSnackBarItem({ type, text });
    },
    async does_router_exist() {
      if (!this.deviceSerialNumber) return this.triggerSnackBar("error", "Please enter a serial number");
      if (this.deviceSerialNumber.length !== 7) return this.triggerSnackBar("error", "Serial number must be 7 digits long");
      if (!this.isValidIMEI(this.selectedModel.TAC + this.deviceSerialNumber)) return this.triggerSnackBar("error", "Serial number number is invalid");
      let inputData = {
        command: "doesRouterExist",
        router_id: this.selectedModel.model + "/" + this.selectedModel.TAC + this.deviceSerialNumber,
      };
      this.isLoading = true;
      try {
        const response = await axios({ method: "POST", url: process.env.VUE_APP_MDM_ROUTER_API + "/does_router_exist", headers: { "Content-Type": "application/json" }, data: inputData });
        if(response.data.errorType === "Error") throw new Error(response.data.errorMessage);
        this.windowPage = 1;
      } catch(error) {
        this.triggerSnackBar("error", error);
      } finally {
        this.isLoading = false;
      }
    },
    // Note that the sign up form reset action will be performed in router gaurd
    // Whenever the user returns from /SignUp page, the router guard will automatically reset the signup vuex states
    cancel_sign_up() {
      this.mixinChangeRoute("/");
    },
    sumDig(n) {
      let a = 0;
      while (n > 0) {
        a = a + (n % 10);
        n = parseInt(n / 10, 10);
      }
      return a;
    },

    isValidIMEI(n) {
      // Converting the number into
      // String for finding length
      let s = n.toString();
      let len = s.length;
      if (len != 15) return false;
      let sum = 0;
      for (let i = len; i >= 1; i--) {
        let d = n % 10;

        // Doubling every alternate digit
        if (i % 2 == 0) d = 2 * d;

        // Finding sum of the digits
        sum += this.sumDig(d);
        n = parseInt(n / 10, 10);
      }
      return sum % 10 == 0;
    },
  },
};
</script>

<style scoped>
.v-input.remove-border >>> fieldset {
  border: none;
  border-width: 0px;
}
</style>
