<template>
  <div>
    <v-dialog v-model="subscriptionDialog" persistent max-width="400">
      <v-card>
        <v-card-text class="justify-center pt-3">
          <h3 class="font-weight-black mb-3 black--text">Unsubscribe</h3>
          <p class="content-font font-weight-medium">Are you sure you’d like to stop using the Jextream MDM service? Unsubscribing will permanently remove your account and license information. To use JEXtream MDM again, you will need a new license.</p>
        </v-card-text>
        <v-card-actions class="justify-end">
          <v-btn small class="button tabledata--text" @click="close_subscription_dialog()">Cancel</v-btn>
          <v-btn small class="button tabledata--text" :loading="isDeletingUser || isFetchingUserDevices" @click="submit_unsubscribe()">Agree and Continue</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { getSingleUserDeviceList, deleteUserPermanently } from "@/services";
import { mapMutations, mapGetters } from "vuex";
export default {
  props: {
    subscriptionDialog: Boolean,
  },
  data() {
    return {
      isDeletingUser: false,
      isFetchingUserDevices: false,
      fetchedDevices: []
    };
  },
  watch: {
    subscriptionDialog: {
      async handler(newVal) {
        if(newVal) {
          try {
            this.isFetchingUserDevices = true;
            this.fetchedDevices = await getSingleUserDeviceList(this.getCurrentUserID);
          } catch(err) {
            this.fetchedDevices = [];
          } finally {
            this.isFetchingUserDevices = false;
          }
        } else {
          this.fetchedDevices = [];
        }
      },
      immediate: true
    }
  },
  computed: {
    ...mapGetters(["getterGetUserInfo"]),
    getCurrentUserID() {
      return this.getterGetUserInfo.user.user_id;
    },
  },
  methods: {
    ...mapMutations(["mutationSetSnackBarItem"]),
    triggerSnackBar(type, text) {
      this.mutationSetSnackBarItem({ type, text });
    },
    close_subscription_dialog() {
      this.$emit("close", 0);
    },

    async submit_unsubscribe() {
      if(this.fetchedDevices.length > 0) {
        this.triggerSnackBar("error", "Make sure that your devices are deleted before unsubscribing");
        return;
      }
      try {
        this.isDeletingUser = true;
        const response = deleteUserPermanently(this.getCurrentUserID);
        this.triggerSnackBar("success", "The user ID has been deleted successfully");
        this.$emit("close", 1);
      } catch (err) {
        this.triggerSnackBar("error", err.errors ? err.errors[0].message : err);
      } finally {
        this.isDeletingUser = false;
      }
    },
  },
};
</script>

<style scoped>
  .content-font {
    font-size: 0.85rem;
    font-weight: 400 !important;
  }
</style>