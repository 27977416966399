<template>
  <div class="dashboard-container">
    <ExpirationCard :cancelledSubs="cancelledSubs" :expiredSubs="expiredSubs"/>
    <div class="card-container">
      <DoughnutChartCard title="Device Models" :countObject="modelCountObject" :middleText="modelCountObject['Total'] + ' Devices'" />
      <DoughnutChartCard title="Connection Status" :countObject="onlineCountObject" :middleText="onlineCountObject['Online'] + ' Online'" />
      <DoughnutChartCard title="Technology" :countObject="technologies" unknownText="Power cycle your device to begin tracking connection technology" />
      <SubscriptionInfoCard :subscriptions="subscriptions" />
    </div>
  </div>
</template>

<script>
import DoughnutChartCard from "@/components/Dashboard/DoughnutChartCard.vue";
import SubscriptionInfoCard from "@/components/Dashboard/SubscriptionInfoCard.vue";
import ExpirationCard from "@/components/Dashboard/ExpirationCard.vue";

import { router_model_utils } from "@/mixins/routerModelUtils.js";

export default {
  name: "IndividualDashboard",
  mixins: [router_model_utils],
  props: {
    fetchedDevices: {
      type: Array,
      required: true
    },
  },
  components: {
    DoughnutChartCard,
    SubscriptionInfoCard,
    ExpirationCard
  },
  computed: {
    modelCountObject() {
      return this.fetchedDevices.reduce((acc, curr) => {
        acc[curr.router_model] = (acc[curr.router_model] || 0) + 1;
        acc["Total"] = acc["Total"] + 1;
        return acc;
      }, {"Total": 0});
    },
    onlineCountObject() {
      return this.fetchedDevices.reduce((acc, curr) => {
        if (curr.online_status == "ONLINE") {
          acc.Online += 1;
        } else {
          acc.Offline += 1;
        }
        acc.Total += 1;
        return acc;
      }, {
        Online: 0,
        Offline: 0,
        Total: 0,
      });
    },
    subscriptions() {
      return this.fetchedDevices.map((device) => {
        return {
          device_name: device.router_name,
          imei: device.imei_number,
          subscription_type: device.license_type.toUpperCase(), 
          subscription_status: device.license_status.toUpperCase(),
          expire_date: device.expire_date.substring(0, device.expire_date.indexOf("T")),
        };
      });
    },
    technologies() {
      return this.fetchedDevices.reduce((acc, curr) => {
        const techIndex = curr.technology_type
        if (techIndex == undefined) {
          acc["Unknown"] += 1;
        } else {
          acc[this.convert_connectedStatus(techIndex)] = (acc[this.convert_connectedStatus(techIndex)] || 0) + 1;
        }

        acc["Total"] += 1;
        return acc;
      }, {
        "Unknown": 0,
        "Total": 0,
      });
    }, 
    cancelledSubs() {
      return this.fetchedDevices.filter((device) => {
        return device.license_status.toUpperCase() === "CANCELLED";
      });
    },
    expiredSubs() {
      return this.fetchedDevices.filter((device) => {
        return device.license_status.toUpperCase() === "EXPIRED";
      });
    },
  },
};
</script>

<style scoped>
.dashboard-container {
  display: flex;
  flex-direction: column;
}

.card-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-bottom: 1rem;
  padding-top: 1rem;
  scrollbar-width: auto;
  max-width: 100%;
  overflow-x: auto;
  margin-bottom: 0.25rem;
  margin-left: 0.75rem;
  margin-right: 0.75rem;
}
</style>