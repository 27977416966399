import Vue from 'vue'
import Vuex from 'vuex'
import VuexPersist from 'vuex-persist';

import ads from './module/ads'
import snackbar from './module/snackbar';
import overlay from './module/overlay'

import login from './module/login';
import signup from './module/signup';
import user from './module/user'
import dashboard from './module/dashboard';
import device from './module/device'
import validation from './module/validation';
import mfa from './module/mfa';
import help from './module/help';
import formErrors from './module/formErrors';
import stripe from './module/stripe';
import reminder from './module/reminder';

const vuexPersist = new VuexPersist({
  key: 'jextream-app',
  storage: window.localStorage,
  modules: ['ads', 'mfa', 'user', 'reminder']
});

Vue.use(Vuex)
export default new Vuex.Store({
  plugins: [vuexPersist.plugin],
  state: {
    pAlerts: [],
    pAlertsConnection: [],
  },
  // Both getters and setters here might become useless
  getters: {
    GetAlerts: state => {
      return state.pAlerts;
    },
    GetAlertsConnection: state => {
      return state.pAlertsConnection;
    },
  },
  mutations: {
    SetAlerts(state, alertItem) {
      state.pAlerts.push(alertItem);
    },
    SetAlertsConnection(state, alertItem) {
      state.pAlertsConnection.push(alertItem);
    },
  },
  modules: {
    ads,
    snackbar,
    overlay,
    login,
    signup,
    user,
    dashboard,
    device,
    validation,
    mfa,
    help,
    formErrors,
    stripe, 
    reminder
  }
})