import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VForm } from 'vuetify/lib/components/VForm';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"display":"grid","height":"100%"}},[_c(VCardText,{staticClass:"pa-6"},[_c(VForm,{ref:"form"},[(_vm.licenseInformation)?_c('div',{staticStyle:{"display":"grid","grid-template-columns":"1fr 1fr","column-gap":"1rem"}},[_c('p',[_c('b',[_vm._v("License Name")])]),_c('p',[_vm._v(_vm._s(_vm.licenseInformation.license_name))]),_c('p',[_c('b',[_vm._v("License Type")])]),_c('p',[_vm._v(_vm._s(_vm.licenseInformation.license_type))]),_c('p',[_c('b',[_vm._v("License Period")])]),_c('p',[_vm._v(_vm._s(_vm.licenseInformation.license_period)+" month"+_vm._s(_vm.licenseInformation.license_period > 1 ? "s" : ""))]),_c('p',[_c('b',[_vm._v("Days of Notification")])]),_c('p',[_vm._v(_vm._s(_vm.licenseInformation.days_of_notification))]),_c('p',[_c('b',[_vm._v("Router")])]),_c('p',[_vm._v(_vm._s(_vm.licenseInformation.router_id))]),_c('p',[_c('b',[_vm._v("License Key")])]),_c('p',[_vm._v(_vm._s(_vm.licenseInformation.license_key))])]):_vm._e()])],1),_c(VCardActions,{staticClass:"pa-6"},[_c(VSpacer),_c(VBtn,{staticClass:"button tabledata--text",on:{"click":function($event){return _vm.open_end_sign_up_dialog()}}},[_vm._v("Done")])],1),_c('EndSignUpDialog',{attrs:{"endSignUpDialog":_vm.endSignUpDialog},on:{"close":_vm.close_end_sign_up_dialog}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }