import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VForm } from 'vuetify/lib/components/VForm';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"display":"grid","height":"100%"}},[_c(VCardText,{staticClass:"pa-6"},[_c(VForm,{ref:"form"},[_c('div',{staticStyle:{"font-size":"14px"}},[_vm._v("Enter the Activation Code")]),_c(VTextField,{attrs:{"outlined":"","dense":"","hide-details":"","label":"Code","maxlength":"6","disabled":_vm.isLoading},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.create_account()}},model:{value:(_vm.activationCode),callback:function ($$v) {_vm.activationCode=$$v},expression:"activationCode"}}),_c('div',{staticClass:"ml-1",staticStyle:{"font-size":"10px"}},[_vm._v("*Enter the Activation Code sent to "+_vm._s(_vm.signUpEmail)+".")])],1)],1),_c(VCardActions,{staticClass:"pa-6"},[_c(VBtn,{staticClass:"blue--text",attrs:{"disabled":_vm.isLoading,"outlined":""},on:{"click":function($event){return _vm.cancel_sign_up()}}},[_vm._v("Cancel")]),_c(VSpacer),_c(VBtn,{staticClass:"button tabledata--text mr-2",attrs:{"disabled":_vm.isLoading},on:{"click":function($event){return _vm.back()}}},[_vm._v("Back")]),_c(VBtn,{staticClass:"button tabledata--text",attrs:{"loading":_vm.isLoading},on:{"click":function($event){return _vm.create_account()}}},[_vm._v("Finalize")])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }