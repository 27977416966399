<template>
  <v-dialog overflow-hidden v-model="remoteCommandDialog" persistent max-width="500px">
    <DialogCard v-if="remoteCommandDialog" :modalIcon="'remote'" :modalTitle="`Remote Command - ${singleRouterInfo.router_name}`">
      <template #header>
        <v-btn icon x-small @click="close_remote_command_dialog()">
          <v-icon color="white">mdi-close</v-icon>
        </v-btn>
      </template>

      <template #body>
        <v-row no-gutters class="mt-6">
            <v-select v-model="commandType" label="Command to send" :items="$store.getters.getterGetCurrentUserRole == 'SU_ADMIN' ? superCommands : normalCommands" outlined dense required></v-select>
          </v-row>
      </template>

      <template #footer>
        <v-btn small class="blue--text" outlined @click="close_remote_command_dialog()">Cancel</v-btn>
        <v-btn small :loading="loadingSendDeviceCommand" @click="send_command_to_device()" class="button tabledata--text mr-2">Send</v-btn>
      </template>
    </DialogCard>
  </v-dialog>
</template>

<script>
import DialogCard from "@/components/Slots/DialogCard.vue"
import { sendCommandToDevice } from "@/services"
import { mapMutations } from "vuex";
export default {
  components: {
    DialogCard
  },
  props: {
    remoteCommandDialog: Boolean,
    singleRouterInfo: Object,
  },
  data() {
    return {
      commandType: "",
      loadingSendDeviceCommand: false,
      normalCommands: [{text: "Reboot", value: "REBOOT"}],
      superCommands: [
        {text: "Reboot", value: "REBOOT"},
        {text: "MDM Enable", value: "ENABLE"},
        {text: "MDM Disable", value: "DISABLE"},
      ],
    }
  },
  watch: {
    remoteCommandDialog: {
      handler() {
        
      },
      immediate: true,
    },
  },
  methods: {
    ...mapMutations(["mutationSetSnackBarItem"]),
    triggerSnackBar(type, text) {
      this.mutationSetSnackBarItem({ type, text });
    },
    //method invokes on close action
    close_remote_command_dialog() {
      this.$emit("close");
    },

    //method to validate the empty fields
    async send_command_to_device() {
      if(this.commandType === "") {
        this.triggerSnackBar("error", "Please select a command type to send")
        return;
      }
      this.loadingSendDeviceCommand = true;
      try {
        await sendCommandToDevice({
          router_id: this.singleRouterInfo.router_id,
          router_action: this.commandType,
        })
        this.triggerSnackBar("success", "The command has been sent to the device successfully");
        this.$emit("close");
      } catch(err) {
        this.triggerSnackBar("error", err.errors ? err.errors[0].message : err);
      } finally {
        this.loadingSendDeviceCommand = false;
      }
    },
  },
};
</script>
