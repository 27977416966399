const state = {
  selectedModel: { model: "RG2100", TAC: "35856379" },
  deviceSerialNumber: "",
  deviceName: "",
  deviceDescription: "",
  carrierInfo: "",
  firstName: "",
  lastName: "",
  signUpEmail: "",
  countryCode: "USA",
  signUpPassword: "",
  licenseInformation: {},
  signUpPage: 0,
};

const getters = {
  getterGetSelectedModel: (state) => {
    return state.selectedModel;
  },
  getterGetDeviceSerialNumber: (state) => {
    return state.deviceSerialNumber;
  },
  getterGetDeviceName: (state) => {
    return state.deviceName;
  },
  getterGetDeviceDescription: (state) => {
    return state.deviceDescription;
  },
  getterGetCarrierInfo: (state) => {
    return state.carrierInfo;
  },
  getterGetFirstName: (state) => {
    return state.firstName;
  },
  getterGetLastName: (state) => {
    return state.lastName;
  },
  getterGetSignUpEmail: (state) => {
    return state.signUpEmail;
  },
  getterGetCountryCode: (state) => {
    return state.countryCode;
  },
  getterGetSignUpPassword: (state) => {
    return state.signUpPassword;
  },
  getterGetLicenseInformation: (state) => {
    return state.licenseInformation;
  },
  getterGetSignUpWindowPage: (state) => {
    return state.signUpPage;
  },
};

const mutations = {
  mutationSetSelectedModel: (state, payload) => {
    state.selectedModel = payload;
  },
  mutationSetDeviceSerialNumber: (state, payload) => {
    state.deviceSerialNumber = payload;
  },
  mutationSetDeviceName: (state, payload) => {
    state.deviceName = payload;
  },
  mutationSetDeviceDescription: (state, payload) => {
    state.deviceDescription = payload;
  },
  mutationSetCarrierInfo: (state, payload) => {
    state.carrierInfo = payload;
  },
  mutationSetFirstName: (state, payload) => {
    state.firstName = payload;
  },
  mutationSetLastName: (state, payload) => {
    state.lastName = payload;
  },
  mutationSetSignUpEmail: (state, payload) => {
    state.signUpEmail = payload;
  },
  mutationSetCountryCode: (state, payload) => {
    state.countryCode = payload;
  },
  mutationSetSignUpPassword: (state, payload) => {
    state.signUpPassword = payload;
  },
  mutationSetLicenseInformation: (state, payload) => {
    state.licenseInformation = payload;
  },
  mutationSetSignUpWindowPage: (state, payload) => {
    state.signUpPage = payload;
  },
};

const actions = {
  actionResetSignUpInfo: ({ commit }, _) => {
    commit("mutationSetSelectedModel", { model: "RG2100", TAC: "35856379" });
    commit("mutationSetDeviceSerialNumber", "");
    commit("mutationSetDeviceName", "");
    commit("mutationSetDeviceDescription", "");
    commit("mutationSetCarrierInfo", "");
    commit("mutationSetFirstName", "");
    commit("mutationSetLastName", "");
    commit("mutationSetSignUpEmail", "");
    commit("mutationSetCountryCode", "USA");
    commit("mutationSetSignUpPassword", "");
    commit("mutationSetLicenseInformation", {});
    commit("mutationSetSignUpWindowPage", 0);
  }
}

export default {
  state,
  getters,
  mutations,
  actions
};
