import { VApp } from 'vuetify/lib/components/VApp';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VMain } from 'vuetify/lib/components/VMain';
import { VWindow } from 'vuetify/lib/components/VWindow';
import { VWindowItem } from 'vuetify/lib/components/VWindow';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VApp,[_c(VMain,{staticClass:"align-center"},[_c(VCard,{staticClass:"sign-up-card",attrs:{"width":"780px","height":"440px"}},[_c(VCardTitle,{staticClass:"justify-center"},[_vm._v("Individual Sign Up")]),_c(VWindow,{staticClass:"sign-up-window",model:{value:(_vm.windowPage),callback:function ($$v) {_vm.windowPage=$$v},expression:"windowPage"}},[_c(VWindowItem,{attrs:{"value":0}},[_c('SelectModel')],1),_c(VWindowItem,{attrs:{"value":1}},[_c('DeviceInfo')],1),_c(VWindowItem,{attrs:{"value":2}},[_c('NewUserInfo')],1),_c(VWindowItem,{attrs:{"value":3}},[_c('VerificationCode')],1),_c(VWindowItem,{attrs:{"value":4}},[_c('LicenseInfo')],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }