<!-- Card Version of DeviceInfo -->
<template>
  <v-card flat>
    <v-card-title>Device Info</v-card-title>
    <v-card-text>
      <!-- 1st Row -->
      <div class="d-flex flex-row justify-space-between my-2">
        <div class="text-left">
          <strong>{{ isMobileDevice(deviceModel) ? "IMEI Number" : "Serial Number" }}</strong>
        </div>
        <div class="text-right">
          <span>{{ isMobileDevice(deviceModel) ? deviceIMEI : deviceSN }}</span>
        </div>
      </div>
      <v-divider></v-divider>
      <!-- 2nd Row -->
      <template v-if="isMobileDevice(deviceModel)">
        <div class="d-flex flex-row justify-space-between my-2"> 
          <div class="text-left">
            <strong>MSISDN</strong>
          </div>
          <div class="text-right">
            <span>{{ deviceMSISDN }}</span>
          </div>
        </div>
        <v-divider></v-divider>
      </template>
      <!-- 3rd Row -->  
      <template v-if="shouldShowCarrier(deviceModel)">
        <div class="d-flex flex-row justify-space-between my-2"> 
          <div class="text-left">
            <strong>Carrier</strong>
          </div>
          <div class="text-right">
            <span>{{ deviceCarrier }}</span>
          </div>
        </div>
        <v-divider></v-divider>
      </template>

      <!-- 4th Row -->
      <div class="d-flex flex-row justify-space-between my-2"> 
        <div class="text-left">
          <strong>Firmware Version</strong>
        </div>
        <div class="text-right">
          <span>{{ deviceFirmwareVersion }}</span>
        </div>
      </div>
      <v-divider></v-divider>
    
      <!-- 3rd Row -->
      <div class="d-flex flex-row justify-space-between my-2">
          <div class="text-left">
            <strong> Last Reported </strong>
          </div>
          <div class="text-right">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-chip v-on="on" v-bind="attrs" x-small dark :color="mixinGetTimeDifference(deviceLastReportDate) >= 1 ? 'grey' : '#4CAF50'">
                  <span class="caption">{{ mixinConvertUTCToDate(deviceLastReportDate) }}</span>
                </v-chip>
              </template>
              <span v-if="mixinGetTimeDifference(deviceLastReportDate) >= 1">Last reported a day or more ago</span>
              <span v-else>Last reported within 24hrs</span>
            </v-tooltip>
          </div>
      </div>
      <v-divider></v-divider>
      <!-- 4th Row -->
      <div class="d-flex flex-row justify-space-between my-2">
        <div class="text-left">
          <strong> Config Updated </strong>
        </div>
        <div class="text-right">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-chip v-on="on" v-bind="attrs" x-small dark :color="getColor(deviceReportDateColor)">
                <span class="caption">{{ mixinConvertUTCToDate(deviceConfigUpdatedOn) }}</span>
              </v-chip>
            </template>
            <span v-if="deviceReportDateColor === 'red'">The device has never connected to the server</span>
            <span v-else-if="deviceReportDateColor === 'orange'">The device has yet to receive the latest configuration from the server</span>
            <span v-else-if="deviceReportDateColor === 'green'">The configuration is up to date</span>
          </v-tooltip>
        </div>
      </div>
      
      <!-- 5th Row -->
      <template v-if="isBusinessRouter">
        <v-divider></v-divider>
        <div class="d-flex flex-row justify-space-between my-2">
          <div class="text-left">
            <strong>Partner</strong>
          </div>
          <div class="text-right">
            <span>{{ partnerName }}</span>
          </div>
        </div>
        
      </template>
      <!-- 6th Row -->
      <template v-if="isBusinessRouter">
        <v-divider></v-divider>
        <div class="d-flex flex-row justify-space-between my-2">
          <div class="text-left">
            <strong>Organization</strong>
          </div>
          <div class="text-right">
            <span>{{ organizationName }}</span>
          </div>
        </div>
      </template>
      <!-- 7th Row -->
      <template v-if="isBusinessRouter">
        <v-divider></v-divider>
        <div class="d-flex flex-row justify-space-between my-2">
          <div class="text-left">
            <strong>Group</strong>
          </div>
          <div class="text-right">
            <span>{{ groupName }}</span>
          </div>
        </div>
      </template>
    </v-card-text>
  </v-card>
</template>

<script>
import { mixin_time_utils } from "@/mixins/timeUtils";
import { router_model_utils } from "@/mixins/routerModelUtils.js";

import { mapGetters } from "vuex";

export default {
  name: "DeviceInfoCard",
  mixins: [mixin_time_utils, router_model_utils],
  methods: {
    getColor(color) {
      const reportedDateColor = {
        red: "#F44336",
        green: "#4CAF50",
        orange: "#FF9800",
      };
      return reportedDateColor[color];
    },
  }, 
  computed: {
    console: () => console,
    ...mapGetters([ 'getterGetDeviceConfigurations', 'getterGetUserInfo' ]),
    notCAdmin() {
      return this.getterGetUserInfo.user.user_role !== "C_ADMIN";
    },
    licenseStatus() {
      return this.getterGetDeviceConfigurations.router_details.license_status === "trialing" ? "Free Trial" : this.getterGetDeviceConfigurations.router_details.license_status;
    },
    partnerName() {
      // This data comes from the user rather than the device
      // It will display incorrect information when viewing a device from SuperAdmin
      // Impersonate to avoid this
      // We could consider making a backend change so that this information is stored in the router table
      return this.getterGetUserInfo.customer.partner_name; 
    },
    organizationName() {
      return this.getterGetDeviceConfigurations.router_details.organization_name;
    },
    groupName() {
      return this.getterGetDeviceConfigurations.router_details.router_group;
    },
    isBusinessRouter() {
      return this.getterGetDeviceConfigurations.router_details.is_individual_router === false;
    },
    statusColors() {
      return {
        textColor: this.licenseStatus === "trialing" || this.licenseStatus.toLowerCase() === "active" ? "green" : "red",
        chipColor: this.licenseStatus === "trialing" || this.licenseStatus.toLowerCase() === "active" ? "#4CAF50" : "#F44336",
      }
    }, 
    licenseExpiration() {
      return this.getterGetDeviceConfigurations.router_details.expire_date;
    },
    licenseType() {
      return this.getterGetDeviceConfigurations.router_details.license_type.toUpperCase();
    },
    deviceModel() {
      return this.getterGetDeviceConfigurations.router_details.router_model;
    },
    deviceIMEI() {
      return this.getterGetDeviceConfigurations.router_details.imei_number;
    },
    deviceIMEI() {
      return this.getterGetDeviceConfigurations.router_details.imei_number;
    },
    deviceSN() {
      return this.getterGetDeviceConfigurations.router_details.router_id.split("/")[1];
    },
    deviceMSISDN() {
      return this.getterGetDeviceConfigurations.router_details.msisdn;
    },
    deviceCarrier() {
      return this.getterGetDeviceConfigurations.router_details.carrier_info;
    },
    deviceFirmwareVersion() {
      return this.getterGetDeviceConfigurations.router_details.firmware_version;
    },
    deviceLastReportDate() {
      return this.getterGetDeviceConfigurations.router_details.last_reported_on ? this.getterGetDeviceConfigurations.router_details.last_reported_on : "";
    },
    deviceReportDateColor() {
      return this.getterGetDeviceConfigurations.router_details.indicate_color;
    },
    deviceConfigUpdatedOn() {
      return this.getterGetDeviceConfigurations.router_details.config_updated_on ? this.getterGetDeviceConfigurations.router_details.config_updated_on : "";
    },
  }
}

</script>

<style scoped> 
.v-chip::before {
  display: none;
}
</style>
