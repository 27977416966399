<template>
  <div>
    <v-dialog v-model="updateDialog" fullscreen persistent hide-overlay transition="dialog-bottom-transition">
      <v-card flat width="auto">
        <v-toolbar dark class="bannercolor">
          <v-toolbar-title class="dashboard-router">User Profile</v-toolbar-title>
          <v-spacer></v-spacer>

          <v-btn icon dark @click="dialog_emit((Toggle = 1))">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-col cols="12">
          <v-container fluid class="pt-0">
            <div v-if="this.navigateLink === 'Account Information'">
              <AccountInfo :detailsCheck="detailsCheck" v-on:subheader="title_info" v-on:previousStep="previous_module" />
            </div>
            <div v-if="this.navigateLink === 'Company Details'">
              <CompanyDetails :previousCheck="previousCheck" v-on:subheader="title_info" v-on:finalstep="final_module" />
            </div>
            <div v-if="this.navigateLink === 'Account Settings'">
              <AccountInformation :informationCheck="informationCheck" v-on:subheader="title_info" v-on:infoStep="info_module" />
            </div>
            <div v-if="this.navigateLink === 'Licenses'">
              <Licenses />
            </div>
            <div v-if="this.navigateLink === 'Remote Support'">
              <RemoteSupport />
            </div>
            <!-- <v-btn style="margin-top: 2rem;" @click="mutationClearAllReminderDate">Re-Enable All Expiration Reminders</v-btn> -->
          </v-container>
        </v-col>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import AccountInfo from "@/components/UpdateProfileInfo/AccountInfo.vue";
import CompanyDetails from "@/components/UpdateProfileInfo/CompanyDetails.vue";
import AccountInformation from "@/components/UpdateProfileInfo/AccountInformation.vue";
import Licenses from "@/components/UpdateProfileInfo/Licenses.vue";
import RemoteSupport from "@/components/UpdateProfileInfo/RemoteSupport.vue";

import { mapMutations } from "vuex";
export default {
  props: {
    updateDialog: Boolean,
  },
  components: {
    AccountInfo,
    CompanyDetails,
    AccountInformation,
    Licenses,
    RemoteSupport,
  },
  data() {
    return {
      drawer: null,
      selectedItem: 0,
      navigateLink: "Account Information",
      emailCheck: 0,
      detailsCheck: 0,
      previousCheck: 0,
      informationCheck: 0,
      pwdCheck: false,
    };
  },
  methods: {
    // ...mapMutations(["mutationClearAllReminderDate"]),
    //method invokes on close action
    dialog_emit(Toggle) {
      this.navigateLink = "Account Information";
      this.detailsCheck = 0;
      this.selectedItem = 0;
      this.$emit("clicked", Toggle);
    },

    //method invokes on menu change
    menu_change(val) {
      this.navigateLink = val.text;
    },
    //method to fetch the title on menu change
    //another braindead engineering from india
    title_info(val) {
      if (val == 1) {
        this.detailsCheck = 1;
      } else if (val == 2) {
        this.detailsCheck = 2;
      } else if (val == 3) {
      } else if (val == 4) {
        this.previousCheck = 1;
      } else if (val == 5) {
        this.detailsCheck = 3;
      } else if (val == 6) {
        this.informationCheck = 1;
      }
    },

    //method invokes on previous step actions
    previous_module() {
      this.detailsCheck = 0;
    },

    //method invokes on success of company details update
    final_module() {
      this.previousCheck = 0;
    },
    //method invokes on success of account settings details update
    info_module() {
      this.informationCheck = 0;
    },
  },
};
</script>
